/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from 'react-scroll'

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks_inner(props) {
  const classes = useStyles();
  return (
    <List className={classes.list + " main_navbar"}>
      <ListItem className={classes.listItem}>
        <NavLink to="/home" color="transparent" className="nav-link">Home</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/about" color="transparent" className="nav-link">About</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/team" color="transparent" className="nav-link">Team</NavLink>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <a href="https://filipcoin.gitbook.io/whitepaper/" target="_blank" color="transparent" download className="nav-link">Whitepaper</a>
      </ListItem> 

      <ListItem className={classes.listItem}>
        <NavLink to="/faq" color="transparent" className="nav-link">Faq</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/contact" color="transparent" className="nav-link">Contact</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/login" color="transparent" className="nav-link login_btn">Login</NavLink>
      </ListItem>
    </List>
  );
}
