import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function About(props) {

  const { ...rest } = props;
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  var   {  withdrawid } = useParams();
  
  useEffect(()  => {
     getuserid();
  },[])

async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

async function  isconfirm(){
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
      }
    }else{
      localStorage.clear();
    }
  }
  return (
    <div className="inner_pages">
      <ScrollToTopOnMount />
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}
      

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">About Us</Link></li>
              </ul>
              <h1 className="inner_title">About Us</h1>
            </GridItem>
          </GridContainer>
        </div>
        <nav className="fixed_sidenav">
          <ul>
            <li><NavLink to="/about">Introduction</NavLink></li>
            <li><NavLink to="/objective">Objective</NavLink></li>
            <li><NavLink to="/roadmap">Roadmap</NavLink></li>
            <li><NavLink to="/tokenomics">Tokenomics</NavLink></li>
          </ul>
        </nav>
        <div className="new_inner_content">
          <section className="intro_section section">
            <div className="container">
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12} className="mt-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel">
                    <h2>Introduction</h2>
                    <p>Decentralized cryptocurrencies have been around since the launch of Bitcoin in 2009, the first widely-adopted decentralized cryptocurrency. The cryptocurrency market has grown to previously unimagined heights. The growth has been rapid and widespread, with a slew of new digital currencies, related startups, and exchanges entering the field each week. There are numerous reasons for the said demand in cryptocurrency. First, there are millions globally who lack easy access to standard bank services as a result of infrastructure issues. Beyond that, cross-border payments are typically charged heavily, and a digital currency could help to alleviate transaction fees from a centralized exchanger. Furthermore, blockchain transactions are easily traceable, allowing immediate feedback and checks and balances that can be utilized across all types of transactions</p>
                    <p>FILIP is committed to the development of a reliable transaction system under the blockchain technology of Ethereum. It utilizes a value of system protocol to achieve the transferring of payment on peer to peer basis, and to build a decentralized platform supporting the service economy applications that is highly flexible in terms of adaptation, convenience, reliability, security and allows the development of partnerships. Innovative technology, comprehensive governance structure and board applications leverage FILIP over existing cryptocurrencies. The foundation will provide transparency for payment management, financial management, development management, as well as business practices for partners, while upholding high standards of integrity, credibility, ethical business conduct, and strict adherence to applicable laws, rules, and regulations for the users and investors. The conception of FILIP and the possibilities it will offer have been strongly supported by the founding members, the development team, industry experts, early backers and investors, lawyers, and professional consultants.</p>
                    <p>The foundation will provide transparency for payment management, financial management, development management, as well as business practices for partners while upholding high standards of integrity, credibility, ethical business conduct, and strict adherence to applicable laws, rules, and regulations for the users and the investors. The conception of FCP and the possibilities it will offer have been strongly supported by the founding members, the development team, industry experts, early backers and investors, lawyers, and professional consultants.</p>
                    <h2>Mission</h2>
                    <p>FCP's mission is to create a social capital platform for service economy applications integrating blockchain technology to utilize and strengthen decentralized transaction protocol systems to serve and address the interests of all of its participants locally and globally. Our goal is to develop and innovate our own blockchain technology which focuses on decentralized finance, a system of service economy applications that will provide practical solutions for our users, investors, and developer partners.</p>
                    <p>This approach is the first-ever system that offers a balance between autonomy, cost efficiency, and risk. This will allow Partner Developers to focus on the development of relevant applications addressing the customers’ goals. A customer-centric approach driven by customer needs and preferences, but will, however, maintain the decentralized approach with respect to the payment protocol system to maintain reliability, speed, lower transaction fee, mobility, and enhanced security.</p>
                    <h2>Vision</h2>
                    <p>FCP envisions to be one of the pioneers of cryptocurrency in the Philippines, as well as in Asia, to implement DeFi and drive the country to global financial digitization. We envision being the catalyst of financial technology facilitating the growth of our partners, investors, and the rest of our stakeholders.</p>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </section>
          
          {/* Team */}
          <section className="section team_section dark_bg" id="leadership_team">
            <div className="container">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead text-center">Our Team</h2>
                  <h1 className="inner_title">Leadership Team</h1>                  
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000">
                  <div className="team_panel">
                    <img src={require("../assets/images/team_01.png")} alt="Team" className="img-fluid"/>
                    <div className="member_details">
                      <h2>Janice Arino</h2>
                      <p>CEO/Founder</p>                      
                    </div>
                    <div className="member_social">
                      <ul>
                        <li><a href="https://www.linkedin.com/in/janice-ari%C3%B1o/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000">
                  <div className="team_panel">
                    <img src={require("../assets/images/team_default.png")} alt="Team" className="img-fluid"/>
                    <div className="member_details">
                      <h2>Ferdinand Mark Nofuente</h2>
                      <p>Chief Finance Officer</p>
                    </div>
                    <div className="member_social">
                      <ul>
                        <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000">
                  <div className="team_panel">
                    <img src={require("../assets/images/team_05.png")} alt="Team" className="img-fluid"/>
                    <div className="member_details">
                      <h2>Jonas Arino</h2>
                      <p>Chief Operation Officer</p>
                    </div>
                    <div className="member_social">
                      <ul>
                        <li><a href="https://www.linkedin.com/in/jonas-arino-8442481aa/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000">
                  <div className="team_panel">
                    <img src={require("../assets/images/team_03.png")} alt="Team" className="img-fluid"/>
                    <div className="member_details">
                      <h2>Punith Kumar Baskaran</h2>
                      <p>Lead Blockchain Consultant</p>
                    </div>
                    <div className="member_social">
                      <ul>
                        <li><a href="https://www.linkedin.com/in/punith-kumar-baskaran-66019037/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000">
                  <div className="team_panel">
                    <img src={require("../assets/images/team_04.png")} alt="Team" className="img-fluid"/>
                    <div className="member_details">
                      <h2>Atty. Salma F. Angkaya-Kuhutan</h2>
                      <p>Legal Advisor</p>
                    </div>
                    <div className="member_social">
                      <ul>
                        <li><a href="https://www.linkedin.com/in/sakuhutan/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000">
                  <div className="team_panel">
                    <img src={require("../assets/images/team_06.png")} alt="Team" className="img-fluid"/>
                    <div className="member_details">
                      <h2>Medel Afalla</h2>
                      <p>Philippine Public Transportation Consultant</p>
                    </div>
                    <div className="member_social">
                      <ul>
                        <li><a href="https://www.linkedin.com/in/medel-afalla-870b31a6/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </section>

          {/* Awards */}
          <section className="section awards_section" id="our_awards">
          <div className="container">
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead text-center">HONORS</h2>
                <h1 className="inner_title">Our Awards</h1>
                <h3>Award of the Project FILIPAY</h3>
              </GridItem>
            </GridContainer>
            <GridContainer className="justify-content-center mt-4">
              <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <img src={require("../assets/images/awards_01.png")} alt="Awards" className="img-fluid"/>
              </GridItem>     
              <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <img src={require("../assets/images/awards_02.png")} alt="Awards" className="img-fluid"/>
              </GridItem>
              <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <img src={require("../assets/images/awards_03.png")} alt="Awards" className="img-fluid"/>
              </GridItem>
              <GridItem sm={6} md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <img src={require("../assets/images/awards_04.png")} alt="Awards" className="img-fluid"/>
              </GridItem>
            </GridContainer>
          </div>
        </section>

        {/* Features Section */}
        <section className="section features_section dark_bg dark_bg_gradient" id="features">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                {/* <h2 className="inner_subhead">FEATURES</h2> */}
                <h1 className="inner_title">FEATURES</h1>                  
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem sm={6} md={6} data-aos="fade-up" data-aos-duration="2000">
                <div className="features_section_panel">
                  <h3>SEAi to revolutionize the Philippine transportation technology</h3>
                  <p>FILIPAY Pioneered Tap In/Tap Out system</p>
                  <a href="javascript:void(0)" color="transparent" className="nav-link login_btn">READ ARTICLE</a>
                </div>
              </GridItem>     
              <GridItem sm={6} md={6} data-aos="fade-up" data-aos-duration="2000" className="mt-3">
                <img src={require("../assets/images/features_img.png")} alt="Awards" className="img-fluid"/>
              </GridItem>                
            </GridContainer>
          </div>
        </section>

        {/* Clients */}
        <section className="section client_section text-center" id="our_partners">
          <div className="container">
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead text-center">CLIENTS</h2>
                <h1 className="inner_title">Our Partners</h1>
                <h3 className="text-center">We are proud to include our list of clients/partners in making Automatic Fare Collection System for transportation possible.</h3>
              </GridItem>
            </GridContainer>
            <GridContainer className="align-items-center justify-content-center">
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_01.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_02.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_03.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_04.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_05.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_06.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_07.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_08.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_09.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_10.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
              <GridItem sm={6} md={4} lg={3} data-aos="zoom-in" data-aos-duration="1000">
                <img src={require("../assets/images/client_logo_11.png")} alt="Client" className="img-fluid mt-5"/>
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <div className="container" data-aos="fade-up" data-aos-duration="2000">
          <div className="text-right">
            <Link to="/objective" className="nxt_page_btn">Objective <img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon"/></Link>
          </div>
        </div>
        </div>          
        <Footer/>
      </div>
    </div>
  );
}
