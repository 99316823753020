import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import { TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import LinkedIn from "linkedin-login-for-react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import { toastAlert } from "../actions/toastAlert";
import { postMethod } from "../actions/actions";
import browser from "browser-detect";
import { Modal } from "react-bootstrap/";
import { Link, useHistory, useParams } from "react-router-dom";
import config from "actions/config";
import axios from "axios";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  FORGOT,
  USER_LOADING,
  USER_ADD,
  SUPPORT_REPLY,
  SUPPORT
} from "../actions/types";
import { func } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
const eye = <FontAwesomeIcon icon={faEye} />;

const useStyles = makeStyles(styles);
const dashboardRoutes = [];


export default function Login(props) {

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { ...rest } = props;
  const classes = useStyles();
  const url = config.baseUrl;
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setemail] = React.useState('');
  const [password, setpassword] = React.useState('');
  const [errors, seterrors] = React.useState('');
  const [countryName, setcountryName] = React.useState('');
  const [countryCode, setcountryCode] = React.useState('');
  const [ipaddress, setipaddress] = React.useState('');
  const [browsername, setbrowsername] = React.useState('');
  const [regionName, setregionName] = React.useState('');
  const [ismobile, setismobile] = React.useState('');
  const [tfacode, settfacode] = React.useState('');
  const [region, setregion] = React.useState('');
  const [show, setshow] = React.useState('');
  var { userId } = useParams();

  useEffect(() => {
    getGeoInfo();
    isactive();

  }, [])

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  async function isactive() {
    var activate_id = userId;
    if (typeof activate_id != "undefined" && activate_id != "") {
      let passdata = { userid: activate_id };
      var res = await postMethod(url + "api/user-activate", passdata);
      if (res.data && res.data.errormsg) {
        toastAlert('success', res.data.errormsg, 'success')
      }
      if (res.data && res.data.message) {
        toastAlert('success', res.data.message, 'success')
      }
    } else {
      localStorage.clear();
    }
  }

  async function getGeoInfo() {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setcountryName(data.country_name);
        setcountryCode(data.country_calling_code);
        setipaddress(data.ip);
        setregionName(data.region);

      })
      .catch((error) => {
      });
    let activate_id = "";
    if (typeof activate_id != "undefined" && activate_id != "") {

    }
  };

  async function handleModal(showrest) {
    setshow(showrest);
  };

  async function TFAchange(code) {
    settfacode(code);
  }

  async function emailchange(email) {
    setemail(email);
  }

  async function passwordchange(password) {
    setpassword(password);
  }

  const responseGoogle = (response) => {
    const result = browser();
    const loginhistory = {};
    loginhistory.countryCode = countryCode;
    loginhistory.countryName = countryName;
    loginhistory.ipaddress = ipaddress;
    loginhistory.regionName = region;
    loginhistory.browsername = result.name;
    loginhistory.ismobile = result.mobile;
    loginhistory.os = result.os;
    const userData = {
      email: response.profileObj.email,
      socialuser: response.tokenObj.idpId,
      socialid: response.profileObj.googleId,
      name: response.profileObj.name,
      logininfo: loginhistory,
    };
    axios
      .post(url + "api/loginsocialuser", userData)
      .then(res => {
        if (res.data.socialid) {
          toastAlert('error', res.data.socialid, 'validate')
        }
        if (res.data && res.data.message) {
          toastAlert('success', res.data.message, 'success')
          localStorage.setItem("jwtToken", res.data.token);

          history.push('/dashboard')
        }
        if (res.data.errmessage) {
          toastAlert('error', res.data.errmessage, 'alert')
        }
        if (res.datatfa) {
          setshow(true);
        }
      })
      .catch(err => {
        toastAlert('error', "Some Error occured Try again later", 'Error')
      })
  }


  const handleSuccess = (data) => {

  }

  const handleFailure = (error) => {

  }

  const callbackLinkedIn = (error, code, redirectUri) => {
    if (error) {
    } else {

    }
  };

  const responseFacebook = (response) => {
    const result = browser();
    const loginhistory = {};
    loginhistory.countryCode = countryCode;
    loginhistory.countryName = countryName;
    loginhistory.ipaddress = ipaddress;
    loginhistory.regionName = region;
    loginhistory.browsername = result.name;
    loginhistory.ismobile = result.mobile;
    loginhistory.os = result.os;
    const userData = {
      email: "",
      socialuser: response.graphDomain,
      socialid: response.userID,
      name: response.name,
      logininfo: loginhistory,
    };
    axios
      .post(url + "api/loginsocialuser", userData)
      .then(res => {
        if (res.data.socialid) {
          toastAlert('error', res.data.socialid, 'validate')
        }
        if (res.data && res.data.message) {
          toastAlert('success', res.data.message, 'success')
          localStorage.setItem("jwtToken", res.data.token);

          history.push('/dashboard')
        }
        if (res.data.errmessage) {
          toastAlert('error', res.data.errmessage, 'alert')
        }
        if (res.datatfa) {
          setshow(true);
        }
      })
      .catch(err => {
        toastAlert('error', "Some Error occured Try again later", 'Error')
      })
  }

  async function formValidate() {

    var isValid = true;
    var error = "";
    if (email == "" || typeof email == "undefined") {
      isValid = false;
      error = "Must Enter Email";
    } else if (password == "" || typeof password == "undefined") {
      isValid = false;
      error = "password is required";
    }

    if (!isValid) {
      toastAlert('error', error, 'validate')
    } else {
      return isValid;
    }
  }


  async function submitnow() {
    formValidate();
    if (formValidate()) {
      const result = browser();
      const loginhistory = {};
      loginhistory.countryCode = countryCode;
      loginhistory.countryName = countryName;
      loginhistory.ipaddress = ipaddress;
      loginhistory.regionName = region;
      loginhistory.browsername = result.name;
      loginhistory.ismobile = result.mobile;
      loginhistory.os = result.os;
      const userData = {
        email: email,
        password: password,
        logininfo: loginhistory,
      };
      axios
        .post(url + "api/loginfrontend", userData)
        .then(res => {
          if (res.data.email) {
            toastAlert('error', res.data.email, 'validate')
          }
          if (res.data.password) {
            toastAlert('error', res.data.password, 'validate')
          }
          if (res.data && res.data.message) {
            toastAlert('success', res.data.message, 'success')
            localStorage.setItem("jwtToken", res.data.token);

            history.push('/dashboard')
          }
          if (res.data.errmessage) {
            toastAlert('error', res.data.errmessage, 'alert')
          }
          if (res.data.tfa) {
            setshow(true);
          }
        })
        .catch(err => {
          toastAlert('error', "Email or Password is incorrect", 'Error')
        })
    }
  }

  async function TFASUBMIT() {
    const result = browser();
    const loginhistory = {};
    loginhistory.countryCode = countryCode;
    loginhistory.countryName = countryName;
    loginhistory.ipaddress = ipaddress;
    loginhistory.regionName = region;
    loginhistory.browsername = result.name;
    loginhistory.ismobile = result.mobile;
    loginhistory.os = result.os;
    const userData = {
      email: email,
      password: password,
      logininfo: loginhistory,
      tfacode: tfacode,
    };
    axios
      .post(url + "api/TFAchecking", userData)
      .then(res => {
        if (res.data.email) {
          toastAlert('error', res.data.email, 'validate')
        }
        if (res.data.password) {
          toastAlert('error', res.data.password, 'validate')
        }
        if (res.data && res.data.message) {
          toastAlert('success', res.data.message, 'success')
          localStorage.setItem("jwtToken", res.data.token);

          history.push('/dashboard')
        }
        if (res.data.errmessage) {
          toastAlert('error', res.data.errmessage, 'alert')
        }

      })
      .catch(err => {
        toastAlert('error', "Email or Password is incorrect", 'Error')
      })
  }

  return (
    <div>
      <div className="auth_wrapper">
        <div className="container">
          <GridContainer>
            <GridItem sm={12} md={6} lg={6}>
              <div className="login_bg">
                <img src={require("../assets/images/login_img.png")} alt="Filipcoin" className="img-fluid" />
                <h4>Decentralized Network for Transportation and other Service Economy Applications</h4>
              </div>
            </GridItem>
            <GridItem sm={12} md={6} lg={6}>
              <div className="auth_form_div">
                <div className="text-right">
                  <Link to="/">
                    <img src={require("../assets/images/logo.png")} alt="Filipcoin" className="img-fluid login_logo" />
                  </Link>
                </div>
                <h2 className="auth_title">Login</h2>                
                <p>To keep connected to FILIPCOIN, please log in with your personal information by email address and password.</p>
                <form className="auth_form">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input id="filled-name" type="email" onChange={(e) => { emailchange(e.target.value) }} variant="filled" className="primary_inp"/>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="inp_grp">
                      <input id="filled-name" type={passwordShown ? "text" : "password"} onChange={(e) => { passwordchange(e.target.value) }} variant="filled" className="primary_inp"/>
                      <i className="eye_icon" onClick={togglePasswordVisiblity}>{eye}</i>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <FormControlLabel control={<Checkbox name="checkedC" />} label="Remember Me" className="mb-0 font_14" />
                    <Link to="/forgot" className="font_14 font-italic">Forgot Password?</Link>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <Button className="join_btn login" onClick={() => { submitnow() }}><span className="join_btn_span">Login Now</span></Button>
                    <Link to="/register" className="font_14">Create Account</Link>
                  </div>
                  <div className="social_login mt-4">
                    <p className="text-center">or you can join with us</p>
                    {/*  <ul>
                   
                    <FacebookLogin
                         appId="321417155966190"
                         // autoLoad
                         callback={responseFacebook}
                         render={renderProps => (
                          <li><a onClick={renderProps.onClick}><i className="fab fa-facebook-f"></i></a></li>
                       
                         )}
                       />
                       <GoogleLogin
                          clientId="168176188309-60egdosbi246ba8p9sm29rc1bsn9r6q4.apps.googleusercontent.com"
                          render={renderProps => (
                            <li><a onClick={renderProps.onClick} disabled={renderProps.disabled}><i class="fab fa-google"></i></a></li>
                          )}
                          buttonText="Login"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        />
                          <LinkedIn
                             clientId="81lx5we2omq9xh"
                             callback={callbackLinkedIn}
                               scope={["r_liteprofile","r_emailaddress"]}
                              
                           > 
                            <li><a><i className="fab fa-linkedin-in"></i></a></li>
                            </LinkedIn>
      
                     <LinkedIn
                          clientId="81lx5we2omq9xh"
                          onFailure={handleFailure}
                          onSuccess={handleSuccess}
                          redirectUri="http://localhost:3000/linkedin"
                          renderElement={({ onClick, disabled }) => (
                             <li><a onClick={onClick} disabled={disabled}><i className="fab fa-linkedin-in"></i></a></li>
                          )}
                        />
                     
                   <li><a href="#" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
                     <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                     <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                  </ul>*/}
                    <ul>
                      <li><a href="https://t.me/filipcoinfilipchannel" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                      <li><a href="https://t.me/filipcoinfilip" target="_blank"><i className="far fa-comments"></i></a></li>
                      <li><a href="https://twitter.com/filipcoinfcp" target="_blank"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.instagram.com/filipcoinfcp/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                      <li><a href="https://filipcoin.medium.com/" target="_blank"><i className="fab fa-medium-m"></i></a></li>
                      <li><a href="https://www.youtube.com/channel/UC4_t-U8vWCZgnhr7e4FYL3A" target="_blank"><i class="fab fa-youtube"></i></a></li>
                      <li><a href="https://www.facebook.com/filipcoinfcp" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    </ul>
                  </div>
                </form>
                <Modal
                  show={show}
                  onHide={() => {
                    handleModal(false);
                  }}
                  centered
                >
                  <Modal.Header closeButton>
                    <h4 >2FA Code</h4>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <div className="buyAmt">
                          <div className="col-md-12">

                            <input
                              value={tfacode}
                              id="tfacode"
                              type="number"
                              className="form-control"
                              placeholder="Enter the 2FA Code"
                              onChange={(e) => { TFAchange(e.target.value) }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btnType1 smallFont py-2 px-3 mr-2"
                      onClick={() => {
                        handleModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btnType1 smallFont py-2 px-3 mr-2"
                      onClick={() => TFASUBMIT()}
                    >
                      Login
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </div>
  );
}