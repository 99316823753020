import React, { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import CryptoJS from "crypto-js";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from '@material-ui/core/styles';
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import config from "../actions/config"
import { toastAlert }  from "../actions/toastAlert";
import * as Moment from "moment";
import { postMethod }  from "../actions/actions";
import { Link, useHistory,useParams } from "react-router-dom";
import { TextField, InputAdornment, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import axios from "axios";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;
const dashboardRoutes = [];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function Kyc(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [_id, set_id] = React.useState('');
  const [passportname, setpassportname] = React.useState('');
  const [name, setname] = React.useState('');
  const [lastname, setlastname] = React.useState('');
  const [email, setemail] = React.useState('');
  const [dob, setdob] = React.useState('');
  const [passportnumber, setpassportnumber] = React.useState('');
  const [passportfrontimage, setpassportfrontimage] = React.useState('');
  const [passportbackimage, setpassportbackimage] = React.useState('');
  const [passportselfie, setpassportselfie] = React.useState('');
  const [passportstatus, setpassportstatus] = React.useState('');
  const [viewfrontpassport, setviewfrontpassport] = React.useState('');
  const [viewbackpassport, setviewbackpassport] = React.useState('');
  const [viewselfiepassport, setviewselfiepassport] = React.useState('');
  const [nationalname, setnationalname] = React.useState('');
  const [nationalnumber, setnationalnumber] = React.useState('');
  const [nationalfrontimage, setnationalfrontimage] = React.useState('');
  const [nationalbackimage, setnationalbackimage] = React.useState('');
  const [nationalselfie, setnationalselfie] = React.useState('');
  const [nationalstatus, setnationalstatus] = React.useState('');
  const [viewfrontnational, setviewfrontnational] = React.useState('');
  const [viewbacknational, setviewbacknational] = React.useState('');
  const [viewselfienational, setviewselfienational] = React.useState('');
  const [drivingname, setdrivingname] = React.useState('');
  const [drivingnumber, setdrivingnumber] = React.useState('');
  const [drivingfrontimage, setdrivingfrontimage] = React.useState('');
  const [drivingbackimage, setdrivingbackimage] = React.useState('');
  const [drivingselfie, setdrivingselfie] = React.useState('');
  const [drivingstatus, setdrivingstatus] = React.useState('');
  const [viewfrontdriving, setviewfrontdriving] = React.useState('');
  const [viewbackdriving, setviewbackdriving] = React.useState('');
  const [viewselfiedriving, setviewselfiedriving] = React.useState('');
  const [userid,setuserid] = React.useState('');
  const history = useHistory();

  
  useEffect(()  => {
    setviewselfiepassport(require("../assets/images/selfie_photo_sample.png"));
    setviewfrontpassport(require("../assets/images/front_page_sample.png"));
    setviewbackpassport(require("../assets/images/back_page_sample.png"));
    setviewselfienational(require("../assets/images/selfie_photo_sample.png"));
    setviewfrontnational(require("../assets/images/front_page_sample.png"));
    setviewbacknational(require("../assets/images/back_page_sample.png"));
    setviewselfiedriving(require("../assets/images/selfie_photo_sample.png"));
    setviewfrontdriving(require("../assets/images/front_page_sample.png"));
    setviewbackdriving(require("../assets/images/back_page_sample.png"));
    getuserid();   
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
       if(!err && verified && verified.id){
      if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      getkycdetails(verified.id);
      getuserdetails(verified.id);
      set_id(verified.id);
     }
    }else{
      history.push('/login')
    }
  
   });
  }catch(err){

  }
  }

  async function getuserdetails(iid){
   
    var newuser={
      id:iid,
    }
    var res = await postMethod(url+"api/getuserdetails",newuser);
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
    }
    if(res.data && res.data.errmessage){
      toastAlert('error',res.data.errmessage,'validate')
    }

    if(res.data && res.data.data){
      setname(res.data.data.name);
      setlastname(res.data.data.lastname);
      setdob(res.data.data.dob);
      setemail(res.data.data.email);
      setuserid(res.data.data._id)
    }
    
  } 


  async function getkycdetails(id){
    var useid={
      userid:id,
    }
    var res = await postMethod(url+"api/get-kyc",useid)
    if(res.data && res.data.data){
      setpassportname(res.data.data.passportname);
      setpassportnumber(res.data.data.passportnumber);
      setpassportstatus(res.data.data.passportstatus);
      if(res.data.data.passportfrontend!="" && res.data.data.passportfrontend!=undefined){
      setpassportfrontimage(res.data.data.passportfrontend);
      setviewfrontpassport(url+"kyc/"+res.data.data.passportfrontend)
      }
      if(res.data.data.passportbackend!="" && res.data.data.passportbackend!=undefined){
        setpassportbackimage(res.data.data.passportbackend);
        setviewbackpassport(url+"kyc/"+res.data.data.passportbackend);
      }
      if(res.data.data.passportselfie!="" && res.data.data.passportselfie!=undefined){
        setpassportselfie(res.data.data.passportselfie);
        setviewselfiepassport(url+"kyc/"+ res.data.data.passportselfie)
      }

      setnationalname(res.data.data.nationalname);
      setnationalnumber(res.data.data.nationalnumber);
      setnationalstatus(res.data.data.nationalcardstatus);
      if(res.data.data.nationalcardfrontend!="" && res.data.data.nationalcardfrontend!=undefined){
      setnationalfrontimage(res.data.data.nationalcardfrontend);
      setviewfrontnational(url+"kyc/"+res.data.data.nationalcardfrontend);
      }
      if(res.data.data.nationalcardbackend!="" && res.data.data.nationalcardbackend!=undefined){
        setnationalbackimage(res.data.data.nationalcardbackend);
        setviewbacknational(url+"kyc/"+res.data.data.nationalcardbackend);
      }
      if(res.data.data.nationalcardselfie!="" && res.data.data.nationalcardselfie!=undefined){
        setnationalselfie(res.data.data.nationalcardselfie);
        setviewselfienational(url+"kyc/"+res.data.data.nationalcardselfie)
      }

      setdrivingname(res.data.data.drivingname);
      setdrivingnumber(res.data.data.drivingnumber);
      setdrivingstatus(res.data.data.drivinglicensestatus);
      if(res.data.data.drivinglicensefrontend!="" && res.data.data.drivinglicensefrontend!=undefined){
      setdrivingfrontimage(res.data.data.drivinglicensefrontend);
      setviewfrontdriving(url+"kyc/"+res.data.data.drivinglicensefrontend);
      }
      if(res.data.data.drivinglicensebackend!="" && res.data.data.drivinglicensebackend!=undefined){
        setdrivingbackimage(res.data.data.drivinglicensebackend);
        setviewbackdriving(url+"kyc/"+res.data.data.drivinglicensebackend);
     }
      if(res.data.data.drivinglicenseselfie!="" && res.data.data.drivinglicenseselfie!=undefined){
        setdrivingselfie(res.data.data.drivinglicenseselfie);
        setviewselfiedriving(url+"kyc/"+res.data.data.drivinglicenseselfie);
      }
    }
  }

  async function namechange(passportname){
    setpassportname(passportname);
  }

  async function lastnamechange(name){
    setlastname(name);
  }

  async function emailchange(email){
    setemail(email);
  }

  async function dobchange(dob){
    setdob(dob);
  }


  async function passportnumberchange(passportnumber){
    setpassportnumber(passportnumber);
  }

  async function passportbackimagechange(passportbackimage){
    setpassportbackimage( passportbackimage)      
    setviewbackpassport(URL.createObjectURL(passportbackimage));
  }

  async function passportfrontimagechange(passportfrontimage){
      setpassportfrontimage(passportfrontimage) 
      setviewfrontpassport(URL.createObjectURL(passportfrontimage));
  }
  

  async function passportselfiechange(passportselfie){
    setpassportselfie( passportselfie) 
    setviewselfiepassport(URL.createObjectURL(passportselfie));
  }
  
  async function drivingnamechange(drivingname){
    setdrivingname(drivingname);
  }

  async function drivingnumberchange(drivingnumber){
    setdrivingnumber(drivingnumber);
  }

  async function drivingfrontimagechange(drivingfrontimage){
    setdrivingfrontimage(drivingfrontimage) 
    setviewfrontdriving(URL.createObjectURL(drivingfrontimage));
  }
 
  async function drivingbackimagechange(drivingbackimage){
    setdrivingbackimage(drivingbackimage) 
    setviewbackdriving(URL.createObjectURL(drivingbackimage));
  }

  async function drivingselfiechange(drivingselfie){
    setdrivingselfie(drivingselfie) 
    setviewselfiedriving(URL.createObjectURL(drivingselfie));
  }

  async function nationalnamechange(nationalname){
    setnationalname(nationalname);
  }

  async function nationalnumberchange(nationalnumber){
    setnationalnumber(nationalnumber);
  }

  async function nationalfrontimagechange(nationalfrontimage){
    setnationalfrontimage(nationalfrontimage) 
    setviewfrontnational(URL.createObjectURL(nationalfrontimage));
  }
 
  async function nationalbackimagechange(nationalbackimage){
    setnationalbackimage(nationalbackimage) 
    setviewbacknational(URL.createObjectURL(nationalbackimage));
  }

  async function nationalselfiechange(nationalselfie){
    setnationalselfie(nationalselfie) 
    setviewselfienational(URL.createObjectURL(nationalselfie));
  }


  async function savepassport(){
    if(name !=null && name != undefined && name != ""){
      if(lastname !=null && lastname != undefined && lastname != ""){
        if(email !=null && email != undefined && email != ""){
          if(Moment(new Date()).isAfter(dob)){
             var formData = new FormData();
             formData.append('id',_id);
             formData.append('idfront',passportfrontimage);
             formData.append('idback',passportbackimage);
             formData.append('photofront',passportselfie);
             formData.append('idtype',"passport");
             formData.append('number',passportnumber);
             formData.append('name',name)
             formData.append('lastname',lastname)
             formData.append('email',email)
             formData.append('dob',dob)
             formData.append('userid',userid)
             var res = await postMethod(url+"api/update-kyc",formData)
             if(res.data && res.data.error){
               toastAlert('error',res.data.error,'error')
             }
             if(res.data && res.data.verified){
               toastAlert('error',res.data.verified,'error')
             }
             if(res.data && res.data.message){
               toastAlert('success',res.data.message,'success')
               getkycdetails(_id);
             }         
      
          }else{
            toastAlert('error',"Enter Valid Date Of Birth",'error')
          }
        }else{
          toastAlert('error',"Enter Email",'error')
        }
      }else{
        toastAlert('error',"Enter Last Name",'error')
      }
    }else{
      toastAlert('error',"Enter First Name",'error')
    }

   
  }

  async function savepassportdriving(){
     if(name !=null && name != undefined && name != ""){
      if(lastname !=null && lastname != undefined && lastname != ""){
        if(email !=null && email != undefined && email != ""){
          if(Moment(new Date()).isAfter(dob)){
    var formData = new FormData();
    formData.append('id',_id);
    formData.append('idfront',drivingfrontimage);
    formData.append('idback',drivingbackimage);
    formData.append('photofront',drivingselfie);
    formData.append('idtype',"drivinglicense");
    formData.append('number',drivingnumber);
    formData.append('name',name)
    formData.append('lastname',lastname)
    formData.append('email',email)
    formData.append('dob',dob)
    formData.append('userid',userid)

    var res = await postMethod(url+"api/update-kyc",formData)
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'error')
    }
    if(res.data && res.data.verified){
      toastAlert('error',res.data.verified,'error')
    }
    if(res.data && res.data.message){
      toastAlert('success',res.data.message,'success')
       getkycdetails(_id);
    }
    }else{
            toastAlert('error',"Enter Valid Date Of Birth",'error')
          }
        }else{
          toastAlert('error',"Enter Email",'error')
        }
      }else{
        toastAlert('error',"Enter Last Name",'error')
      }
    }else{
      toastAlert('error',"Enter First Name",'error')
    }
  }

  async function savepassportnational(){
     if(name !=null && name != undefined && name != ""){
      if(lastname !=null && lastname != undefined && lastname != ""){
        if(email !=null && email != undefined && email != ""){
          if(Moment(new Date()).isAfter(dob)){
    var formData = new FormData();
    formData.append('id',_id);
    formData.append('idfront',nationalfrontimage);
    formData.append('idback',nationalbackimage);
    formData.append('photofront',nationalselfie);
    formData.append('idtype',"nationalcard");
    formData.append('number',nationalnumber);
    formData.append('name',name)
    formData.append('lastname',lastname)
    formData.append('email',email)
    formData.append('dob',dob)
    formData.append('userid',userid)

    var res = await postMethod(url+"api/update-kyc",formData)
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'error')
    }
    if(res.data && res.data.verified){
      toastAlert('error',res.data.verified,'error')
    }
    if(res.data && res.data.message){
      toastAlert('success',res.data.message,'success')
       getkycdetails(_id);
    }
    }else{
            toastAlert('error',"Enter Valid Date Of Birth",'error')
          }
        }else{
          toastAlert('error',"Enter Email",'error')
        }
      }else{
        toastAlert('error',"Enter Last Name",'error')
      }
    }else{
      toastAlert('error',"Enter First Name",'error')
    }
  }

  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/kyc" className="active">KYC</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">KYC</h1>
              </div>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem sm={12} md={8} lg={8}>
              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <h4>Document Upload</h4>
                  <p>To verify your identity, please upload any of your document</p>
                  <hr/>
                  <p className="info_txt"><i class="fas fa-info-circle"></i> In order to complete, please upload any of the following personal document.</p>
                  <div className="kyc_tab">
                    <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="passport_tab-tab" data-toggle="pill" href="#passport_tab" role="tab" aria-controls="passport_tab" aria-selected="true">
                          <img src={require("../assets/images/passport_icon.png")} alt="Icon" className="img-fluid" /> 
                          <span>Passport</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="national_card_tab-tab" data-toggle="pill" href="#national_card_tab" role="tab" aria-controls="national_card_tab" aria-selected="false">
                          <img src={require("../assets/images/nationcard_icon.png")} alt="Icon" className="img-fluid" /> 
                          <span>National Card</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="license_tab-tab" data-toggle="pill" href="#license_tab" role="tab" aria-controls="license_tab" aria-selected="false">
                          <img src={require("../assets/images/driving_icon.png")} alt="Icon" className="img-fluid" /> 
                          <span>Driving License</span>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="passport_tab" role="tabpanel" aria-labelledby="passport_tab-tab">
                      <form className="auth_form mt-5">
                       <div className="form-row">
                        <div className="form-group col-md-6">
                        <TextField id="filled-name" label="First Name" value={name} onChange={(e) => {namechange(e.target.value)}} type="text" variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-6">
                        <TextField id="filled-name" label="Last Name" value={lastname} onChange={(e) => {lastnamechange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <TextField id="filled-name" label="Email Address" value={email} onChange={(e) => {emailchange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                            <img src={require("../assets/images/email_icon.png")} alt="Input"/>
                          </InputAdornment>,}} />
                        </div>
                        <div className="form-group col-md-6">
                         <TextField id="filled-name" label="Date of Birth" value={Moment(dob).format('YYYY-MM-DD')} onChange={(e) => {dobchange(e.target.value)}} variant="filled" type="date"  InputProps={{startAdornment: <InputAdornment position="start">
                           <img src={require("../assets/images/form_date_icon.png")} alt="Input"/>
                         </InputAdornment>,}} />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <TextField id="filled-name" label="Passport Number" value={passportnumber} onChange={(e) => {passportnumberchange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                            <img src={require("../assets/images/form_passport_icon.png")} alt="Input"/>
                          </InputAdornment>,}} />
                        </div>
                      </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Front Image"  value={passportfrontimage.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 1">
                                <input accept="image/*" onChange={(e) => {passportfrontimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file1" type="file" />
                                <label htmlFor="icon-button-file1">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Back Image" value={passportbackimage.name} type="text"  disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 2">
                                <input accept="image/*"  onChange={(e) => {passportbackimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file2" type="file" />
                                <label htmlFor="icon-button-file2">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                          </div>
                          <div className="form-row">
                          {passportstatus=='Pending'?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/front_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewfrontpassport} alt="Icon" className="upload_image sssss img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus=='Verified'?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/front_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewfrontpassport} alt="Icon" className="upload_image sssss img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus=='Rejected'?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/front_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewfrontpassport} alt="Icon" className="upload_image sssss img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus==''?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/front_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewfrontpassport} alt="Icon" className="upload_image sssss img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus=='Pending'?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/back_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewbackpassport} alt="Icon" className="upload_image ggggg img-fluid" /> 
                         </div>
                          ) : (" ")}
                          {passportstatus=='Verified'?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/back_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewbackpassport} alt="Icon" className="upload_image ggggg img-fluid" /> 
                         </div>
                          ) : (" ")}
                          {passportstatus=='Rejected'?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/back_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={url+"kyc/"+viewbackpassport} alt="Icon" className="upload_image ggggg img-fluid" /> 
                         </div>
                          ) : (" ")}
                           {passportstatus==''?(
                           <div className="form-group col-md-6 text-center">
                           {/* <img src={require("../assets/images/back_page_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                           <img src={viewbackpassport} alt="Icon" className="upload_image ggggg img-fluid" /> 
                         </div>
                          ) : (" ")}
                            
                          </div>
                          <hr/>
                          <h4>Selfie with ID Proof</h4>
                          <p>To verify your identity, please upload any of your document</p>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Selfie Image" value={passportselfie.name}  type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 3">
                                <input accept="image/*" onChange={(e) => {passportselfiechange(e.target.files[0])}} className={classes.input} id="icon-button-file" type="file" />
                                <label htmlFor="icon-button-file">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                            {passportstatus==''?(
                          <div className="form-group col-md-6 text-center">
                          {/* <img src={require("../assets/images/selfie_photo_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                          <img src={viewselfiepassport} alt="Icon" className="upload_image img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus=='Pending'?(
                          <div className="form-group col-md-6 text-center">
                          {/* <img src={require("../assets/images/selfie_photo_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                          <img src={viewselfiepassport} alt="Icon" className="upload_image img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus=='Verified'?(
                          <div className="form-group col-md-6 text-center">
                          {/* <img src={require("../assets/images/selfie_photo_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                          <img src={viewselfiepassport} alt="Icon" className="upload_image img-fluid" />
                           </div>
                          ) : (" ")}
                          {passportstatus=='Rejected'?(
                          <div className="form-group col-md-6 text-center">
                          {/* <img src={require("../assets/images/selfie_photo_sample.png")} alt="Icon" className="upload_image img-fluid" /> */}
                          <img src={viewselfiepassport} alt="Icon" className="upload_image img-fluid" />
                           </div>
                          ) : (" ")}
                          </div>
                          {passportstatus==''?(
                          <div className="text-center">
                            <Button className="join_btn" onClick={()=>{savepassport()}}><span className="join_btn_span">Submit</span></Button>
                          </div>
                          ) : (" ")}
                          {passportstatus=='Pending'?(
                          <div className="text-center">
                            <Button className="join_btn"><span className="join_btn_span">Pending</span></Button>
                          </div>
                          ) : (" ")}
                           {passportstatus=='Verified'?(
                          <div className="text-center">
                            <Button className="join_btn "><span className="join_btn_span">Verified</span></Button>
                          </div>
                          ) : (" ")}
                          {passportstatus=='Rejected'?(
                          <div className="text-center">
                            <Button className="join_btn" onClick={()=>{savepassport()}}><span className="join_btn_span">Rejected Re-Submit</span></Button>
                          </div>
                          ) : (" ")}
                        </form>
                      </div>
                      <div class="tab-pane fade" id="national_card_tab" role="tabpanel" aria-labelledby="national_card_tab-tab">
                        <form className="auth_form mt-5">
                        <div className="form-row">
                        <div className="form-group col-md-6">
                        <TextField id="filled-name" label="First Name" value={name} onChange={(e) => {namechange(e.target.value)}} type="text" variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-6">
                        <TextField id="filled-name" label="Last Name" value={lastname} onChange={(e) => {lastnamechange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <TextField id="filled-name" label="Email Address" value={email} onChange={(e) => {emailchange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                            <img src={require("../assets/images/email_icon.png")} alt="Input"/>
                          </InputAdornment>,}} />
                        </div>
                        <div className="form-group col-md-6">
                         <TextField id="filled-name" label="Date of Birth" value={Moment(dob).format('YYYY-MM-DD')} onChange={(e) => {dobchange(e.target.value)}} variant="filled" type="date"  InputProps={{startAdornment: <InputAdornment position="start">
                           <img src={require("../assets/images/form_date_icon.png")} alt="Input"/>
                         </InputAdornment>,}} />
                        </div>
                      </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <TextField id="filled-name" label="National Card Number" onChange={(e) => {nationalnumberchange(e.target.value)}} value={nationalnumber}  variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_national_icon.png")} alt="Input"/>
                              </InputAdornment>,}} />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Front Image" value={nationalfrontimage.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 4">
                                <input accept="image/*" onChange={(e) => {nationalfrontimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file4" type="file" />
                                <label htmlFor="icon-button-file4">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Back Image" value={nationalbackimage.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 5">
                                <input accept="image/*" onChange={(e) => {nationalbackimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file5" type="file" />
                                <label htmlFor="icon-button-file5">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                          </div>
                          <div className="form-row">
                          {nationalstatus=='Pending'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewfrontnational} alt="Icon" className="upload_image ffimg-fluid" />
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Rejected'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewfrontnational} alt="Icon" className="upload_image ffimg-fluid" />
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Verified'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewfrontnational} alt="Icon" className="upload_image ffimg-fluid" />
                         </div>
                          ) : (" ")}
                          {nationalstatus==''?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewfrontnational} alt="Icon" className="upload_image ffimg-fluid" />
                          </div>
                          ) : (" ")}
                          {nationalstatus=='Pending'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewbacknational} alt="Icon" className="upload_image bbimg-fluid" />
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Rejected'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewbacknational} alt="Icon" className="upload_image bbimg-fluid" />
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Verified'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewbacknational} alt="Icon" className="upload_image bbimg-fluid" />
                         </div>
                          ) : (" ")}
                          {nationalstatus==''?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewbacknational} alt="Icon" className="upload_image bbimg-fluid" />
                          </div>
                          ) : (" ")}
                          
                          </div>
                          <hr/>
                          <h4>Selfie with ID Proof</h4>
                          <p>To verify your identity, please upload any of your document</p>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Selfie Image" value={nationalselfie.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 6">
                                <input accept="image/*" onChange={(e) => {nationalselfiechange(e.target.files[0])}} className={classes.input} id="icon-button-file6" type="file" />
                                <label htmlFor="icon-button-file6">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                            {nationalstatus==''?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfienational} alt="Icon" className="upload_image seimg-fluid" />
                           </div>
                           ) : (" ")}
                            {nationalstatus=='Pending'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfienational} alt="Icon" className="upload_image seimg-fluid" />
                           </div>
                           ) : (" ")}
                            {nationalstatus=='Verified'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfienational} alt="Icon" className="upload_image seimg-fluid" />
                           </div>
                           ) : (" ")}
                            {nationalstatus=='Rejected'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfienational} alt="Icon" className="upload_image seimg-fluid" />
                           </div>
                           ) : (" ")}
                            
                          </div>
                          {nationalstatus==''?(
                           <div className="text-center">
                           <Button className="join_btn" onClick={()=>{savepassportnational()}}><span className="join_btn_span">Submit</span></Button>
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Pending'?(
                           <div className="text-center">
                           <Button className="join_btn"><span className="join_btn_span">Pending</span></Button>
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Verified'?(
                           <div className="text-center">
                           <Button className="join_btn"><span className="join_btn_span">Verified</span></Button>
                         </div>
                          ) : (" ")}
                          {nationalstatus=='Rejected'?(
                           <div className="text-center">
                           <Button className="join_btn" onClick={()=>{savepassportnational()}}><span className="join_btn_span">Rejected Re-Submit</span></Button>
                         </div>
                          ) : (" ")}
                        </form>
                      </div>
                      <div class="tab-pane fade" id="license_tab" role="tabpanel" aria-labelledby="license_tab-tab">
                        <form className="auth_form mt-5">
                        <div className="form-row">
                        <div className="form-group col-md-6">
                        <TextField id="filled-name" label="First Name" value={name} onChange={(e) => {namechange(e.target.value)}} type="text" variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-6">
                        <TextField id="filled-name" label="Last Name" value={lastname} onChange={(e) => {lastnamechange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <TextField id="filled-name" label="Email Address" value={email} onChange={(e) => {emailchange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                            <img src={require("../assets/images/email_icon.png")} alt="Input"/>
                          </InputAdornment>,}} />
                        </div>
                        <div className="form-group col-md-6">
                         <TextField id="filled-name" label="Date of Birth" value={Moment(dob).format('YYYY-MM-DD')} onChange={(e) => {dobchange(e.target.value)}} variant="filled" type="date"  InputProps={{startAdornment: <InputAdornment position="start">
                           <img src={require("../assets/images/form_date_icon.png")} alt="Input"/>
                         </InputAdornment>,}} />
                        </div>
                      </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <TextField id="filled-name" label="Driving License Number" value={drivingnumber} onChange={(e) => {drivingnumberchange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_driving_icon.png")} alt="Input"/>
                              </InputAdornment>,}} />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Front Image" value={drivingfrontimage.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 7">
                                <input accept="image/*" onChange={(e) => {drivingfrontimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file7" type="file" />
                                <label htmlFor="icon-button-file7">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Back Image" value={drivingbackimage.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 8">
                                <input accept="image/*" onChange={(e) => {drivingbackimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file8" type="file" />
                                <label htmlFor="icon-button-file8">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                          </div>
                          <div className="form-row">
                          {drivingstatus==''?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewfrontdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                           {drivingstatus=='Pending'?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewfrontdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                           {drivingstatus=='Verified'?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewfrontdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                           {drivingstatus=='Rejected'?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewfrontdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                            {drivingstatus==''?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewbackdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                            {drivingstatus=='Pending'?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewbackdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                           {drivingstatus=='Verified'?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewbackdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                           {drivingstatus=='Rejected'?(
                          <div className="form-group col-md-6 text-center">
                          <img src={viewbackdriving} alt="Icon" className="upload_image img-fluid" />
                          </div>
                           ) : (" ")}
                            
                          </div>
                          <hr/>
                          <h4>Selfie with ID Proof</h4>
                          <p>To verify your identity, please upload any of your document</p>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <TextField id="filled-name" label="Upload Selfie Image" value={drivingselfie.name} type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                                <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                              </InputAdornment>,}}/>
                              <div className="upload_btn 9">
                                <input accept="image/*" onChange={(e) => {drivingselfiechange(e.target.files[0])}} className={classes.input} id="icon-button-file9" type="file" />
                                <label htmlFor="icon-button-file9">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                              <p className="upload_note">(*Note:  .jpg / .png / .gif  max 5mb)</p>
                            </div>
                            {drivingstatus==''?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfiedriving} alt="Icon" className="upload_image img-fluid" />
                           </div>
                           ) : (" ")}
                           {drivingstatus=='Pending'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfiedriving} alt="Icon" className="upload_image img-fluid" />
                           </div>
                           ) : (" ")}
                           {drivingstatus=='Rejected'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfiedriving} alt="Icon" className="upload_image img-fluid" />
                           </div>
                           ) : (" ")}
                           {drivingstatus=='Verified'?(
                           <div className="form-group col-md-6 text-center">
                           <img src={viewselfiedriving} alt="Icon" className="upload_image img-fluid" />
                           </div>
                           ) : (" ")}
                           
                          </div>
                          {drivingstatus==''?(
                            <div className="text-center">
                            <Button className="join_btn" onClick={()=>{savepassportdriving()}}><span className="join_btn_span">Submit</span></Button>
                          </div>
                          ) : (" ")}
                          {drivingstatus=='Pending'?(
                            <div className="text-center">
                            <Button className="join_btn"><span className="join_btn_span">Pending</span></Button>
                          </div>
                          ) : (" ")}
                          {drivingstatus=='Rejected'?(
                            <div className="text-center">
                            <Button className="join_btn" onClick={()=>{savepassportdriving()}}><span className="join_btn_span">Rejected Re-Submit</span></Button>
                          </div>
                          ) : (" ")}
                          {drivingstatus=='Verified'?(
                            <div className="text-center">
                            <Button className="join_btn"><span className="join_btn_span">Verified</span></Button>
                          </div>
                          ) : (" ")}
                         
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem sm={12} md={4} lg={4}>
              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <h4 className="mb-3">KYC - Photo ID Proof</h4>
                  <p><b>To verify your account please submit any one ID proof. </b></p>
                  <p>A valid government issued ID, acceptable high quality images of documents are</p>
                  <ul className="kyc_list">
                    <li>International passport (double page)</li>
                    <li>National ID card (both sides)</li>
                    <li>Driver's license (both sides)</li>
                    <li>VISIBLE in their ENTIRETY (watermarks are not permitted)</li>
                    <li>Images of HIGH QUALITY (color images, 300dpi resolution or higher)</li>
                  </ul>
                  <hr/>
                  <h4 className="mb-3">Selfie picture trader holding the ID use to register on the platform notice</h4>
                  <p>To avoid delays when verifying your account, make sure that your submitted documents are</p>
                  <ul className="kyc_list">
                    <li>VISIBLE in their ENTIRETY</li>
                    <li>Images of HIGH QUALITY (color images, 300dpi resolution or higher)</li>
                    <li>Valid ID documents, with the expiry date clearly visible</li>
                  </ul>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
