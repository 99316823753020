import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import { Link,useHistory ,useParams} from "react-router-dom";
import config from "actions/config"
import axios from "axios";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  FORGOT,
  USER_LOADING,
  USER_ADD,
  SUPPORT_REPLY,
  SUPPORT
} from "../actions/types";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
const url=config.baseUrl;

export default function Resetpassword(props) {

  const [password, setpassword] = React.useState('');
  const [repassword, setrepassword] = React.useState('');
  const history = useHistory();
  const { ...rest } = props;
  var   {  userId } = useParams();

  async function passwordchange(password){
    setpassword(password);
  }
  
  async function repasswordchange(repassword){
    setrepassword(repassword);
  }
  
  async function formValidate(){
    var isValid = true;
    var error ="";
    if(password=="" || typeof password=="undefined"){
      isValid = false;
      error ="Must Enter Password";
    }

    if(repassword=="" || typeof repassword=="undefined"){
        isValid = false;
        error ="Again Enter Password";
      }

    if(!isValid){
      toastAlert('error',error,'validate')
    }else{
      return isValid;
    }

  }

  async function save(){

    if(formValidate()){
      const resetuser = {
        _id:userId,
        password: password,
        password2: repassword
      };
      var res = await postMethod(url+"api/resetpassword", resetuser);
      if(res.data && res.data.errmessage){
        toastAlert('success',res.data.errmessage,'success')
        
        }
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
        history.push('/login')
        }
    }
  }
  

  const classes = useStyles();
  return (
    <div>
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
    <div className="auth_wrapper">
      <div className="container">
        <GridContainer className="align-items-center">
          <GridItem sm={12} md={6} lg={6}>
            <div className="login_bg">
              <img src={require("../assets/images/login_img.png")} alt="Filipcoin" className="img-fluid" />
              <h4>Decentralized Network for Transportation and other Service Economy Applications</h4>
            </div>
          </GridItem>
          <GridItem sm={12} md={6} lg={6}>
            <div className="auth_form_div">
              <div className="text-right">
                <Link to="/">
                  <img src={require("../assets/images/logo.png")} alt="Filipcoin" className="img-fluid login_logo" />
                </Link>
              </div>
              <h2 className="auth_title">Reset Password</h2>
              <p>Please enter your new password</p>
                <form className="auth_form">
                <div className="form-group">
                  <label>Password</label>
                  <div className="inp_grp">
                    <input id="filled-name" type="password" onChange={(e) => {passwordchange(e.target.value)}} variant="filled" className="primary_inp"/>
                  </div>                    
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="inp_grp">
                      <input id="filled-name" type="password" onChange={(e) => {repasswordchange(e.target.value)}} variant="filled" className="primary_inp"/>
                    </div>
                  </div>              
                  <div className="d-flex align-items-center justify-content-between">
                    <Button className="join_btn" onClick={()=>{save()}}><span className="join_btn_span">Submit</span></Button>
                    <Link to="/login" className="font_13">Login</Link>
                  </div>
                  <div className="social_login mt-4">
                    <p>or you can join with us</p>
                    <ul>
                    <li><a href="https://t.me/filipcoinfilipchannel" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                      <li><a href="https://t.me/filipcoinfilip" target="_blank"><i className="far fa-comments"></i></a></li>
                      <li><a href="https://twitter.com/filipcoinfcp" target="_blank"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.instagram.com/filipcoinfcp/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                      <li><a href="https://filipcoin.medium.com/" target="_blank"><i className="fab fa-medium-m"></i></a></li>
                      <li><a href="https://www.youtube.com/channel/UC4_t-U8vWCZgnhr7e4FYL3A" target="_blank"><i class="fab fa-youtube"></i></a></li>
                      <li><a href="https://www.facebook.com/filipcoinfcp" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    </ul>
                  </div>
                </form>
            </div>
          </GridItem>
        </GridContainer>
      </div>      
    </div>
    <Footer/>
    </div>
  );
}
