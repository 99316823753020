import React , { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import Select from 'react-select';
import Moment from 'moment';
import QRCode from 'qrcode.react';
import CryptoJS from "crypto-js";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link ,useHistory} from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, Button, TableHead, TextField,  FormControl } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(date, amount, id, eth, usd, status) {
  return { date, amount, id, eth, usd, status };
}

const rows = [
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Failed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Failed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
].sort((a, b) => (a.id > b.id ? -1 : 1));
																		
export default function WithdrawToken(props) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [_id, set_id] = React.useState('');
  const [buyamount, setbuyamount] = React.useState(0);
  const [currency, setcurrency] = React.useState('');
  const [loading, setloading] = React.useState(false);
  const [amount, setamount] = React.useState(0);
  const [currencydata, setcurrencydata] = React.useState('');
  const [historyList, sethistoryList] = React.useState([]);
  const [token_price_btc, settoken_price_btc] = React.useState(0);
  const [ethfee, setethfee] = React.useState(0);
  const [btcfee, setbtcfee] = React.useState(0);
  const [transactionfee, settransactionfee] = React.useState(0);
  const [token_price_eth, settoken_price_eth] = React.useState(0); 
  const [walletaddress, setwalletaddress] = React.useState('');
  const [ethaddress, setethaddress] = React.useState('0x000000000000000000');
  const [btcaddress, setbtcaddress] = React.useState('0x000000000000000000');
  const [fcpbal,setfcpbal] = React.useState('');
  const [withdrawaddress,setwithdrawaddress] = React.useState('');
  const [kycstatus,setkycstatus] =  React.useState('Not Verified');
  const [toaddress,settoaddress] =  React.useState('');
       
  useEffect(()  => {
    getuserid();
  },[])

  async function getuserid(){
    try{
      var name = "Bearer ";
      var authToken = localStorage.getItem('jwtToken')
      var token = authToken.replace(name, "");
      var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
      var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
      jwt.verify(token, decrypt_val, (err, verified) => {
      if(!err && verified && verified.id){
      if( verified.id!=""  &&  verified.id!=undefined && _id==""){
        getcurrency();
        getbuytokenlist(verified.id);
        set_id(verified.id);
      }
      }else{
        history.push('/login')
      }
    });
  }catch(err){
  }
  }

  var historylist=[];

  async function getcurrency(){
    var res = await getMethod(url+"api/currencydata");
    if(res.data && res.data.data){
      getvalue();
      setcurrencydata(res.data.data);
    }
  }

  async function getvalue(){
    var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data){
      
      settoken_price_btc(res.data.data[0].token_price_btc);
      settoken_price_eth(res.data.data[0].token_price_eth);
      setethfee(res.data.data[0].transaction_fee);
      setbtcfee(res.data.data[0].transaction_fee1);
    }
  }

  async function getbuytokenlist(iid){
    var res = await getMethod(url+"api/gettransfertokenhistory/"+iid);
    
    if(res.data && res.data.data){
      var log=res.data.data;
      getuserdetails(iid);
      getwallet(iid);
      getaddress(iid);
      sethistoryList(log);
    }
  }

  async function getaddress(iid){
    var res = await getMethod(url+"api/getaddresss/"+iid);
    if(res.data && res.data.data){
      setwalletaddress(res.data.data.address);
      setethaddress(res.data.data.address);
    }
  }
  

  async function getwallet(iid){
    var res = await getMethod(url+"api/getuserwallet/"+iid);
    
     if(res.data && res.data.fcp){
        var babal=(res.data.fcp).substring(0, 8);
      setfcpbal(babal);
      
    }
  }

  async function getuserdetails(iid){
   
    var newuser={
      id:iid,
    }
    var res = await postMethod(url+"api/getuserdetails",newuser);
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
    }
    if(res.data && res.data.errmessage){
      toastAlert('error',res.data.errmessage,'validate')
    }

    if(res.data && res.data.data){
      if(res.data.data.verifiedstatus=='Verified'){
      setkycstatus(res.data.data.verifiedstatus);
      }
    }
    
  } 
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }; 

  async function amountchange(ee){
    
    setamount(ee);
  }

  async function toaddresschange(e){
    settoaddress(e);
  }

 

  async function transfer(){
  if(toaddress!="" && walletaddress!=toaddress){
  if(amount!="0" && amount!="" && toaddress!="" && amount>0){
        setloading(true);
        const Buydata = {
          id: _id,
          tokenvalue: amount,
          currency: "FCP",
          fromaddress:walletaddress,
          toaddress:toaddress
        };
        var res = await postMethod(url+"api/transfertokeninternal",Buydata);
        if(res.data && res.data.message){
          setloading(false);
          toastAlert('success',res.data.message,'success')
          window.location.reload();
         
        }
    
        if(res.data && res.data.errmsg){
          toastAlert('error',res.data.errmsg,'error')
          setloading(false);
        }
      }else{
        toastAlert('error',"Invalid Amount",'error')
      }
    }else{
      toastAlert('error',"Invalid Address",'error')
    }
  }

  const row=historylist.sort((a, b) => (a.id > b.id ? -1 : 1));

  const { ...rest } = props;
  const classes = useStyles();
  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/buy-token" className="active">Withdraw Token</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">Transfer Token</h1>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={6} lg={6} className="m-auto">
              <div className="inner_page_panel border_radius">
                <div className="dash_wallet_sec_panel d-block">
                  <div className="dash_wallet_sec_panel_left">
                    <h4 className="m-0"><span></span> Transfer Token </h4>
                    <div class="dashboard_details mt-3">
                      <p className="text-dark mb-0">Total Available balance</p>
                      <h2 className="text-dark mb-0">{fcpbal} FCP</h2>
                    </div>
                    <form className="auth_form support_form mt-3">
                      <div className="form-row">
                      <div className="form-group col-12 mb-3">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b>To ETH Address</b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={toaddress} onChange={(e) => {toaddresschange(e.target.value)}} variant="outlined"/>
                              <TextField id="outlined-basic" variant="outlined" value="Address" disabled className="w-25"/>
                            </div>
                          </FormControl>
                        </div>
                        <div className="form-group col-12 mb-3">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b> FCP Tokens </b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={amount} onChange={(e) => {amountchange(e.target.value)}} variant="outlined"/>
                              <TextField id="outlined-basic" variant="outlined" value="FCP" disabled className="w-25"/>
                            </div>
                          </FormControl>
                        </div>
                        
                           
                          
                          <center>
                           {loading == true ? (
                          <div>
                            <Loader
                              type="Watch"
                              color="#FFBB2A"
                              height={50}
                              width={50}
                            />
                          </div>
                        ) : (
                      <Button className="join_btn mt-3 mb-4" onClick={()=>{transfer()}}><span className="join_btn_span">Transfer</span></Button>
                        )}
                        </center>
                       </div>
                    
                    </form>
                    
                  </div>
                </div>
              </div>
            </GridItem>
           
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <h2 className="dash_subtitle">Recent Transaction History</h2>
              <TableContainer className="dash_table">
                <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Token Amount</TableCell>
                      <TableCell>From Address</TableCell>
                      <TableCell>To Address</TableCell>
                      
                      <TableCell>Status</TableCell>
                      <TableCell>Transaction Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? historyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : historyList
                    ).map((row) => (
                      <TableRow key={row._id}>
                        <TableCell scope="row">
                          {Moment(row.created_date).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left">
                          {row.transferamount}
                        </TableCell>
                        <TableCell align="left">
                          {row.fromaddress}                          
                        </TableCell>
                        <TableCell align="left">
                          {row.toAddress}
                        </TableCell>
                       
                        <TableCell align="left">
                          {"Completed"}
                        </TableCell>
                        <TableCell align="left">
                          {row.type}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>  
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
