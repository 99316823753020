import React , { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import Select from 'react-select';
import Moment from 'moment';
import QRCode from 'qrcode.react';
import CryptoJS from "crypto-js";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link ,useHistory} from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, Button, TableHead, TextField,  FormControl } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import Web3 from 'web3';
import '@metamask/legacy-web3'

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;
const dashboardRoutes = [];
console.log(config,"==================================================ccc")
const adminaddress = config.adminethaddress;
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(date, amount, id, eth, usd, status) {
  return { date, amount, id, eth, usd, status };
}

const rows = [
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Failed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Failed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
].sort((a, b) => (a.id > b.id ? -1 : 1));
																		
export default function WithdrawToken(props) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [_id, set_id] = React.useState('');
  const [buyamount, setbuyamount] = React.useState(0);
  const [currency, setcurrency] = React.useState('ETH');
  const [loading, setloading] = React.useState(false);
  const [amount, setamount] = React.useState(0);
  const [currencydata, setcurrencydata] = React.useState('');
  const [historyList, sethistoryList] = React.useState([]);
  const [token_price_btc, settoken_price_btc] = React.useState(0);
  const [ethfee, setethfee] = React.useState(0);
  const [btcfee, setbtcfee] = React.useState(0);
  const [transactionfee, settransactionfee] = React.useState(0);
  const [token_price_eth, settoken_price_eth] = React.useState(0); 
  const [walletaddress, setwalletaddress] = React.useState('0x000000000000000000');
  const [ethaddress, setethaddress] = React.useState('0x000000000000000000');
  const [btcaddress, setbtcaddress] = React.useState('0x000000000000000000');
  const [fcpbal,setfcpbal] = React.useState('');
  const [show,setshow] = React.useState(true);
  const [mainnet,setmainnet] = React.useState(true);
  const [gasfee,setgasfee] = React.useState('0');
  const [withdrawaddress,setwithdrawaddress] = React.useState('');
  const [kycstatus,setkycstatus] =  React.useState('Not Verified');
  const [bscfcbbal,setbscfcbbal] = React.useState("");
  const [token_price_bnb, settoken_price_bnb] = React.useState(0); 
  const [bnbfee, setbnbfee] = React.useState(0);
       
  useEffect(()  => {
    getuserid();
  },[])

  async function getuserid(){
    try{
      var name = "Bearer ";
      var authToken = localStorage.getItem('jwtToken')
      var token = authToken.replace(name, "");
      var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
      var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
      jwt.verify(token, decrypt_val, (err, verified) => {
      if(!err && verified && verified.id){
      if( verified.id!=""  &&  verified.id!=undefined && _id==""){
        getconnect();
        getcurrency();
        getbuytokenlist(verified.id);
        set_id(verified.id);
      }
      }else{
        history.push('/login')
      }
    });
  }catch(err){
  }
  }

  var historylist=[];

  async function connect(){
     toastAlert('error',"Please Login Metamask External",'error')
  }

  async function getconnect(){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {

        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setwithdrawaddress(result[0]);
            setshow(false);
           if( (window.web3.currentProvider.networkVersion==config.network && currency == "BNB")||(window.web3.currentProvider.networkVersion==config.network && currency == "ETH")){
              setmainnet(true);
            }else{
              setmainnet(false)
            }
          })
        }
      })
    }catch(err){}
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
   }
  }

  window.ethereum.on('accountsChanged', function (accounts) {
  getconnect();
  })

  window.ethereum.on('networkChanged', function (networkId) {
    if(networkId==config.network){
      setmainnet(true)
    }else{
      setmainnet(false)
    }
  })

  

  async function getcurrency(){
    var res = await getMethod(url+"api/currencydata");
    if(res.data && res.data.data){
      getvalue();
      setcurrencydata(res.data.data);
    }
  }

  async function choosemainnet(){
    toastAlert('error',"Please choose Mainnet in Metamask External",'error')
  }

  async function getvalue(){
    var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data){
      
      settoken_price_btc(res.data.data[0].token_price_btc);
      settoken_price_eth((parseFloat(res.data.data[0].token_price_eth)/parseFloat(res.data.data[0].token_price)).toFixed(4));
      setethfee(res.data.data[0].transaction_fee);
      setbtcfee(res.data.data[0].transaction_fee1);
      setbnbfee(res.data.data[0].transaction_bnbfee);
      settoken_price_bnb((parseFloat(res.data.data[0].token_price_BNB)/parseFloat(res.data.data[0].token_price)).toFixed(4))

    }
  }

  async function getbuytokenlist(iid){
    var res = await getMethod(url+"api/getwithdrawhistory/"+iid);
    
    if(res.data && res.data.data){
      var log=res.data.data;
      getuserdetails(iid);
      getwallet(iid);
      getaddress(iid);
      getbtcaddress(iid);
      sethistoryList(log);
    }
  }

  async function getaddress(iid){
    var res = await getMethod(url+"api/getaddresss/"+iid);
    if(res.data && res.data.data){
      setwalletaddress(res.data.data.address);
      setethaddress(res.data.data.address);
    }else{
      setwalletaddress("0*0000000000000");
    }
  }
  async function getbtcaddress(iid){
    var res = await getMethod(url+"api/getbtcaddress/"+iid);
    if(res.data && res.data.data){
      setbtcaddress(res.data.data.address);
    }else{
      setbtcaddress("0*0000000000000");
    }
  }

  async function getwallet(iid){
    var res = await getMethod(url+"api/getuserwallet/"+iid);
    
     if(res.data && res.data.fcp){
        var babal=(res.data.fcp).substring(0, 8);
      setfcpbal(babal);
    }
    if(res.data && res.data.bscfcp){
      var bscbabal=(res.data.bscfcp).substring(0, 8);
      setbscfcbbal(bscbabal)
    }
  }

  async function getuserdetails(iid){
   
    var newuser={
      id:iid,
    }
    var res = await postMethod(url+"api/getuserdetails",newuser);
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
    }
    if(res.data && res.data.errmessage){
      toastAlert('error',res.data.errmessage,'validate')
    }

    if(res.data && res.data.data){
      if(res.data.data.verifiedstatus=='Verified'){
      setkycstatus(res.data.data.verifiedstatus);
      }
    }
    
  } 
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleChange (event){
    setcurrency(event);
    if (event == "BTC") {
      setwalletaddress(btcaddress);
    }
    if (event == "ETH") {
      setwalletaddress(ethaddress);
    }
    if(buyamount!=""){
    if (event == "ETH") {
      var enteredamount = parseFloat(buyamount);
      var price = parseFloat(token_price_eth);
      var fullamount =parseFloat(enteredamount) * parseFloat(price);
      settransactionfee(ethfee);
      setamount(fullamount);
      
    }

    if (event == "BTC") {
      var enteredamount = parseFloat(buyamount);
      var price =parseFloat( token_price_btc);
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
      setwalletaddress(btcaddress);
      settransactionfee(btcfee);
    }

    if (event == '') {
      var enteredamount =parseFloat( buyamount);
      var price = 0;
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
    }
  }else{
    setamount(0);
  }
  };

  async function buyamountchange(ee){
    if(ee!=""){
      if(currency=="ETH"){
        var enteredamount = parseFloat(ee);
        var price = parseFloat(token_price_eth);
        var fullamount = parseFloat(enteredamount) * parseFloat(price);
        setamount(fullamount);
        settransactionfee(ethfee)
      }else if(currency=="BNB"){
        var enteredamount = parseFloat(ee);
        var price = parseFloat(token_price_bnb);
        var fullamount = parseFloat(enteredamount) * parseFloat(price);
        setamount(fullamount);
        settransactionfee(bnbfee)
      }
      if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
          if((currency=="BNB" && window.web3.currentProvider.networkVersion == config.network) || (currency=="ETH" && window.web3.currentProvider.networkVersion == config.network) ){
          web3.eth.getAccounts(function(error, result) {
            web3.eth.getGasPrice(function (err, getGasPrice) {
              web3.eth.estimateGas({from: result[0], to: withdrawaddress, amount: window.web3.toWei(ee)},function(err, gaslimit){
               var gasPrice = window.web3.toHex(getGasPrice);
               console.log(gasPrice,"===================gasprice")
               var a= window.web3.fromWei(((window.web3.toHex(100000)) * gasPrice), 'ether');
                 setwithdrawaddress(result[0]);
                 console.log(a,"==============================")
                 setgasfee(a);
                    setmainnet(true);
               })
            })
         })
         }else{
          setmainnet(false)
          toastAlert('error',"Please Choose "+currency+" MainNet",'error')
         }
        }
      })
    }catch(err){}
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
   }

  }else{
    setamount(0);
  }
  setbuyamount(ee);
  } 

  window.addEventListener('load', function () {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
           window.ethereum.on('accountsChanged', function (accounts) {
             getconnect();
            })
           window.ethereum.on('networkChanged', function (networkId) {
            if((networkId==config.network && currency == "BNB")||(networkId==config.network && currency == "ETH")){
               setmainnet(true)
            }else{
               setmainnet(false)
            }
          })
        }else{
        toastAlert('error',"Please Add Metamask External",'error')
        setmainnet(false)
      }
      }catch(err){
        setmainnet(false)
      }
    }else{
        toastAlert('error',"Please Add Metamask External",'error')
        setmainnet(false)
      }
  })

  const ethradiochange =(e) => {
   setcurrency("ETH")
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
          if( (window.web3.currentProvider.networkVersion == config.network) ){
           setmainnet(true)
         }else{
          setmainnet(false)
          toastAlert('error',"Please Choose ETH MainNet",'error')
         }
        }
      })
    }catch(err){}
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
   }
  }

  const bnbradiochange =(e) => {
    setcurrency("BNB")
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
          if( (window.web3.currentProvider.networkVersion == config.network) ){
           setmainnet(true)
         }else{
          setmainnet(false)
          toastAlert('error',"Please Choose BNB MainNet",'error')
         }
        }
      })
    }catch(err){}
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
   }
  }

  async function amountchange(ee){
    
    setamount(ee);
  }

  async function withdrawaddresschange(e){
    setwithdrawaddress(e);
  }

  async function formValidate(){
    var isValid = true;
    var error ="";
 
    if(buyamount=="" || buyamount=="undefined" || buyamount==NaN || buyamount==0 || buyamount=='0'){
      isValid = false;
      error ="Enter Valid Token";
    }
     if(gasfee=="" || gasfee=="undefined" || gasfee==NaN || gasfee==0 || gasfee=='0'){
      isValid = false;
      error ="Enter Valid Token";
    }

    if(!isValid){
      toastAlert('error',error,'validate')
    }else{
      return isValid;
    }
  }

  async function withdraw(){
   
     if(buyamount!="" && buyamount!="undefined" && buyamount!=NaN && buyamount!=0 && buyamount!='0'){
      if(gasfee!="" && gasfee!="undefined" && gasfee!=NaN && gasfee!=0 && gasfee!='0'){
      setloading(true);
      if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
           if( (window.web3.currentProvider.networkVersion==config.network && currency == "BNB")||(window.web3.currentProvider.networkVersion==config.network && currency == "ETH")){
              setmainnet(true);
          web3.eth.getAccounts(async function(error, result) {
            web3.eth.getGasPrice(async function (err, getGasPrice) {
              web3.eth.estimateGas({from: result[0], to: withdrawaddress, amount: window.web3.toWei(buyamount)},async function(err, gaslimit){
               var gasPrice = window.web3.toHex(getGasPrice);
               console.log(gasPrice,"===================gasprice")
               var a= window.web3.fromWei(((window.web3.toHex(100000)) * gasPrice), 'ether');
                 setwithdrawaddress(result[0]);
                 setgasfee(a);
                  const Buydatass = {
                    id: _id,
                       fromaddress:walletaddress,
                        tokenvalue: buyamount,
                        currency: currency=="ETH"? "FCP":"BSC-FCP",
                         amount: amount,
                     };
                 var res = await postMethod(url+"api/checkwithdrawrequest",Buydatass);
                     if(res.data && res.data.success){
                  web3.eth.sendTransaction({
                    from:result[0],
                    to:adminaddress,
                    value: window.web3.toWei(a)}, async function(err, transactionHash) {
                
        if(err){
         console.log(err,"==================error")
          toastAlert('error',"Something went wrong Please try again later",'error');
          setloading(false);
        }else{     
        setloading(true);
        const Buydata = {
          id: _id,
          tokenvalue: buyamount,
          currency: currency,
          targetcur : currency=="ETH"?"FCP":"BSC-FCP",
          amount: amount,
          fromaddress:walletaddress,
          toaddress:withdrawaddress,
          transfee:currency=="ETH"?ethfee:bnbfee,
          gasfee:a,
          gasfeetransactionhash:transactionHash
        };
        var res = await postMethod(url+"api/withdrawrequest",Buydata);
        if(res.data && res.data.message){
          setloading(false);
          toastAlert('success',res.data.message,'success')
          window.location.reload();
         
        }
    
        if(res.data && res.data.errmsg){
          toastAlert('error',res.data.errmsg,'error')
          setloading(false);
        }
    }  
               
             }).catch(error => {
               setloading(false);
         });
          }else{
     toastAlert('error',res.data.message,'validate')
     setloading(false);
   }
    
               })
            })
         })
         }else{
              setmainnet(false)
         }


        }
      })
    }catch(err){}
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
    setloading(false);
   }
      
       }else{
       toastAlert('error',"Enter Valid Amount",'validate')
       setloading(false);
     }
   }else{
   toastAlert('error',"Enter Valid Amount",'validate')
   setloading(false);
    }
      
  }

  const row=historylist.sort((a, b) => (a.id > b.id ? -1 : 1));

  const { ...rest } = props;
  const classes = useStyles();
  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/buy-token" className="active">Withdraw Token</Link></li>
              </ul>
              <div className="dash_heading flex-start">
                <h1 className="inner_title">Withdraw Token</h1>
                
                <div className="radio_burton">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio1" name="customRadio" checked={currency=="ETH"? true : false} 
                                   onChange={ethradiochange} class="custom-control-input"/>
                    <label class="custom-control-label" for="customRadio1">ETH</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio2" name="customRadio" checked={currency=="BNB"? true : false} 
                                   onChange={bnbradiochange} class="custom-control-input"/>
                    <label class="custom-control-label" for="customRadio2">BSC</label>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={6} lg={6} className="m-auto">
              <div className="inner_page_panel border_radius">
                <div className="dash_wallet_sec_panel d-block">
                  <div className="dash_wallet_sec_panel_left">
                    <h4 className="m-0"><span></span> Calculate Token Amount</h4>
                    <div class="dashboard_details mt-3">
                      <p className="text-dark mb-0">Total Available balance</p>
                      <h2 className="text-dark mb-0">{currency && currency=="ETH"? fcpbal : bscfcbbal} {currency && currency=="ETH"? "FCP" : "BSC-FCP"}</h2>
                    </div>
                    <label htmlFor="outlined-age-native-simple"><b>**Need to Login MetaMask to Withdraw FCP</b></label>
                    <form className="auth_form support_form mt-3">
                      <div className="form-row">
                      <div className="form-group col-12 mb-3">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b>{currency && currency=="ETH"? "ETH " : "BSC "} Withdrawal Address</b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={withdrawaddress} disabled onChange={(e) => {withdrawaddresschange(e.target.value)}} variant="outlined"/>
                              <TextField id="outlined-basic" variant="outlined" value="Withdraw Address" disabled className="w-25"/>
                            </div>
                          </FormControl>
                        </div>
                        <div className="form-group col-12 mb-3">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b>Tokens You Receive</b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={buyamount} disabled={show==false? "":"true"} onChange={(e) => {buyamountchange(e.target.value)}} variant="outlined"/>
                              <TextField id="outlined-basic" variant="outlined" value="FCP" disabled className="w-25"/>
                            </div>
                          </FormControl>
                        </div>
                        <div className="form-group col-12 mb-3">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b>Gas Fee</b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={gasfee} disabled  variant="outlined"/>
                              <TextField id="outlined-basic" variant="outlined" value="Gas Fee" disabled className="w-25"/>
                            </div>
                          </FormControl>
                        </div>
                        <div className="form-group col-12">
                         <center>
                           {loading == true ? (
                          <div>
                            <Loader
                              type="Watch"
                              color="#FFBB2A"
                              height={50}
                              width={50}
                            />
                          </div>
                        ) : (
                        <div>
                        {show == true ? (
                           <Button className="join_btn mt-3 mb-4" onClick={()=>{connect()}}><span className="join_btn_span">Connect Metamask</span></Button>
                          ):(
                          <div>
                          {mainnet == true ? (
                            <Button className="join_btn mt-3 mb-4" onClick={()=>{withdraw()}}><span className="join_btn_span">Withdraw</span></Button>
                            ):(
                            <Button className="join_btn mt-3 mb-4" onClick={()=>{choosemainnet()}}><span className="join_btn_span">Choose Mainnet To Withdraw</span></Button>
                            )}
                           </div>
                          )}
                        </div>
                        )}
                        </center>
                       </div>
                      </div>
                    </form>
                    
                  </div>
                </div>
              </div>
            </GridItem>
           
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <h2 className="dash_subtitle">Recent Transaction History</h2>
              <TableContainer className="dash_table">
                <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Token Amount</TableCell>
                      <TableCell>Transaction Hash</TableCell>
                      <TableCell>Value</TableCell>
                      
                      <TableCell>Status</TableCell>
                      <TableCell>Transaction Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? historyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : historyList
                    ).map((row) => (
                      <TableRow key={row._id}>
                        <TableCell scope="row">
                          {Moment(row.datetime).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left">
                          {row.withamount}
                        </TableCell>
                        <TableCell align="left">
                          {row.txid}                          
                        </TableCell>
                        <TableCell align="left">
                          {row.withcurrency.currencySymbol}
                        </TableCell>
                       
                        <TableCell align="left">
                          {row.status}
                        </TableCell>
                        <TableCell align="left">
                          Withdraw
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>  
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
