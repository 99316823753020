import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from '@material-ui/core/styles';
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinksafter_inner from "components/Header/HeaderLinksAfterLogin.js";
import { Link,useHistory,useParams } from "react-router-dom";
import { TextField, Button, FormControl } from '@material-ui/core';
import config from "../actions/config"
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  FORGOT,
  USER_LOADING,
  USER_ADD,
  SUPPORT_REPLY,
  SUPPORT
} from "../actions/types";
import { func } from "prop-types";

const url=config.baseUrl;
const dashboardRoutes = [];
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


export default function Contact(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [email, setemail] = React.useState('');
  const [name, setname] = React.useState('');
  const [subject, setsubject] = React.useState('');
  const [phonenumber, setphonenumber] = React.useState('');
  const [message, setmessage] = React.useState('');
  
  useEffect(()  => {
     getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

  async function emailchange(email){
    setemail(email);
  }

  async function namechange(name){
    setname(name);
  }

  async function mobilechange(mobile){
    setphonenumber(mobile);
  }

  async function subjectchange(subject){
    setsubject(subject);
  }

  async function messagechange(message){
    setmessage(message);
  }

  async function submitnow(){
    const userData = {
      name: name,
      mobilenumber: phonenumber,
      email: email,
      subject: subject,
      message: message,
    };
    var res = await postMethod(url+"api/contact-us",userData)
    
    if(res.data && res.data.message){
      toastAlert('success',res.data.message,'success')
      history.push('/home')
    }

    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'error')
    }

  }

  return (
    <div className="inner_pages">
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksafter_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/contact" className="active">Contact Us</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">Contact Us</h1>
              </div>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <p>Just send us your questions or concerns by starting a new case and we will give you the help you need</p>
                  <form className="auth_form support_form mt-3">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Full Name</label>
                          <TextField id="outlined-basic" onChange={(e) => {namechange(e.target.value)}} variant="outlined"/>
                        </FormControl>
                      </div>
                      <div className="form-group col-md-6">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Mobile Number</label>
                          <TextField id="outlined-basic" onChange={(e) => {mobilechange(e.target.value)}} variant="outlined"/>
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Email Address</label>
                          <TextField id="outlined-basic" onChange={(e) => {emailchange(e.target.value)}} variant="outlined"/>
                        </FormControl>
                      </div>
                      <div className="form-group col-md-6">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Subject</label>
                          <TextField id="outlined-basic" onChange={(e) => {subjectchange(e.target.value)}} variant="outlined"/>
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Message</label>
                          <TextField id="outlined-basic" onChange={(e) => {messagechange(e.target.value)}} rows="{4}" multiline variant="outlined"/>
                         </FormControl> 
                      </div>
                    </div>
                    <div className="text-center">
                      <Button className="join_btn" onClick={()=>{submitnow()}}><span className="join_btn_span">Submit</span></Button>
                    </div>
                  </form>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
