import React,{ useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinksafter_inner from "components/Header/HeaderLinksAfterLogin.js";
import { Link ,useHistory} from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const url=config.baseUrl;
const dashboardRoutes = [];


export default function PrivacyPolicy(props) {
 
  const { ...rest } = props;
  const history = useHistory();

  const [policy, setpolicy] = React.useState('');
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

   useEffect(()  => {
    getuserid();
    getpolicy();
   },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

  async function getpolicy(){
    var res = await getMethod(url+"api/getpolicy");
    if(res.data && res.data.data){
     
      setpolicy(res.data.data.policy);
    }
  }

  return (
    <div className="inner_pages">
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksafter_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}

      <div className="inner_pageheader">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">Privacy Policy</Link></li>
              </ul>
              <h1 className="inner_title">Privacy Policy</h1>
            </GridItem>
          </GridContainer>
          <GridContainer className="align-items-center">
            <GridItem sm={12} md={12} lg={12} className="mt-3">
              <div className="inner_page_panel">
              <div>{ ReactHtmlParser(policy) }</div>
             
               {/* <h2>Privacy Policy</h2>
                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. This Privacy Policy for softNio</p>
                <p className="mb-5">We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from http://filipcoin.com/ico</p>
                <div className="terms_list">
                  <div className="mb-4">
                    <h2>Information Collection And Use</h2>
                    <p>Every legitimate project that sources funds through an ICO has a website, where they specify what the project is all about, its goals, the amount of money needed, how long the funding campaign will go on for and so forth.</p>
                  </div>
                  <div className="mb-4">
                    <h2>Types of Data Collected</h2>
                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                  </div>
                  <div className="mb-4">
                    <h2>Use of Data</h2>
                    <p>Bitcoin, still being the single most dominant cryptocurrency, is accepted pretty much anywhere in the crypto world. However, as Ethereum offers a stable and convenient Blockchain platform for developers to set up their projects.</p>
                  </div>
                  <div className="mb-4">
                    <h2>Transfer Of Data</h2>
                    <p>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                  </div>
                </div> */}
                
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
