import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Objective(props) {

  const { ...rest } = props;
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  var   {  withdrawid } = useParams();
  
  useEffect(()  => {
     getuserid();
  },[])

async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

async function  isconfirm(){
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
      }
    }else{
      localStorage.clear();
    }
  }
  return (
    <div className="inner_pages">
      <ScrollToTopOnMount />
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}
      

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">About Us</Link></li>
              </ul>
              <h1 className="inner_title">About Us</h1>
            </GridItem>
          </GridContainer>
        </div>
        <nav className="fixed_sidenav">
          <ul>
            <li><NavLink to="/about">Introduction</NavLink></li>
            <li><NavLink to="/objective">Objective</NavLink></li>
            <li><NavLink to="/roadmap">Roadmap</NavLink></li>
            <li><NavLink to="/tokenomics">Tokenomics</NavLink></li>
          </ul>
        </nav>
        <div className="new_inner_content">
          <section className="intro_section section">
            <div className="container">
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12} className="mt-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel">
                    <h2>Objective</h2>
                    <p>FCP's objective is to supplement the development of the technological projects provided by our partner developer companies.</p>
                    <p>It will be responsible for the cryptocurrency assets, development of coins, and the continuous updates of future products and services under the FILIPCOIN blockchain. Funds received by the FCP organization will be utilized to further improve the FILIPCOIN in order to increase its value with proper integration on service economic application projects.</p>
                    <p className="mt-4">Here are our objectives:</p>
                    <ul className="obj_list">
                      <li>Provide smooth and secure transactions between apps, merchants, and users;</li>
                      <li>Provide the possibility of transferring value between users & merchants;</li>
                      <li>Make the process of exchanging/splitting of virtual currencies;</li>
                      <li>Payment gateway collaboration with different industries;</li>
                      <li>Create & provide mass payment facilities to fulfill community needs.</li>
                    </ul>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </section>                   

          <div className="container" data-aos="fade-up" data-aos-duration="2000">
            <div className="bottom_page_btn_grp">
              <Link to="/about" className="nxt_page_btn"><img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon prev_arrow_icon"/>Intro</Link>
              <Link to="/roadmap" className="nxt_page_btn">Roadmap <img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon"/></Link>
            </div>
          </div>
        </div>          
        <Footer/>
      </div>
    </div>
  );
}
