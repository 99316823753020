import React, { useState, useEffect }  from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import { TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import LinkedIn from "linkedin-login-for-react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import { MailOutline } from '@material-ui/icons';
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import browser from "browser-detect";
import 'react-toastify/dist/ReactToastify.css';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import jwt from 'jsonwebtoken';
import config from "../actions/config"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const url=config.baseUrl;
const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default function Register(props) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const history = useHistory();

  const { ...rest } = props;
  const classes = useStyles();
  const [email, setemail] = React.useState('');
  const [password, setpassword] = React.useState('');
  const [repassword, setrepassword] = React.useState('');
  const [termsandcond, settermsandcond] = React.useState(''); 
  const [validateErr, setvalidateErr] = React.useState(''); 
  const [countryName, setcountryName] = React.useState('');
  const [countryCode, setcountryCode] = React.useState('');
  const [ipaddress, setipaddress] = React.useState('');
  const [browsername, setbrowsername] = React.useState('');
  const [regionName, setregionName] = React.useState('');
  const [ismobile, setismobile] = React.useState('');
  const [TFAcode, setTFAcode] = React.useState('');
  const [region, setregion] = React.useState('');
  const [show, setshow] = React.useState('');
  const [referralid, setreferralid] = React.useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordreShown, setPasswordreShown] = useState(false);

  async function emailchange(email){
    setemail(email);
  }

  async function passwordchange(password){
    setpassword(password);
  }

  async function repasswordchange(repassword){
    setrepassword(repassword);
  }

  async function checkchange(termsandcon){
    settermsandcond(termsandcon);
  }

  async function referralidchange(refid){
    setreferralid(refid)
  }

  async function formValidate(){

    var isValid = true;
    var error ="";
    if(email=="" || typeof email=="undefined"){
      isValid = false;
      error ="Must Enter Email";
    }else if(password=="" || typeof password=="undefined"){
      isValid = false;
      error ="password is required";
    }else if(repassword=="" || typeof repassword=="undefined"){
      isValid = false;
      error ="confirm password is required";
    }else if(repassword=="" || typeof repassword=="undefined"){
      isValid = false;
      error ="confirm password is required";
    }else if(password!=repassword){
      isValid = false;
      error ="Password Mismatch";
    }

    if(!isValid){
      toastAlert('error',error,'validate')
    }else{
      return isValid;
    }

  }

  async function register(){
    formValidate();
      if(formValidate()){
        const newuser={
          email:email,
          password:password,
          password1:repassword,
          accept:termsandcond,
          referralid:referralid
        }

          var res = await postMethod(url+"api/registerfrontenduser",newuser)
          if(res.data && res.data.email){
            toastAlert('error',res.data.email,'validate')
          }
          if(res.data && res.data.password){
            toastAlert('error',res.data.password,'validate')
          }
          if(res.data && res.data.referralid){
            toastAlert('error',res.data.referralid,'validate')
          }
          if(res.data && res.data.password2){
            toastAlert('error',res.data.password2,'validate')
          }

          if(res.data && res.data.accept){
            toastAlert('error',res.data.accept,'validate')
          }

          if(res.data && res.data.message){
          toastAlert('success',res.data.message,'success')
          history.push('/login')
          }else{
          toastAlert('error',res.data,'validate')
          }
        // })
    }
              
           
      
  }

  const responseFacebook = (response) => {
      const result = browser();
      const loginhistory = {};
      loginhistory.countryCode = countryCode;
      loginhistory.countryName = countryName;
      loginhistory.ipaddress = ipaddress;
      loginhistory.regionName = region;
      loginhistory.browsername = result.name;
      loginhistory.ismobile = result.mobile;
      loginhistory.os = result.os;
      const userData = {
        email :"",
        socialuser: response.graphDomain ,
        socialid: response.userID,
        name: response.name,
        logininfo: loginhistory,
      };
      axios
      .post(url+"api/loginsocialuser", userData)
      .then(res => {
         if(res.data.socialid){
          toastAlert('error',res.data.socialid,'validate')
        }
        if(res.data && res.data.message){
          toastAlert('success',res.data.message,'success')
          localStorage.setItem("jwtToken", res.data.token);
          
          history.push('/dashboard')
          }
        if(res.data.errmessage){
          toastAlert('error',res.data.errmessage,'alert')
        }   
        if(res.data.tfa){
          setshow(true);
        }  
      })
      .catch(err =>{
        toastAlert('error',"Some Error occured Try again later",'Error')
      })
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglerePasswordVisiblity = () => {
    setPasswordreShown(passwordreShown ? false : true);
  };

   const responseGoogle = (response) => {
   const result = browser();
      const loginhistory = {};
      loginhistory.countryCode = countryCode;
      loginhistory.countryName = countryName;
      loginhistory.ipaddress = ipaddress;
      loginhistory.regionName = region;
      loginhistory.browsername = result.name;
      loginhistory.ismobile = result.mobile;
      loginhistory.os = result.os;
      const userData = {
        email:response.profileObj.email,
        socialuser: response.tokenObj.idpId ,
        socialid: response.profileObj.googleId,
        name: response.profileObj.name,
        logininfo: loginhistory,
      };
      axios
      .post(url+"api/loginsocialuser", userData)
      .then(res => {
         if(res.data.socialid){
          toastAlert('error',res.data.socialid,'validate')
        }
        if(res.data && res.data.message){
          toastAlert('success',res.data.message,'success')
          localStorage.setItem("jwtToken", res.data.token);
          
          history.push('/dashboard')
          }
        if(res.data.errmessage){
          toastAlert('error',res.data.errmessage,'alert')
        }   
        if(res.data.tfa){
          setshow(true);
        }  
      })
      .catch(err =>{
        toastAlert('error',"Some Error occured Try again later",'Error')
      })
  }

  return (
     <div>
    <div className="auth_wrapper">
      <div className="container">
        <GridContainer>
          <GridItem sm={12} md={6} lg={6}>
            <div className="login_bg">
              <img src={require("../assets/images/login_img.png")} alt="Filipcoin" className="img-fluid" />
              <h4>Decentralized Network for Transportation and other Service Economy Applications</h4>
            </div>
          </GridItem>
          <GridItem sm={12} md={6} lg={6}>
            <div className="auth_form_div">
              <div className="text-right">
                <Link to="/">
                  <img src={require("../assets/images/logo.png")} alt="Filipcoin" className="img-fluid login_logo" />
                </Link>
              </div>
              <h2 className="auth_title">Registration</h2>
              <p>To keep connected to FILIPCOIN, please log in with your personal information by email address and password.</p>
                <form className="auth_form">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input id="filled-name" type="email" onChange={(e) => {emailchange(e.target.value)}} variant="filled" className="primary_inp"/>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="inp_grp">
                      <input id="filled-name" type={passwordShown ? "text" : "password"} onChange={(e) => {passwordchange(e.target.value)}} variant="filled" className="primary_inp"/>
                      <i className="eye_icon" onClick={togglePasswordVisiblity}>{eye}</i>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="inp_grp">
                      <input id="filled-name" type={passwordreShown ? "text" : "password"} onChange={(e) => {repasswordchange(e.target.value)}} variant="filled" className="primary_inp"/>
                      <i className="eye_icon" onClick={togglerePasswordVisiblity}>{eye}</i>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Referral Id (Optional)</label>
                    <input id="filled-name" type="text" onChange={(e) => {referralidchange(e.target.value)}} variant="filled" className="primary_inp"/>                    
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <FormControlLabel control={<Checkbox name="checkedC" />}  onChange={(e) => {checkchange(e.target.checked)}} label="I accept and agree to the Terms & Conditions" className="mb-0 font_13"/>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <Button className="join_btn" onClick={()=>{register()}}><span className="join_btn_span">Register</span></Button>
                  </div>
                  <p className="font_13">Already registerd User? <Link to="/login">Sign In</Link></p>
                  <div className="social_login mt-4">
                    <p>or you can join with us</p>
                  {/* <ul>
                      <FacebookLogin
                          appId="321417155966190"
                          // autoLoad
                          callback={responseFacebook}
                          render={renderProps => (
                            <li><a onClick={renderProps.onClick}><i className="fab fa-facebook-f"></i></a></li>
                        
                          )}
                        />
                        <GoogleLogin
                            clientId="168176188309-60egdosbi246ba8p9sm29rc1bsn9r6q4.apps.googleusercontent.com"
                            render={renderProps => (
                              <li><a onClick={renderProps.onClick} disabled={renderProps.disabled}><i class="fab fa-google"></i></a></li>
                            )}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          />
                    </ul>*/}
                    <ul >
                    <li><a href="https://t.me/filipcoinfilipchannel" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                    <li><a href="https://t.me/filipcoinfilip" target="_blank"><i className="far fa-comments"></i></a></li>
                    <li><a href="https://twitter.com/filipcoinfcp" target="_blank"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/filipcoinfcp/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://filipcoin.medium.com/" target="_blank"><i className="fab fa-medium-m"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UC4_t-U8vWCZgnhr7e4FYL3A" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="https://www.facebook.com/filipcoinfcp" target="_blank"><i class="fab fa-facebook"></i></a></li>
                  </ul>
                  </div>
                </form>
            </div>
          </GridItem>
        </GridContainer>
      </div>      
    </div>
    </div>
  );
}
