import React , { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import Moment from 'moment';
import CryptoJS from "crypto-js";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link ,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import { Modal} from "react-bootstrap/";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import { TextField, InputAdornment, FormControlLabel, Checkbox, Button, FormControl, InputLabel, Select } from '@material-ui/core';
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, TableHead, InputBase  } from '@material-ui/core';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Search, AttachFile, Send } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  FORGOT,
  USER_LOADING,
  USER_ADD,
  SUPPORT_REPLY,
  SUPPORT
} from "../actions/types";

const url=config.baseUrl;
const dashboardRoutes = [];
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(id, created, issue, status) {
  return { id, created, issue, status };
}

const rows = [
  createData('578944', '13/10/2020 01:36 PM', "Withdraw Issue - no confirmation email when withdraw", <span className="text-success">Closed</span> ),
  createData('578944', '13/10/2020 01:36 PM', "Withdraw Issue", <span className="text-danger">Open</span>),
  createData('578944', '13/10/2020 01:36 PM', "Login Issue", <span className="text-success">Closed</span>),
  createData('578944', '13/10/2020 01:36 PM', "Withdraw Issue - no confirmation email when withdraw", <span className="text-success">Closed</span> ),
  createData('578944', '13/10/2020 01:36 PM', "Withdraw Issue", <span className="text-success">Closed</span>),
  createData('578944', '13/10/2020 01:36 PM', "Login Issue", <span className="text-success">Closed</span>),
  createData('578944', '13/10/2020 01:36 PM', "Withdraw Issue - no confirmation email when withdraw", <span className="text-success">Closed</span> ),
].sort((a, b) => (a.id > b.id ? -1 : 1));

export default function Support(props) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(()  => {
    getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
       getticketdetails(verified.id);
       set_id(verified.id);
    }
   
    }else{
      history.push('/login')
    }
  
   });
  }catch(err){

  }
  }

  const classes = useStyles();
  const { ...rest } = props;
  const [issue, setissue] = React.useState('');
  const [_id, set_id] = React.useState('');
  const [mail, setmail] = React.useState('');
  const [description, setdescription] = React.useState('');
  const [ticlist, setticlist] = React.useState([]);
  const [selectticlist, setselectticlist] = React.useState([]);
  const [message_requery, setmessage_requery] = React.useState([]);
   const [show,setshow]= React.useState(false);  
     const [selectid,setselectid]= React.useState(''); 


   const handleChange = (event) => {
    setissue(event.target.value);
  };
  
  async function getticketdetails(iid){
    const tic = {
      id: iid,
    }
    var res = await postMethod(url+"api/getticketdetails",tic);
    if(res.data && res.data.data){
     
      setticlist(res.data.data);
    }
  }


  async function mailchange(mail){
    setmail(mail);
  }
  
  async function selectchat (idd){
      const tic = {
      id: idd,
    }
       var res = await postMethod(url+"api/getselectticketdetails",tic);
    if(res.data && res.data.data){
     
      setselectticlist(res.data.data);
      setselectid(idd);
      setshow(true);
    }
  };
  
  async function closeticket (){
     var data={
        _id:selectid,
    }
      var res = await postMethod(url+"api/closesuppport",data);
    if(res.data && res.data){     
     toastAlert('success',"Successfully Closed",'success')
      window.location.reload();
    }
   
  }

  async function handleModal (showrest){
      setshow(showrest);
  };

  async function resmschange(sms){
    setmessage_requery(sms);
  }

  async function descriptionchange(description){
    setdescription(description);
  }

  async function replysend(iid){
    const replay = {
      userid:_id,
      _id:iid,
      message_requery:message_requery,
    };
    var res = await postMethod(url+"api/support_reply_user",replay);

    if(res.data && res.data.message){
      toastAlert('success',res.data.message,'success')
      window.location.reload();
    }
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
    }
    if(res.data && res.data.message_requery){
      toastAlert('error',res.data.message_requery,'validate')
    }
    

  }

  async function save(){
    const Newsupport = {
      id: _id,
      email_add: mail,
      subject: issue,
      description: description,
    };
    var res = await postMethod(url+"api/supportticketadd",Newsupport);

    if(res.data && res.data.email_add){
      toastAlert('error',res.data.email_add,'validate')
    }
    if(res.data && res.data.subject){
      toastAlert('error',res.data.subject,'validate')
    }
    if(res.data && res.data.message){
      toastAlert('success',res.data.message,'success')
      window.location.reload();
    }
    if(res.data && res.data.description){
      toastAlert('error',res.data.description,'validate')
    }


  }



  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/support" className="active">Support</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">Support</h1>
              </div>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <h4>Create Support Ticket</h4> 
                  <form className="auth_form support_form mt-3">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label htmlFor="outlined-age-native-simple">Select Your Issue</label>
                          <Select native value={issue} onChange={handleChange}>
                            <option>Choose Issue Type</option>
                            <option value="Buy Token With ETH Issue">Buy Token With BTC Issue</option>
                            <option value="Buy Token With ETH Issue">Buy Token With ETH Issue</option>
                            <option value="Change Password Issue">Change Password Issue</option>
                            <option value="2 Factor Authentication Issue">2 Factor Authentication Issue</option>
                            <option value="KYC Verification Issue">KYC Verification Issue</option>
                            <option value="Deposit BTC Issue">Deposit BTC Issue</option>
                            <option value="Deposit ETH Issue">Deposit ETH Issue</option>
                            <option value="Withdraw Issue">Withdraw Issue </option>
                            <option value="Deposit Issue">Deposit Issue</option>
                            <option value="Login Issue">Login Issue</option>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="form-group col-md-6">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Your Alternative Email</label>
                          <TextField id="outlined-basic" onChange={(e) => {mailchange(e.target.value)}} variant="outlined"/>
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <label>Describe Your Issue</label>
                          <TextField id="outlined-basic" rows="{4}" onChange={(e) => {descriptionchange(e.target.value)}} multiline variant="outlined"/>
                         </FormControl> 
                      </div>
                    </div>
                    <div className="text-left">
                      <Button className="join_btn" onClick={() => {save()}}><span className="join_btn_span">Submit</span></Button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <div className="dash_heading mb-3">
                    <h4>Ticket History</h4> 
                    <div className="ticket_history_search">
                      <InputBase className={classes.input} placeholder="Search Ticket ID/Date" inputProps={{ 'aria-label': 'search google maps' }}/>
                      <IconButton type="submit" className={classes.iconButton} aria-label="search"><Search /></IconButton>  
                    </div>  
                  </div>        
                  <TableContainer className="dash_table">
                    <Table className={classes.table} aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Ticket ID</TableCell>
                          <TableCell>Created On</TableCell>
                          <TableCell>Issue</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>View</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? ticlist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : ticlist
                        ).map((row) => (
                          <TableRow key={row.ticketno}>
                            <TableCell scope="row">
                              {row.ticketno}
                            </TableCell>
                            <TableCell align="left">
                              {row.created_date}
                            </TableCell>
                            <TableCell align="left">
                              <span className="issue_type">{row.subject}</span>                          
                            </TableCell>
                            <TableCell align="left">
                              {row.closedstatus}
                            </TableCell>
                             <TableCell align="left">
                              <button onClick={() => {selectchat(row._id)}} >View</button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={6}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer> 
                  
                </div>
              </div>
            </GridItem>
          </GridContainer>
           <Modal
                size="lg"
                show={show}
                onHide={() => {
                  handleModal(false);
                }}
                centered
                    >
                      <Modal.Header closeButton>
                        <h4 ></h4>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12 mx-auto">
                            <div className="buyAmt">
                              <div className="col-md-12">
                              <h4 className="mb-3">Description</h4> 
                  {(selectticlist > 0
                          ? selectticlist
                          : selectticlist
                        ).map((list) => (
                          <div>
                          <div className="ticket_details_panel">
                          <div className="user_img">
                          {list.userid.profileimage==''?(
                              <img  height="60px" width="80px" src={url+"image/Plus.png"} alt="User" />
                          ) : (
                              <img  height="60px" width="80px" src={url+"profile/"+list.userid.profileimage} alt="User" />
                            )} 
                          </div>
                          <div className="ticket_details_div">
                            <h2>{list.subject}</h2>
                            <p className="mb-1">Created  on  {Moment(list.created_date).format('DD-MM-YYYY')}</p>
                            <p className="mb-0 ticket_status_div">Status: <span className="text-danger">{list.closedstatus}</span></p>
                            <hr />
                            <p>{list.description}</p>
                            <p className="mt-3 mb-2">Thanks,<br/>{list.userid.name}</p>
                          </div>
                        </div>
                        {(list.reply > 0
                          ? list.reply
                          : list.reply
                        ).map((replay) => (
                          <div>
                          {replay.replytype == "user" ? (
                          <div className="ticket_details_panel mt-4">
                          <div className="user_img">
                          {list.userid.profileimage==''?(
                            <img  height="60px" width="80px" src={url+"image/Plus.png"} alt="User" />
                          ) : (
                            <img  height="60px" width="80px" src={url+"profile/"+list.userid.profileimage} alt="User" />
                          )} 
                          </div>
                          <div className="ticket_details_div">
                            <p className="mb-1">{list.userid.name} reply on {Moment(replay.replydate).format('DD-MM-YYYY')}</p>
                            <hr />
                            <p>{replay.message_query}</p>
                            <p className="mt-3 mb-2">Thanks,<br/>{list.userid.name}</p>
                          </div>
                        </div>  
                        ) : (
                           <div className="ticket_details_panel mt-4">
                              <div className="user_img">
                                <img src={require("../assets/images/admin_img.png")} alt="User" />
                              </div>
                              <div className="ticket_details_div">
                                <p className="mb-1">{replay.replytype} reply on {Moment(replay.replydate).format('DD-MM-YYYY')}</p>
                                <hr />
                                <p>{replay.message_query}</p>
                                <p className="mt-3 mb-2">Thanks,<br/>{replay.replytype}</p>
                              </div>
                            </div>  
                          )}  
                          </div>
                      ))}
                      <form className="mt-3"> 
                       <div className="form-row">
                        <div className="form-group col-md-12">

                        {list.closedstatus=="Open" ? (
                         <div className="msg_reply">
                            <IconButton type="button" className={classes.iconButton} aria-label="search"><AttachFile /></IconButton>  
                            <InputBase className={classes.input} value={message_requery} onChange={(e) => {resmschange(e.target.value)}} inputProps={{ 'aria-label': 'search google maps' }}/>
                            <IconButton type="button" onClick={() => {replysend(list._id)}} className={classes.iconButton} aria-label="search"><Send /></IconButton>  
                          </div>
                        ):("")}
                         
                        </div>
                       </div>
                      </form>
                    </div>
                 ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btnType1 smallFont py-2 px-3 mr-2"
                          onClick={() => {
                            handleModal(false);
                          }}
                        >
                          Back
                        </button>
                        
                           <button
                           className="btn btnType1 smallFont py-2 px-3 mr-2"
                          onClick={() => {
                            closeticket();
                          }}
                           >
                             Close Ticket
                           </button>
                        
                          
                        
                      </Modal.Footer>
                    </Modal>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
