import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Token(props) {

  const { ...rest } = props;
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  var   {  withdrawid } = useParams();
  
  useEffect(()  => {
     getuserid();
  },[])

async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

async function  isconfirm(){
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
      }
    }else{
      localStorage.clear();
    }
  }
  return (
    <div className="inner_pages">
      <ScrollToTopOnMount />
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}
      

      <div className="inner_pageheader term_inner">

        <section className="buy_fcp_section section text-center" id="buy_fcp">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">Token Sale</h2>
                <h1 className="inner_title">Buy FCP With</h1>                
                <div className="text-center">
                {localStorage.getItem('jwtToken') ? 
                  <a href="/buy-token/eth" target="_blank"><img src={require("../assets/images/token_img_01.png")} alt="Icon"  className="img-fluid mx-5 mb-5"/></a>
                  :
                  <a href="/login" target="_blank"><img src={require("../assets/images/token_img_01.png")} alt="Icon"  className="img-fluid mx-5 mb-5"/></a>
                }
                {localStorage.getItem('jwtToken') ? 
                  <a href="/buy-token/bnb" target="_blank"><img src={require("../assets/images/token_img_02.png")} alt="Icon"  className="img-fluid mx-5 mb-5"/></a>
                  :
                  <a href="/login" target="_blank"><img src={require("../assets/images/token_img_02.png")} alt="Icon"  className="img-fluid mx-5 mb-5"/></a>
                }
                </div>
              </GridItem>
            </GridContainer> 
          </div>
        </section>

        <section className="uniswap_token_section dark_bg dark_bg_gradient section text-center" id="uniswap_token">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">EXCHANGE</h2>
                <h1 className="inner_title">UNISWAP</h1>
                <img src={require("../assets/images/unisawp_icon.png")} alt="Filipcoin" className="img-fluid my-5" />
              </GridItem>
            </GridContainer> 
          </div>
        </section>

        <section className="pancake_section section" id="pancake">
          <div className="container"> 
            <GridContainer className="text-center">
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <img src={require("../assets/images/pancake_logo.png")} alt="Mobile App" className="img-fluid" />
                <h2 className="padding_txt">6-Step Guide on Trading FCP on PancakeSwap</h2>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} className="m-auto">
                <div className="stepbox_panel" data-aos="fade-up" data-aos-duration="2000">
                  <div className="stepbox_panel_left">
                    <h3>STEP 1: ADD FCP to your TrustWallet BEP20 Wallet</h3>
                    <ol>
                      <li>On your TrustWallet Account, go to SEARCH TOKENS menu.</li>
                      <li>Tap ADD CUSTOM TOKEN</li>
                      <li>Add FCP:</li>
                    </ol>
                    <div className="stepone_panel">
                      <div>
                        <label>Network:</label>
                        <label>Smart Chain</label>
                      </div>
                      <div>
                        <label>Token Contract Address:</label>
                        <label>
                          <span>0x155e8a74dac3d8560ddabbc26aa064b764535193</span>
                          <span><img src={require("../assets/images/copy_icon.png")} alt="Filipcoin" className="img-fluid" /></span>
                          <span><img src={require("../assets/images/share_icon.png")} alt="Filipcoin" className="img-fluid" /></span>
                        </label>
                      </div>
                      <div>
                        <label>Token Symbol:</label>
                        <label>FCP</label>
                      </div>
                      <div>
                        <label>Token Decimal:</label>
                        <label>18</label>
                      </div>
                    </div>
                  </div>
                  <div className="stepbox_panel_right">
                    <img src={require("../assets/images/step_img_01.png")} alt="Filipcoin" className="img-fluid" />
                  </div>
                </div>

                <div className="stepbox_panel" data-aos="fade-up" data-aos-duration="2000">
                  <div className="stepbox_panel_left">
                    <h3>STEP 2: CASH IN VIA COINSPH</h3>
                    <ol>
                      <li>On your CoinsPH account, tap CASH IN.</li>
                      <li>Cash in at least PHP3200. (Computation based on the minimum required amount of XRP BNB conversion.)</li>
                      <li>Buy XRP
                        <ol>
                          <li>Tap XRP</li>
                          <li>Tap TRADE</li>
                          <li>Tap BUY</li>
                        </ol>
                      </li>
                      <li>Convert PHP to XRP
                        <ol>
                          <li>Convert All PHP to XRP</li>
                          <li>Tap SEND</li>
                          <li>Tap XRP Wallet Address</li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                  <div className="stepbox_panel_right">
                    <img src={require("../assets/images/step_img_02.png")} alt="Filipcoin" className="img-fluid" />
                  </div>
                </div>

                <div className="stepbox_panel" data-aos="fade-up" data-aos-duration="2000">
                  <div className="stepbox_panel_left">
                    <h3>STEP 3: DEPOSIT XRP VIA BINANCE</h3>
                    <ol>
                      <li>On your Binance Account, set up your XRP Wallet
                        <ol>
                          <li>Tap WALLET</li>
                          <li>Tap XRP</li>
                          <li>Tap DEPOSIT</li>
                          <li>Choose network</li>
                          <li>Tap Ripple</li>
                        </ol>
                      </li>
                      <li>Send XRP from CoinsPH to Binance
                        <ol>
                          <li>From your Binance Account, Copy XRP WALLET ADDRESS</li>
                          <li>On your CoinsPH Account, paste XRP WALLET ADDRESS</li>
                          <li>From your Binance Account, copy XRP TAG</li>
                          <li>On your CoinsPH Account, paste XRP tag on DESTINATION TAG</li>
                          <li> Tap SEND XRP</li>
                        </ol>
                      </li>
                      <li>Confirm recipient identity
                        <ol>
                          <li>Fill up RECIPIENT (First Name, Last Name)</li>
                          <li>Set Destination Wallet/Exchange to BINANCE</li>
                          <li>Tap NEXT to proceed</li>
                        </ol>
                      </li>
                      <li>Set XRP amount to transfer
                        <ol>
                          <li>Set AMOUNT (less XRP gas fee)</li>
                          <li>Tap NEXT to proceed</li>                          
                        </ol>
                      </li>
                      <li>Confirm transaction to proceed
                        <ol>
                          <li>Verify payment summary</li>
                          <li>Tap SLIDE TO PAY</li>
                          <li>Submit OTP sent through your phone number/email address</li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                  <div className="stepbox_panel_right">
                    <img src={require("../assets/images/step_img_03.png")} alt="Filipcoin" className="img-fluid" />
                  </div>
                </div>

                <div className="stepbox_panel" data-aos="fade-up" data-aos-duration="2000">
                  <div className="stepbox_panel_left">
                    <h3>STEP 4: CONVERT XRP TO BNB</h3>
                    <ol>
                      <li>On your Binance Account, convert XRP to BNB
                        <ol>
                          <li>Tap TRADES</li>
                          <li>Tap CONVERT</li>
                          <li>Convert XRP to BNB</li>
                        </ol>
                      </li>                      
                    </ol>
                    <p className="steps_note_txt mt-5"><span>Note:</span> Minimum conversion of 50 XRP is required.</p>
                  </div>
                  <div className="stepbox_panel_right">
                    <img src={require("../assets/images/step_img_04.png")} alt="Filipcoin" className="img-fluid" />
                  </div>
                </div>

                <div className="stepbox_panel" data-aos="fade-up" data-aos-duration="2000">
                  <div className="stepbox_panel_left">
                    <h3>STEP 5: WITHDRAW BNB FROM BINANCE TO TRUST WALLET</h3>
                    <ol>
                      <li>On your TrustWallet Account, copy your SMART CHAIN BNB WALLET ADDRESS</li>                      
                      <li>Withdraw BNB from your Binance Wallet
                        <ol>
                          <li>On your Binance Account, tap WALLET</li>
                          <li>Tap WITHDRAW</li>
                          <li>Paste your Trust Wallet Smart Chain BNB Wallet Address</li>
                          <li>Set Network to BINANCE SMART CHAIN (BEP20)</li>
                        </ol>
                      </li>
                      <p className="steps_note_txt mt-2"><span>Note:</span> Minimum conversion of 50 XRP is required.</p>
                      <li>Confirm BNB withdrawal
                        <ol>
                          <li>Tap WITHDRAWAL</li>
                          <li>Tap CONFIRM</li>
                          <li>Submit OTP Code sent to your phone number/email address</li>
                          <li>Tap SUBMIT</li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                  <div className="stepbox_panel_right">
                    <img src={require("../assets/images/step_img_05.png")} alt="Filipcoin" className="img-fluid" />
                  </div>
                </div>

                <div className="stepbox_panel" data-aos="fade-up" data-aos-duration="2000">
                  <div className="stepbox_panel_left">
                    <h3>STEP 6: SWAP BNB TO FCP</h3>
                    <ol>
                      <li>On your TrustWallet Account, copy your SMART CHAIN BNB WALLET ADDRESS</li>                      
                      <li>Go to PANCAKESWAP</li>
                      <li>Swap BNB to FCP</li>
                      <li>Confirm and approve transaction to proceed</li>
                      <li>Check FCP Balance on Trust Wallet</li>                      
                    </ol>
                  </div>
                  <div className="stepbox_panel_right">
                    <img src={require("../assets/images/step_img_05.png")} alt="Filipcoin" className="img-fluid" />
                  </div>
                </div>

              </GridItem>
            </GridContainer>           
          </div>
        </section>

        <section className="tokpie_section dark_bg dark_bg_gradient section" id="tokpie_section">
          <div className="container"> 
            <GridContainer className="text-center">
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">EXCHANGE</h2>
                <img src={require("../assets/images/tokpie_logo.png")} alt="Tokpie" className="img-fluid" />
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-4">
              <GridItem md={12} data-aos="fade-up" data-aos-duration="2000">
                <h3>Buy FCP tokens with BNB on Tokpie</h3>
                <ol>
                  <li>Fund your account with BNB. If you don t have crypto, you can purchase BNB with a bank card in a few minutes.</li>
                  <li>Open the FCP/BNB order book and place your trade order to buy. Use this instruction if you don t know how to place a trade order.</li>
                  <li>Withdraw FCP tokens to your personal BEP20 or ERC20 wallet. Alternatively, you can hold them on the account.</li>
                </ol>
                <h3 className="mt-4">Sell FCP tokens with BNB on Tokpie</h3>
                <ol>
                  <li>Deposit FCP token into your account.</li>
                  <li>Open the FCP/BNB order book, and place your trade order to sell. Read the instruction if you don t know how to place a trade order.</li>
                  <li>Withdraw BNB to your personal BEP20 wallet or hold them on the account.</li>
                </ol>
                <p className="mt-4">Visit <a href="https://tokpie.io/" target="_blank">Tokpie</a> for more information.</p>
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <section className="price_tracking_section section text-center" id="price_tracking">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">Token</h2>
                <h1 className="inner_title">Price Tracking</h1>
                <h3 className="padding_txt"><b>Track FCP’s trading information today!</b></h3>                
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-5 mb-4">
              <GridItem md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <div className="price_tracking_img_div"><img src={require("../assets/images/coinmarketCap.svg")} alt="Filipcoin" className="img-fluid" /></div>
                <p className="text-center">CoinMarketCap. <a href="https://coinmarketcap.com/currencies/filipcoin/" target="_blank">Click here</a></p>
              </GridItem>
              <GridItem md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <div className="price_tracking_img_div"><img src={require("../assets/images/FTX_logo.svg")} alt="Filipcoin" className="img-fluid" /></div>
                <p className="text-center">FTX. <a href="https://blockfolio.com/" target="_blank">Click here</a></p>
              </GridItem>
              <GridItem md={4} data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                <div className="price_tracking_img_div"><img src={require("../assets/images/coingecko.svg")} alt="Filipcoin" className="img-fluid" /></div>
                <p className="text-center">CoinGecko. <a href="https://www.coingecko.com/en/coins/filipcoin" target="_blank">Click here</a></p>
              </GridItem>
            </GridContainer>
          </div>
        </section>


        <Footer/>
      </div>
    </div>
  );
}
