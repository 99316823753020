import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';

// pages for this product
import Home from "views/home.js";
import Faq from "views/faq.js";
import TermsConditions from "views/terms-conditions.js";
import PrivacyPolicy from "views/privacy-policy.js";
import About from "views/about.js";
import Objective from "views/objective.js";
import Roadmap from "views/roadmap.js";
import Tokenomics from "views/tokenomics.js";
import Products from "views/products.js";
import Token from "views/token.js";

import Login from "views/login.js";
import Register from "views/register.js";
import Forgot from "views/forgot.js";
import Resetpassword from "views/resetpassword.js";
import Dashboard from "views/dashboard.js";
import History from "views/history.js";
import LoginHistory from "views/login-history.js";
import Kyc from "views/kyc.js";
import Profile from "views/profile.js";
import TFA from "views/2FA.js";
import Support from "views/support.js";
import Contact from "views/contact.js";
import Transfertoken from "views/transfertoken.js";
import Buytoken from "views/buy-token.js";
import Withdraw from "views/withdrawtoken.js";
import Team from "views/team.js";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { ToastContainer} from "react-toastify";

ReactDOM.render(  
  <BrowserRouter basename="/"> 
  <ToastContainer/>
    <Switch>
      <Route path="/buytoken" component={Buytoken} />
      <Route path="/transfertoken" component={Transfertoken} />
      <Route path="/linkedin" component={LinkedInPopUp} />
      <Route path="/withdrawtoken" component={Withdraw} />
      <Route path="/contact" component={Contact} /> 
      <Route path="/support" component={Support} /> 
      <Route path="/2FA" component={TFA} /> 
      {/* <Route path="/profile" component={Profile} /> */}
      <PrivateRoute exact path="/profile" component={Profile} />
      <Route path="/kyc" component={Kyc} />
      <Route path="/login-history" component={LoginHistory} />
      <Route path="/history" component={History} />
      {/* <Route path="/dashboard" component={Dashboard} /> */}
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <Route path="/forgot" component={Forgot} />
      <Route path="/register" component={Register} />
      <Route path="/login" component={Login} />
      <Route exact path="/activate/:userId" component={Login} />
      <Route exact path="/resetpassword/:userId" component={Resetpassword} />
      <Route exact path="/buy-token/:keys" component={Buytoken} />
      <Route path="/team" component={Team} /> 

      <Route path="/token" component={Token} />
      <Route path="/products" component={Products} />
      <Route path="/tokenomics" component={Tokenomics} />
      <Route path="/roadmap" component={Roadmap} />
      <Route path="/objective" component={Objective} />
      <Route path="/about" component={About} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-conditions" component={TermsConditions} />
      <Route path="/faq" component={Faq} />
      <Route exact path="/withdrawconfirm/:withdrawid" component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
