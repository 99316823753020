import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Tokenomics(props) {

  const { ...rest } = props;
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  var   {  withdrawid } = useParams();
  
  useEffect(()  => {
     getuserid();
  },[])

async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

async function  isconfirm(){
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
      }
    }else{
      localStorage.clear();
    }
  }
  return (
    <div className="inner_pages">
      <ScrollToTopOnMount />
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}
      

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">About Us</Link></li>
              </ul>
              <h1 className="inner_title">About Us</h1>
            </GridItem>
          </GridContainer>
        </div>
        <nav className="fixed_sidenav">
          <ul>
            <li><NavLink to="/about">Introduction</NavLink></li>
            <li><NavLink to="/objective">Objective</NavLink></li>
            <li><NavLink to="/roadmap">Roadmap</NavLink></li>
            <li><NavLink to="/tokenomics">Tokenomics</NavLink></li>
          </ul>
        </nav>
        <div className="new_inner_content">
          <section className="intro_section section">
            <div className="container">
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12} className="mt-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel">
                    <h2>Tokenomics</h2>
                    <p>The FCP Token will be used to power the FILIPCOIN service economy app projects and will work as a means to reward or get rewarded for participation in the peer-to-peer payments.</p>
                  </div>
                </GridItem>
                <GridItem md={8} lg={8} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                  <img src={require("../assets/images/token_allocation.png")} alt="Token" className="img-fluid mt-5"/>
                </GridItem>
              </GridContainer>
            </div>
          </section>

          <section className="section">
            <div className="container">
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12} className="mt-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel">
                    <h2>Holder Privilege</h2>
                    <p>Exclusive privileges are offered to FILIPCOIN holders to provide a premium for participating in the ICO. Owning Filipcoins is a prerequisite to accessing the services and projects that holders may use and utilize, allowing them to maximize their purposes and accompanying benefits.</p>
                    <p>However, the level of accessibility and privileges vary depending on the number/amount of coins owned/purchased/HOLD. For the most part, the differences lie in the depth of accessibility as well as the equity and rewards percentages offered for every Filipcoin Project that will be implemented following its Roadmap.</p> 
                    <p>The equity percentage will be allocated from the income of the FILIPAY project and the FILIPCOIN exchange. For investors, there will be AN assurance of the income per year based on the number/amount of coins owned/purchased/HOLD per year and directly credited yearly to the holder’s account in the FILIPCOIN Application. </p>
                    <p>The USD value depends on the value per FCP in the cryptocurrency exchanges.</p>
                    <p>Additionally, Filipcoin holders are guaranteed continuous upgrades and improvements of the FCP System as we continue to achieve every milestone in our Roadmap until the development of our own FILIPCOIN Blockchain.</p>
                  </div>
                </GridItem>                
              </GridContainer>
            </div>
          </section>

          <section className="section" id="bep20">
            <div className="container">
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12} className="mb-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel"><h2>BEP-20 Contract Address</h2></div>
                </GridItem>
              </GridContainer>
              <GridContainer className="align-items-center">
                <GridItem md={6} className="text-center" data-aos="fade-up" data-aos-duration="2000">
                  <a href="https://pancakeswap.finance/swap?outputCurrency=0x155e8a74dac3d8560ddabbc26aa064b764535193" target="_blank"><img src={require("../assets/images/bep_address.png")} alt="Token" className="img-fluid"/></a>
                </GridItem>
                <GridItem md={6}>
                  <div className="address_details" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <label>ACCEPTABLE CURRENCIES</label>
                      <label>BNB, ETH</label>
                    </div>
                    <div>
                      <label>TOTAL SUPPLY</label>
                      <label>168,000,000.00</label>
                    </div>
                    <div>
                      <label>NUMBER OF TOKENS PER SALE</label>
                      <label>88, 068, 000</label>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>              
            </div>
          </section>

          <section className="section" id="erc20">
            <div className="container">
              <GridContainer className="align-items-center mt-5">
                <GridItem sm={12} md={12} lg={12} className="mb-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel"><h2>ERC-20 Contract Address</h2></div>
                </GridItem>
              </GridContainer>
              <GridContainer className="align-items-center">
                <GridItem md={6} className="text-center" data-aos="fade-up" data-aos-duration="2000">
                  <a href="https://app.uniswap.org/#/swap?outputCurrency=0xb6Dd77FD132dcAa10F1858734e838a0FA7431580" target="_blank"><img src={require("../assets/images/erc_address.png")} alt="Token" className="img-fluid"/></a>
                </GridItem>
                <GridItem md={6}>
                  <div className="address_details" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                      <label>ACCEPTABLE CURRENCIES</label>
                      <label>BNB, ETH</label>
                    </div>
                    <div>
                      <label>TOTAL SUPPLY</label>
                      <label>168,000,000.00</label>
                    </div>
                    <div>
                      <label>NUMBER OF TOKENS PER SALE</label>
                      <label>88, 068, 000</label>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </section>

          <div className="container" data-aos="fade-up" data-aos-duration="2000">
            <div className="bottom_page_btn_grp">
              <Link to="/roadmap" className="nxt_page_btn"><img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon prev_arrow_icon"/>Roadmap</Link>
              <a href="about#leadership_team" className="nxt_page_btn">Leadership Team <img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon"/></a>
            </div>
          </div>
        </div>          
        <Footer/>
      </div>
    </div>
  );
}
