import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import { TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link,useHistory } from "react-router-dom";
import config from "actions/config"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import axios from "axios";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  FORGOT,
  USER_LOADING,
  USER_ADD,
  SUPPORT_REPLY,
  SUPPORT
} from "../actions/types";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
const url=config.baseUrl;

export default function Forgot(props) {

  const [email, setemail] = React.useState('');
  const history = useHistory();
  const { ...rest } = props;
  
  async function emailchange(email){
    setemail(email);
  }
  
  async function formValidate(){
    var isValid = true;
    var error ="";
    if(email=="" || typeof email=="undefined"){
      isValid = false;
      error ="Must Enter Email";
    }

    if(!isValid){
      toastAlert('error',error,'validate')
    }else{
      return isValid;
    }

  }

  async function forgot(){

    if(formValidate()){
      var forgotUser={
        email: email,
      }
      var res = await postMethod(url+"api/forgotpassword", forgotUser);
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
        history.push('/home')
        }
    }
  }
  

  const classes = useStyles();
  return (
    <div>
  
    <div className="auth_wrapper">
      <div className="container">
        <GridContainer className="align-items-center">
          <GridItem sm={12} md={6} lg={6}>
            <div className="login_bg">
              <img src={require("../assets/images/login_img.png")} alt="Filipcoin" className="img-fluid" />
              <h4>Decentralized Network for Transportation and other Service Economy Applications</h4>
            </div>
          </GridItem>
          <GridItem sm={12} md={6} lg={6}>
            <div className="auth_form_div">
              <div className="text-right">
                <Link to="/">
                  <img src={require("../assets/images/logo.png")} alt="Filipcoin" className="img-fluid login_logo" />
                </Link>
              </div>
              <h2 className="auth_title">Forgot Password</h2>
              <p>To keep connected with us please enter your login email address</p>
              <form className="auth_form">
                <div className="form-group">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input id="filled-name" type="email" onChange={(e) => {emailchange(e.target.value)}} variant="filled" className="primary_inp"/>
                  </div>                  
                </div>                
                <div className="d-flex align-items-center justify-content-between">
                  <Button className="join_btn" onClick={()=>{forgot()}}><span className="join_btn_span">Submit</span></Button>
                  <Link to="/login" className="font_13">Login</Link>
                </div>
                <div className="social_login mt-4">
                  <p>or you can join with us</p>
                  <ul>
                  <li><a href="https://t.me/filipcoinfilipchannel" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                  <li><a href="https://t.me/filipcoinfilip" target="_blank"><i className="far fa-comments"></i></a></li>
                  <li><a href="https://twitter.com/filipcoinfcp" target="_blank"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="https://www.instagram.com/filipcoinfcp/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="https://filipcoin.medium.com/" target="_blank"><i className="fab fa-medium-m"></i></a></li>
                  <li><a href="https://www.youtube.com/channel/UC4_t-U8vWCZgnhr7e4FYL3A" target="_blank"><i class="fab fa-youtube"></i></a></li>
                  <li><a href="https://www.facebook.com/filipcoinfcp" target="_blank"><i class="fab fa-facebook"></i></a></li>
                </ul>
                </div>
              </form>
            </div>
          </GridItem>
        </GridContainer>
      </div>      
    </div>
    </div>
  );
}
