import React,  { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import CountdownTimer from "react-component-countdown-timer";
import Countdown, { zeroPad } from "react-countdown";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CryptoJS from "crypto-js";
import $ from "jquery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// React Scroll
import { Element } from 'react-scroll'

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styled from "../../node_modules/styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link,useHistory,useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import { Button, Accordion, AccordionDetails, AccordionSummary, Hidden } from "@material-ui/core";

const team_options = {
  responsive:{
    0:{
        items:1, 
        nav: false
    },
    600:{
      items:2,
      margin:10,
      nav: true
    },
    768:{
        items:3,
        margin:30,
        nav: true
    },
    992:{
        items:4,
        margin:50
    }
  }
};

const roadmap_options = {
  responsive:{
    0:{
        items:1, 
        nav: false
    },
    600:{
      items:2,
      margin:10,
      nav: true
    },
    768:{
        items:2,
        margin:10,
        nav: true
    },
    992:{
        items:2,
        margin:20
    }
  }
};

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #b78c50;
    font-size: 32px;
  }
  & > .minus {
    display: none;
    color: #b78c50;
    font-size: 32px;
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;


// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [expanded, setExpanded] = React.useState('panel1');
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  var   {  withdrawid } = useParams();
  const [token_price_btc, settoken_price_btc] = React.useState(0);
  const [min_btc, setmin_btc] = React.useState(0);
  const [min_eth, setmin_eth] = React.useState(0);
  const [ethfee, setethfee] = React.useState(0);
  const [btcfee, setbtcfee] = React.useState(0);
  const [transactionfee, settransactionfee] = React.useState(0);
  const [token_price_eth, settoken_price_eth] = React.useState(0); 
  const [ethdoller, setethdoller] = React.useState(0);
  const [btcdoller, setbtcdoller] = React.useState(0);
  const [bnbdoller, setbnbdoller] = React.useState(0);
  

  useEffect(()  => {
    getuserid();
    isconfirm();
    getvalue();
    getethdoller();
    getbtcdoller();
    getbnbdoller();
    loadScript();
  },[])

   const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">Days</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">Hours</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">Mins</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span>
      </div>
    );
  };

  async function getvalue(){
    var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data){
      
      settoken_price_btc(res.data.data[0].token_price_btc);
      settoken_price_eth(res.data.data[0].token_price_eth);
      setmin_eth(res.data.data[0].min_investment_eth);
      setmin_btc(res.data.data[0].min_investment_btc)
      setethfee(res.data.data[0].transaction_fee);
      setbtcfee(res.data.data[0].transaction_fee1);
    }
  }

  async function  isconfirm(){
     localStorage.clear();
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')

      }

    }else{
      localStorage.clear();
    }
  }

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
        
        set_id(verified.id);

    }
    }else{
      
    }
   });
  }catch(err){
  }
  }

  async function getethdoller(){
    var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data && res.data.data[0] ){
      var interval = 1/parseFloat(res.data.data[0].token_price_eth)
     var ethval = (parseFloat(interval)*parseFloat(res.data.data[0].token_price)).toFixed(8)
     setethdoller(ethval);
    }
  }

  async function getbnbdoller(){
    var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data && res.data.data[0]){
      var interval = 1/parseFloat(res.data.data[0].token_price_BNB)
      var bnbval = (parseFloat(interval)*parseFloat(res.data.data[0].token_price)).toFixed(8)
      setbnbdoller(bnbval);
    }
  }

  async function getbtcdoller(){
    var res = await getMethod(url+"api/getbtcdoller");
    if(res.data && res.data.data){
      console.log(res.data.data)
     setbtcdoller(res.data.data.btcbalance);
    }
  }

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const routeChange = () =>{ 
    if(_id==''){
      let path = 'login'; 
      history.push(path);
    }else{
      let path = 'buytoken'; 
      history.push(path);
    }
    
  }
  
  const { ...rest } = props;

  function loadScript() {
    // Carousel Indicators Two Active 
    $('ol.carousel_indicators_img li, ol.carousel_indicators_dots li').on("click",function(){ 
      $('ol.carousel_indicators_img li.active').removeClass("active");
      $("ol.carousel_indicators_dots li.active").removeClass("active");
      $(this).addClass("active");   
      var indicators = $(this).data("slide-to");
      $(".carousel_indicators_dots").find("[data-slide-to='" + indicators + "']")
        .addClass("active");
    });
  }

  return (
    <div className="page_header">
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      
      <div className="page-header header-filter">
        <div className={classes.container}>
          <GridContainer className="mt-5 align-items-center">
            <GridItem md={12} lg={7} data-aos="fade-up" data-aos-duration="2000">
              <h1>ABOUT FILIPCOIN</h1>
              <h2>The team behind the FILIPCOIN is creating a blockchain ecosystem for decentralized applications (DApps) with decentralized exchange (DEX) in the payment service economy sector.</h2>
              <p>Unlike other projects in the cryptocurrency world, FILIPCOIN holders already gain automatic access to FILPAY, a developed and functional application for the Transportation Industry, with additional uses and benefits in the pipeline.</p>
              <p>FILIPCOIN will initially be a utility token operating on Binance Smart Chain (BNB) and Ethereum (ETH) blockchain.</p>
              <p>FILIPCOIN Token will be adopted as a native coin to FCP's very own Blockchain.</p>
              {/* <div className="banner_btn">                              
                <a href="https://pancakeswap.finance/swap?outputCurrency=0x155e8a74dac3d8560ddabbc26aa064b764535193" target="_blank"><img src={require("../assets/images/pancakeswap.png")} alt="Pancakeswap" className="img-fluid banner_part_logo"/></a>
                <a href="https://app.uniswap.org/#/swap?outputCurrency=0xb6Dd77FD132dcAa10F1858734e838a0FA7431580" target="_blank"><img src={require("../assets/images/Uniswap.png")} alt="Uniswap" className="img-fluid banner_part_logo"/></a>
              </div> */}
            </GridItem>
            <GridItem sm={8} md={7} lg={5} data-aos="fade-up" data-aos-duration="2000" className="m-auto">
              <img src={require("../assets/images/banner_image.png")} alt="Banner" className="img-fluid"/>
            </GridItem>
          </GridContainer>
          <GridContainer className="mt-5">
            <GridItem md={12} data-aos="fade-up" data-aos-duration="2000">
              <div className="text-right">
                <p>Powered by</p>
                <img src={require("../assets/images/binance_icon.svg")} alt="Filipcoin" className="img-fluid banner_part_logo"/>
                <img src={require("../assets/images/ethereun_icon.svg")} alt="Filipcoin" className="img-fluid banner_part_logo"/>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className="main">

        {/* About Section */}
        {/* <div className="about_section grey_bg">
          <div className="container"> 
            <div className="section">
              <GridContainer className="align-items-center">
                <GridItem sm={6} md={6} lg={6}  data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">About Filipcoin</h2>
                  <h1 className="inner_title">We've build a platform to buy and sell shares.</h1>
                  <p className="dark_txt">FILIPCOIN is creating a blockchain ecosystem for mobile application services in the service economy sector.</p>
                  <p>Unlike other projects in the cryptocurrency world, FILIPCOIN holders already gains automatic access to a developed and fully-functional application for small businesses and entrepreneurs, with additional uses and benefits in the pipeline.</p>
                  <p>However, as we will detail in this Whitepaper, we intend to introduce additional functionalities and progressively integrate the application into the Ethereum blockchain.</p>
                  <h3>Powered by</h3>
                  <img src={require("../assets/images/ethereum.png")} alt="Ethereum" className="img-fluid powered_img"/>
                  <img src={require("../assets/images/binance-smartchain.png")} alt="Binance Smart Chain" className="img-fluid powered_img"/>
                </GridItem>
                <GridItem sm={6} md={6} lg={6} data-aos="fade-up" data-aos-duration="2000">
                <div className="how_works_video">
                   <a href="https://www.youtube.com/watch?v=nMH8Rm7Uojk" target="_blank"><img src={require("../assets/images/how_it_work.png")} alt="How It Works" className="img-fluid" /></a>
                  </div>                 
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div> */}

        {/* Why Flipcoin */}
        <div className="about_section">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={10} lg={10} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">Why Filipcoin</h2>
                  <h1 className="inner_title">Competitive Advantages</h1>
                  <p className="padding_txt">FCP will demonstrate innovative approach on the technical development and management that is both profitable and sustainable.</p>
                  <div id="slider" className="carousel slide why_filipcoin_tab" data-ride="carousel" data-interval="2000">
                    <ol className="carousel-indicators carousel_indicators_img">
                      <li data-target="#slider" data-slide-to="0" className="active"><span><img src={require("../assets/images/icon_01.svg")} alt="Icon" className="tab_icon"/></span></li>
                      <li data-target="#slider" data-slide-to="1"><span><img src={require("../assets/images/icon_02.svg")} alt="Icon"  className="tab_icon"/></span></li>
                      <li data-target="#slider" data-slide-to="2"><span><img src={require("../assets/images/icon_03.svg")} alt="Icon" className="tab_icon"/></span></li>
                      <li data-target="#slider" data-slide-to="3"><span><img src={require("../assets/images/icon_04.svg")} alt="Icon"  className="tab_icon"/></span></li>
                      <li data-target="#slider" data-slide-to="4"><span><img src={require("../assets/images/icon_05.svg")} alt="Icon" className="tab_icon"/></span></li>
                      <li data-target="#slider" data-slide-to="5"><span><img src={require("../assets/images/icon_06.svg")} alt="Icon" className="tab_icon"/></span></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <h2 className="tab_heading_title">Flexible</h2>
                        <p>Ease of Use.  This particular use will allow partners from Transportation and other mobile payment application service sector to integrate a payment gateway to the FILIPCOIN blockchain ecosystem, these plug-and-play tools make it easy for anyone with a smartphone to access financial services at a lower costs and will provide ease to its users, hence the beauty of a decentralized system leveraged by having its own blockchain technology.</p>
                      </div>
                      <div className="carousel-item">
                        <h2 className="tab_heading_title">Collaborative</h2>
                        <p>Through decentralization, increased accessibility of available technology developed, though FILIPCOIN will be able to cater to significant groups of users and target markets covering Developers, Corporations, Small-Medium Enterprise and End User.</p>
                      </div>
                      <div className="carousel-item">
                        <h2 className="tab_heading_title">Supportive</h2>
                        <p>The whole team  and the FILIPCOIN holders from 2017 has built the greater foundation from the public for continuous support and development of the FCP ecosystem. It will be the responsibility of the team for the cryptocurrency assets, development of coin, and the continuous updates of future products and services under the FILIPCOIN blockchain. Funds received by the FILIP organization will be utilized to further improve the FILIPCOIN in order to increase its value with proper integration of service economic application projects.</p>
                      </div>
                      <div className="carousel-item">
                        <h2 className="tab_heading_title">Exclusive</h2>
                        <p>Exclusive Technologies that are already operational in the Transportation Industry in The Philippines. The team behind the FILIPCOIN is creating a blockchain ecosystem for decentralized applications (DApps) with decentralized exchange (DEX) in the payment service economy sector. Unlike other projects in the cryptocurrency world, FILIPCOIN holders already gains automatic access to a developed and fully-functional application for the Transportation Industry, with additional uses and benefits in the pipeline.</p>
                      </div>
                      <div className="carousel-item">
                        <h2 className="tab_heading_title">Prospective</h2>
                        <p>The first cryptocurrency, Bitcoin, is still the most well-known application of blockchain. However, this technology has since rapidly evolved and expanded in many other areas. The initial hope with Bitcoin was to make both money and payments decentralized and universally accessible. Although Bitcoin failed to live up to this promise, decentralized finance based on blockchain (“DeFi“), also called open finance, is a fledgling technology with potential. DeFi operates via decentralized, permissionless (without any central authority) applications, called DApps, built on a blockchain network, most commonly Ethereum. Visionaries see this as an open-source alternative to every financial service we use today. Picture savings, loans, and trades, to insurance, and even more, as all globally accessible.</p>
                      </div>
                      <div className="carousel-item">
                        <h2 className="tab_heading_title">Safe & Secure</h2>
                        <p>As part of the blockchain technology, FILIPCOIN is committed to deliver secure and compatible platform to an open-source community. Our professional team of developers will utilize the integration of on-chain and off-chain data through technical and conceptual innovation.</p>
                      </div>
                    </div>
                    <ol className="carousel-indicators carousel_indicators_dots">
                      <li data-target="#slider" data-slide-to="0" className="active"></li>
                      <li data-target="#slider" data-slide-to="1"></li>
                      <li data-target="#slider" data-slide-to="2"></li>
                      <li data-target="#slider" data-slide-to="3"></li>
                      <li data-target="#slider" data-slide-to="4"></li>
                      <li data-target="#slider" data-slide-to="5"></li>
                    </ol>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        {/* Token Section */}
        <div name="token_sale" className="token_sale_section" id="token">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">Filipcoin</h2>
                  <h1 className="inner_title">TOKEN SALE</h1>
                  <p className="token_sale_txt">Filipcoin will be released on the basis of Ethereum and Bitcoin platform. It's compatibility of the token with third-party services (wallets, exchanges etc.), and provides easy-to-use integration.</p>
                </GridItem>
              </GridContainer>
              <div className="token_graph_panel">
                <GridContainer className="mt-3 align-items-start">
                  <GridItem sm={12} md={5} lg={4} data-aos="fade-up" data-aos-duration="2000">
                    <div className="token_details">
                      <label>Acceptable Currencies</label>
                      <p>BNB, ETH</p>
                    </div>
                    <div className="token_details">
                      <label>Total Supply</label>
                      <p>168,000,000.00</p>
                    </div>                                    
                    <div className="token_details">
                      <label>Number of Tokens Per Sale</label>
                      <p>88, 068, 000</p>
                    </div>
                    <div className="text-left"><Button className="join_btn"  onClick={routeChange}>Buy Token</Button></div>
                  </GridItem>
                  <GridItem sm={12} md={7} lg={8} data-aos="fade-up" data-aos-duration="2000">
                    <div className="radio_burton justify-content-start mt-0 mb-3">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio1" name="customRadio" checked class="custom-control-input"/>
                        <label class="custom-control-label" for="customRadio1">BNB</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"/>
                        <label class="custom-control-label" for="customRadio2">ETH</label>
                      </div>
                    </div>
                    <div className="chart_wrapper">
                      <h2>REAL-TIME EXCHANGE RATE</h2>
                      <img src={require("../assets/images/chart_img.png")} alt="Icon"  className="img-fluid"/>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              <GridContainer>
                <GridItem md={6} data-aos="fade-up" data-aos-duration="2000">
                  <div className="token_sale_bottom">
                    <h4>Exchange</h4>
                    <div>
                      <img src={require("../assets/images/Pancakeswap_Cake.png")} alt="Icon"  className="img-fluid"/>
                      <img src={require("../assets/images/uniswap_uni.png")} alt="Icon"  className="img-fluid"/>
                      <img src={require("../assets/images/Tokpie.png")} alt="Icon"  className="img-fluid"/>
                    </div>
                  </div>
                </GridItem>
                <GridItem md={6} data-aos="fade-up" data-aos-duration="2000">
                  <div className="token_sale_bottom">
                    <h4>Price Listing</h4>
                    <div>
                      <img src={require("../assets/images/CoinmarketCap.png")} alt="Icon"  className="img-fluid"/>
                      <img src={require("../assets/images/FTX.png")} alt="Icon"  className="img-fluid"/>
                      <img src={require("../assets/images/CoinGecko.png")} alt="Icon"  className="img-fluid"/>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div> 
          </div>
        </div>

         {/* Product Section */}
         <div name="product" className="product_section dark_bg dark_bg_gradient" id="product">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">Products</h2>
                  <h1 className="inner_title">FILIPCOIN WALLET</h1>
                  <p className="mb-5 mt-3">FILIPCOIN (FCP) facilitates adoption to digital currency trend. Send and receive FCP anywhere in the world, exchange tokens under ETH and BNB, and PAY FARE IN MOST MODE OF TRANSPORTATION ANYWHERE.</p>
                  <div className="text-center"><img src={require("../assets/images/mobile_img_01.png")} alt="Icon"  className="img-fluid"/></div>
                </GridItem>
              </GridContainer>              
            </div> 
          </div>
        </div>

        {/* Mobile App Section */}
        <div className="mobileapp_section">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">FILIPAY</h2>
                  <h1 className="inner_title">Commuting Made Easy</h1>
                  <h4 className="padding_txt"><b>Download the FILIPAY App for fare payment via QR Code.</b></h4>
                </GridItem>
              </GridContainer>
              <GridContainer className="align-items-center">
                <GridItem sm={8} md={8} lg={7} data-aos="fade-up" data-aos-duration="2000" className="m-auto">
                  <img src={require("../assets/images/mobile_img_02.png")} alt="Mobile App" className="img-fluid" />
                </GridItem>
                <GridItem sm={12} md={12} lg={5}>
                  <ul className="mobileapp_list">
                    <li data-aos="fade-up" data-aos-duration="2000">Automatic Fare Collection</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">Pay Ahead that Maximizes Payment Mobility</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">Accessible, Reliable and Convenient</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">Digital Currency as Reward Points</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">Highly Secured E-Wallet</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">Send and Receive Peso Digital Currency</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200">Collaborative Rebates</li>
                    <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1400">Loading Gateways</li>
                  </ul>                  
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1700">
                  <p className="padding_txt">FILIPAY facilitates automatic fare collection to all Public Utility Vehicles, adoption to digital currency trend, send and receive money/credits anywhere in the world, get rebates from your favorite shopping sites or restaurants, pay ahead with your bills, gasoline, foods, travels and many more.</p>
                  <p>Visit <a href="https://filipay.com.ph/" target="_blank">FILIPAY Website</a> for more information.</p>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
       
        {/* Team Section */}
        {/* <div className="team_section grey_bg" name="team_sec">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">Our Team</h2>
                  <h1 className="inner_title">The Leadership Team</h1>
                  <p className="padding_txt">We are proud of our great team. They are the most motivated and enthusiastic people we have, and is always ready and willing to help out where needed.</p>
                </GridItem>
              </GridContainer>
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12}>
                <OwlCarousel className="owl-theme team_carousel" loop nav {...team_options} navText={["<i class='fas fa-arrow-left'></i>","<i class='fas fa-arrow-right'></i>"]}>
                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                      <div className="team_panel">
                        <img src={require("../assets/images/team_01.png")} alt="Team" className="img-fluid"/>
                        <div className="member_details">
                          <h2>Janice Arino</h2>
                          <p>CEO/President</p>
                          <p className="mt-3">Janice, who hailed from Bicol Province, is a teacher by profession. She then moved to Los Baños... <a href="/team" className="text-white">View More</a></p>
                        </div>
                        <div className="member_social">
                          <ul>                            
                            <li><a href="https://www.linkedin.com/in/janice-ari%C3%B1o/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                      <div className="team_panel">
                        <img src={require("../assets/images/team_05.png")} alt="Team" className="img-fluid"/>
                        <div className="member_details">
                          <h2>Jonas Arino</h2>
                          <p>Chief Operation Officer</p>
                          <p className="mt-3">With 20 years’ experience in service at Social Security System in the Philippine... <a href="/team" className="text-white">View More</a></p>
                        </div>
                        <div className="member_social">
                          <ul>                            
                            <li><a href="https://www.linkedin.com/in/jonas-arino-8442481aa/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                      <div className="team_panel">
                        <img src={require("../assets/images/2img.png")} alt="Team" className="img-fluid"/>
                        <div className="member_details">
                          <h2>Norlito Jacob</h2>
                          <p>InfoSec & IT Engineer</p>
                          <p className="mt-3">A consistent team leader who always tends to "aim high" in terms of goals and vision ... <a href="/team" className="text-white">View More</a></p>
                        </div>
                        <div className="member_social">
                          <ul>                           
                            <li><a href="https://www.linkedin.com/in/jacob-norlito-70669b165/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>                  

                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                      <div className="team_panel">
                        <img src={require("../assets/images/team_03.png")} alt="Team" className="img-fluid"/>
                        <div className="member_details">
                          <h2>Punith Kumar Baskaran</h2>
                          <p>Lead Blockchain Consultant</p>
                          <p className="mt-3">Extensive experience in various domains of Blockchain development and an... <a href="/team" className="text-white">View More</a></p>
                        </div>
                        <div className="member_social">
                          <ul>                            
                            <li><a href="https://www.linkedin.com/in/punith-kumar-baskaran-66019037/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                      <div className="team_panel">
                        <img src={require("../assets/images/team_04.png")} alt="Team" className="img-fluid"/>
                        <div className="member_details">
                          <h2>Atty. Salma F. Angkaya-Kuhutan</h2>
                          <p>Legal Adviser</p>
                          <p className="mt-3">Currently runs a general law practice while acting as legal counsel, corporate... <a href="/team" className="text-white">View More</a></p>
                        </div>
                        <div className="member_social">
                          <ul>                            
                            <li><a href="https://www.linkedin.com/in/sakuhutan/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                      <div className="team_panel">
                        <img src={require("../assets/images/team_06.png")} alt="Team" className="img-fluid"/>
                        <div className="member_details">
                          <h2>Medel Afalla</h2>
                          <p>Philippine Public Transportation Consultant</p>
                          <p className="mt-3">With 37 years of expertise and experience in good governance in Public... <a href="/team" className="text-white">View More</a></p>
                        </div>
                        <div className="member_social">
                          <ul>                           
                            <li><a href="https://www.linkedin.com/in/medel-afalla-870b31a6/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>                   
                  </OwlCarousel>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div> */}

        {/* Community Section */}
        <div name="community" className="community_section dark_bg" id="community">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">COMMUNITY</h2>
                  <h1 className="inner_title">Learn. Follow. Connect</h1>
                  <div className="community_panel">
                    <div>
                      <a href="https://filipcoin.medium.com/" target="_blank">
                        <img src={require("../assets/images/Learn.png")} alt="Team" className="img-fluid"/>
                      </a>
                    </div>
                    <div>
                      <a href="https://twitter.com/filipcoinfcp" target="_blank">
                        <img src={require("../assets/images/Follow.png")} alt="Team" className="img-fluid"/>
                      </a>
                    </div>
                    <div>
                      <a href="https://t.me/filipcoinfilipchannel" target="_blank">
                        <img src={require("../assets/images/Connect.png")} alt="Team" className="img-fluid"/>
                      </a>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>              
            </div> 
          </div>
        </div>
        
        {/* Faq Section */}
        <div className="faq_section">
          <div className="container"> 
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2 className="inner_subhead">Explain more about our platform</h2>
                  <h1 className="inner_title">Frequently Asked Questions</h1>
                  <h4 className="padding_txt"><b>Below we’ve provided a bit of information of Our Token and the its Project. If you have any other questions, please get in touch with us.</b></h4>
                </GridItem>
              </GridContainer>
              <GridContainer className="align-items-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={12} lg={10} className="m-auto">
                  <h1 className="faq_inner_title">About Filipcoin</h1>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                      <h2 className="accordian_head">What is the FILIPCOIN Token?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The FILIPCOIN Token is a crypto-currency built using the Ethereum protocol (ERC20) and Decentralized Ledger Technology. Our goal is to develop and innovate our own blockchain technology which focuses on the decentralized finance, a system of service economy application that will provide practical solutions for our users, investors, and developer partners.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                      <h2 className="accordian_head">How do I find local FILIPCOIN Token enthusiasts and traders?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Please refer to our Twitter, Facebook, and Instagram pages. For more in-depth discussions, check out our Telegram. Links provided below:</p>
                      <p className="accordian_para">Twitter: <a href="https://twitter.com/filipcoinfcp" target="_blank">https://twitter.com/filipcoinfcp</a></p>
                      <p className="accordian_para">Facebook:</p>
                      <p className="accordian_para">FB Group Page: <a href="https://www.facebook.com/filipcoinfcp" target="_blank">https://www.facebook.com/filipcoinfcp</a></p>
                      <p className="accordian_para">FB Like Page: <a href="https://www.facebook.com/filipcoinfcp" target="_blank">https://www.facebook.com/filipcoinfcp</a></p>
                      <p className="accordian_para">Instagram: <a href="https://www.instagram.com/filipcoinfcp/" target="_blank">https://www.instagram.com/filipcoinfcp/</a></p>
                      <p className="accordian_para">Telegram:</p>
                      <p className="accordian_para">Telegram Channel: <a href="https://t.me/filipcoinfilipchannel" target="_blank">https://t.me/filipcoinfilipchannel</a></p>
                      <p className="accordian_para">FILIP Telegram Group Chat: <a href="https://t.me/filipcoinfilip" target="_blank">https://t.me/filipcoinfilip</a></p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                      <h2 className="accordian_head">When does FILIPCOIN Token launch?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Please check our website and social media for the latest news and updates on our roadmap and timeline. We are organizing public sale later this year.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel4bh-content" id="panel4bh-header">
                      <h2 className="accordian_head">What can I do with the FILIPCOIN Token?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The FILIPCOIN Token will immediately be available as a medium of exchange within the FILIPCOIN Network for various FILIPCOIN goods and services, including those of FILIPCOIN’s partners. The token will also be usable within FILIPCOIN Transportation Industry. Our long-term goal is to provide as many uses as possible, so stay tuned for updates!</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel5bh-content" id="panel5bh-header">
                      <h2 className="accordian_head">How is FILIPCOIN Token funded?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">FILIPCOIN Token is funded by the shareholders of Service Economy Applications Inc. (SEAI) and other Angel Investors. We also allocate a portion of the sales of the tokens to the operations of FILIPCOIN Token. Please see FILIPCOIN Whitepaper for detailed information on allocation of token sales.</p>
                    </AccordionDetails>
                  </Accordion>
                  <Button className="load_more_btn mt-5"><Link to="/faq">Load More <i class="fa fa-angle-double-right" aria-hidden="true"></i></Link></Button>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>       

        <Footer/>
      </div>

      <div class="modal fade" id="video_modal" tabindex="-1" role="dialog" aria-labelledby="video_modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                         </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
