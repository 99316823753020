export default function isLogin(props) {
  try{
    var getLogin = localStorage.getItem('jwtToken');
    console.log('getLogingetLogingetLogingetLogingetLogingetLogingetLogingetLogingetLogingetLogin',getLogin)
    if(getLogin && getLogin !=""){
      return "yes";
    }else{
      return "no";
    }
    
  }catch(err){alert(err)
    return "no";
  }
  
}
