/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { NavLink, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinksAfterLogin(props) {
  const classes = useStyles();
  return (
    <List className={classes.list + " main_navbar"}>
      <ListItem className={classes.listItem}>
        <NavLink to="/dashboard" color="transparent" className="nav-link">Dashboard</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/kyc" color="transparent" className="nav-link">KYC</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/2FA" color="transparent" className="nav-link">2FA</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/buytoken" color="transparent" className="nav-link">Buy Token</NavLink>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/history" color="transparent" className="nav-link">History</NavLink>
      </ListItem>

      <ListItem className={classes.listItem + " dropdown"}>
        <Link className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false">Account</Link>
        <div className="dropdown-menu inner_dropdown_menu">
          <NavLink className="dropdown-item" to="/profile">My Profile</NavLink>          
          <NavLink className="dropdown-item" to="/support">Support</NavLink>
          <NavLink className="dropdown-item" to="/transfertoken">Transfer Token</NavLink>
          <NavLink className="dropdown-item" to="/withdrawtoken">Withdraw Token</NavLink>
          <NavLink className="dropdown-item" to="/login-history">Login History</NavLink>
          <NavLink className="dropdown-item" to="/login">Logout</NavLink>
        </div>
      </ListItem>

    </List>
  );
}
