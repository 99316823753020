import React, { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import Moment from 'moment';
import Select from 'react-select';
import QRCode from 'qrcode.react';
import CryptoJS from "crypto-js";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import config from "../actions/config";
import { getMethod }  from "../actions/actions";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link,useHistory,useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, TableHead } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;

const dashboardRoutes = [];
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(date, amount, id, eth, usd, type) {
  return { date, amount, id, eth, usd, type };
}

const rows = [
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Buy</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Send</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Transfer</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Buy</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Send</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Transfer</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Transfer</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Buy</span>),
].sort((a, b) => (a.id > b.id ? -1 : 1));
  


export default function Dashboard(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const history = useHistory();
  const { ...rest } = props;
  const classes = useStyles();
  
  const [_id, set_id] = React.useState('');
  const [ethbal, setethbal] = React.useState(0);
  const [btcbal, setbtcbal] = React.useState(0);
  const [fcpbal, setfcpbal] = React.useState(0);
  const [currencydata, setcurrencydata] = React.useState('');
  const [walletaddress, setwalletaddress] = React.useState('');
  const [ethaddress, setethaddress] = React.useState('');
  const [btcaddress, setbtcaddress] = React.useState('');
  const [name, setname] = React.useState('');
  const [profileimage, setprofileimage] = React.useState('');
  const [email, setemail] = React.useState('');
  const [joiningyr, setjoiningyr] = React.useState('');
  const [kycstatus, setkycstatus] = React.useState('');
  const [googlestatus, setgooglestatus] = React.useState('');
  const [joiningmonth, setjoiningmonth] = React.useState('');
  const [historyList, sethistoryList] = React.useState([]);
  const [bscfcbbal,setbscfcbbal] = React.useState(0);
  useEffect(()  => {
    getuserid();   
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
       if(!err && verified && verified.id){
        getuserdetails(verified.id);
        gettransactionlist(verified.id);
        getaddress(verified.id);
       {/* getbtcaddress(verified.id); */}
        getwallet(verified.id);
        set_id(verified.id);
        getcurrency();
     }else{
        history.push('/login')
    }
  
   });
  }catch(err){

  }
  }

  async function getaddress(iid){
    var res = await getMethod(url+"api/getaddresss/"+iid);
    if(res.data && res.data.data){
      setethaddress(res.data.data.address);
      setwalletaddress(res.data.data.address)
    }else{
      setethaddress("0*0000000000000");
      setwalletaddress("0*0000000000000");
    }
  }

 {/* async function getbtcaddress(iid){
    var res = await getMethod(url+"api/getbtcaddress/"+iid);
    
    if(res.data && res.data.data){
      setbtcaddress(res.data.data.address);
    }else{
      setbtcaddress("0*0000000000000");
    }
  } */}

  var historylist=[];
  async function gettransactionlist(iid){
    var res = await getMethod(url+"api/getalltransaction/"+iid);
    if(res.data && res.data.data){
      if(res.data.data.length>0){
      var log=res.data.data[0].list;
      sethistoryList(log);
    }
    }    
  }

  async function getwallet(iid){
    var res = await getMethod(url+"api/getuserwallet/"+iid);
    if(res.data && res.data.eth){

      var babal=(res.data.eth).substring(0, 8);
      setethbal(babal);
    }
   {/* if(res.data && res.data.btc){
       var babal=(res.data.btc).substring(0, 8);
      setbtcbal(babal);
    } */}
     if(res.data && res.data.fcp){
      var babal=(res.data.fcp).substring(0, 8);
      setfcpbal(babal);
    }
     if(res.data && res.data.bscfcp){
      var bscbabal=(res.data.bscfcp).substring(0, 8);
      setbscfcbbal(bscbabal)
    }
  }

  async function getuserdetails(iid){
   
    var newuser={
      id:iid,
    }
    var res = await postMethod(url+"api/getuserdetails",newuser);
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
    }
    if(res.data && res.data.errmessage){
      toastAlert('error',res.data.errmessage,'validate')
    }

    if(res.data && res.data.data){
      setname(res.data.data.name);
      if(res.data.data.profileimage!='' && res.data.data.profileimage!=undefined){
      setprofileimage(url+"profile/"+res.data.data.profileimage);
      }
      setemail(res.data.data.email);
      setkycstatus(res.data.data.verifiedstatus);
      setgooglestatus(res.data.data.google);
      var start = new Date(res.data.data.date);
      setjoiningyr((start).getFullYear());
      setjoiningmonth(monthNames[(start).getMonth()]);
      
    }
    
  } 
  
  async function copyToClipboard(e) {
    var textField = document.createElement('textarea')
    textField.innerText = walletaddress;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toastAlert('success',"Copied",'success')
  };

  async function redirectkyc(){
     history.push('/kyc')
  }

   async function getcurrency(){
    var curlist =[{value: "ETH", label: "ETH"}]
    setcurrencydata(curlist);
   {/* var res = await getMethod(url+"api/currencydata");
    console.log(res.data.data,"====================curcurlist")
    if(res.data && res.data.data){
      var curlist =[{value: "ETH", label: "ETH"}]
      setcurrencydata(res.data.data);
    } */}
  }

  async function handleChange (event){
    
   {/* if (event == "BTC") {
      setwalletaddress(btcaddress);
    } */}
    if (event == "ETH") {
      setwalletaddress(ethaddress);
    }
  };

  const row=historyList.sort((a, b) => (a.id > b.id ? -1 : 1));

  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/dashboard" className="active">Dashboard</Link></li>
              </ul>
              {/* <div className="dash_heading">
                <h1 className="inner_title">Dashboard</h1>
                <div className="wallet_address">
                  <label>Your Wallet</label>
                  <p>{walletaddress}</p>
                  <a href="#" onClick={copyToClipboard} className="copy_icon"><i class="far fa-copy"></i></a>
                </div>
              </div> */}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem sm={12} md={12} lg={12} className="mt-3">
              <div className="dashboard_details">
                <GridContainer className="align-items-center">
                  <GridItem sm={12} md={5} lg={5}>
                    <div className="profile_detail_panel">
                    {profileimage==''?(
                        <img height="30px" width="30px" src={url+"image/Plus.png"} alt="Profile" className="img-fluid profile_img profile" />
                          ) : (
                          <img  src={profileimage} alt="Icon" height="30px" width="30px" className="upload_image profile img-fluid" />
                          )}
                      <div className="profile_details">
                        <h2>{name}</h2>
                        <p className="mb-0 emaild">{email}</p>
                        <p className="mb-0">User since {joiningmonth} {joiningyr}</p>
                        {/* <button className="btn btn-success btn-sm">KYC Verified</button> */}
                        {kycstatus=='Verified'?(
                          <button className="btn btn-success btn-sm " onClick={() => {redirectkyc()}}>KYC Verified</button>
                          ) : (
                            <button className="btn btn-danger btn-sm" onClick={() => {redirectkyc()}}>KYC Not Verified</button>
                          )}  
                      
                        
                      </div>
                    </div>
                  </GridItem>
                  <GridItem sm={12} md={4} lg={4}>
                    <div className="avail_balance_detail">
                      <p className="mb-0">Available Balance</p>
                      <h2>{fcpbal}<span>ETH-Filipcoin</span></h2>
                      <h2>{bscfcbbal}<span>BSC-Filipcoin</span></h2>
                      {/* <p className="mb-0">Last buy 12 Feb 2020</p> */}
                    </div>
                  </GridItem>
                  <GridItem sm={12} md={3} lg={3}>
                    <div className="balance_detail">
                      {/*<div className="d-flex align-items-center">
                        <img src={require("../assets/images/btc_icon.png")} alt="Icon" className="img-fluid" />
                        <h4 className="mb-0">{btcbal}</h4>
                      </div>*/}
                      <div className="d-flex align-items-center">
                        <img src={require("../assets/images/eth_icon.png")} alt="Icon" className="img-fluid" />
                        <h4 className="mb-0">{ethbal}</h4>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={6} lg={6}>
              <div className="inner_page_panel border_radius">
                <div className="dash_wallet_sec_panel">
                  <div className="dash_wallet_sec_panel_left">
                    <h4 className="m-0">Wallet Address</h4>
                     <Select native className="w-25" onChange={(e) => {handleChange(e.value)}}  options={currencydata}  >
                     </Select>
                    <div className="d-flex">
                      <p>{walletaddress}</p>
                      <a onClick={copyToClipboard} className="copy_icon ml-2"><i class="far fa-copy"></i></a>
                    </div>
                   <p class="text-danger">*The above transaction will be execute with the additional charges (Transfer amount + Gas fee)</p>
                  </div>
                  <div className="dash_wallet_sec_panel_right">
                    <QRCode
                        value={walletaddress}
                        size={122}
                        level={'H'}
                      />
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem sm={12} md={6} lg={6}>
              <GridContainer>
                <GridItem sm={12} md={6} lg={6}>
                  <div className="inner_page_panel border_radius">
                    <div className="dash_wallet_sec_panel">
                      <div className="dash_wallet_sec_panel_left">
                        <h4 className="m-0">KYC Verification</h4>
                        <div className="d-flex align-items-end">
                          <div>
                            <p>To confirm your Identity, please upload digital photos and documents</p>
                            {kycstatus=='Verified'?(
                          <Link className="text-success" to="/kyc">KYC  Verified</Link>
                          ) : (
                            <Link className="text-danger" to="/kyc">KYC Not Verified</Link>
                          )}  
                            
                          </div>
                          <img src={require("../assets/images/kyc_icon.png")} alt="Icon" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={12} md={6} lg={6}>
                  <div className="inner_page_panel border_radius">
                    <div className="dash_wallet_sec_panel">
                      <div className="dash_wallet_sec_panel_left">
                        <h4 className="m-0">2 Factor Authentication</h4>
                        <div className="d-flex align-items-end">
                          <div>
                            <p>Two Factor Authentication 2FA works by adding an additional layer of security to your online accounts.</p>
                            {googlestatus=='Enabled'?(
                           <Link className="text-warning" to="/2FA">Disable Security</Link>
                          ) : (
                            <Link className="text-warning" to="/2FA">Enable Security</Link>
                          )}  
                           
                          </div>
                          <img src={require("../assets/images/tfa_icon.png")} alt="Icon" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <h2 className="dash_subtitle">Recent Transaction History</h2>
              <TableContainer className="dash_table">
                <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Token Amount</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Value</TableCell>
                    
                      <TableCell>Transaction Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? historyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      :  historyList
                    ).map((row) => (
                      <TableRow key={row._id}>
                        <TableCell scope="row">
                          {Moment(row.created_date).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left">
                          {row.amount}
                        </TableCell>
                        
                        {row.txid==""?(
                          <TableCell align="left">{row._id} </TableCell> 
                          ):(
                          <TableCell align="left">{row.txid} </TableCell> 
                          )                         
                        }
                       
                        <TableCell align="left">
                          {row.currency}
                        </TableCell>
                        
                        <TableCell align="left">
                          {row.transferType}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>  
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
