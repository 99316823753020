import React, { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import * as Moment from "moment";
import CryptoJS from "crypto-js";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import { TextField, InputAdornment, FormControlLabel, Checkbox, Button,  MenuItem, InputLabel, FormControl } from '@material-ui/core';
import axios from "axios";

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;
const dashboardRoutes = [];
        
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));



export default function Profile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [country, setCountry] = React.useState('');
  const [_id, set_id] = React.useState('');
  const [name, setname] = React.useState('');
  const [lastname, setlastname] = React.useState('');
  const [email, setemail] = React.useState('');
  const [mobile, setmobile] = React.useState('');
  const [address, setaddress] = React.useState('');
  const [dob, setdob] = React.useState('');
  const [city, setcity] = React.useState('');
  const [viewprofileimage, setviewprofileimage] = React.useState('');
  const [profileimage, setprofileimage] = React.useState('');
  const [pincode, setpincode] = React.useState('');
  const [aboutme, setaboutme] = React.useState('');
  const [cntlist, setcntlist] = React.useState('');
  const [referralid, setreferralid] = React.useState('');
  var listcnty="";
  useEffect(()  => {
    getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      getuserdetails(verified.id);
      listcnty=countryList().getData();
      set_id(verified.id);
    }
   
    }else{
      history.push('/login')
    }
  
   });
  }catch(err){

  }
  }

  async function getuserdetails(iid){
   
    var newuser={
      id:iid,
    }
    var res = await postMethod(url+"api/getuserdetails",newuser);
    //.log(res,"============resresresresssssss==========================")
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
      // history.push('/login')
    }
    if(res.data && res.data.errmessage){
      toastAlert('error',res.data.errmessage,'validate')
      //history.push('/login')
    }

    if(res.data && res.data.data){
      setCountry(res.data.data.country);
      setcity(res.data.data.city);
      setpincode(res.data.data.pincode);
      setname(res.data.data.name);
      setlastname(res.data.data.lastname);
      setmobile(res.data.data.phonenumber);
      setaddress(res.data.data.address1);
      setdob(res.data.data.dob);
      set_id(res.data.data._id);
      setaboutme(res.data.data.aboutme);
      setemail(res.data.data.email);
      setcntlist(res.data.country);
      setreferralid(res.data.data.referencecode);
      if(res.data.data.profileimage!='' && res.data.data.profileimage!=undefined){
      setviewprofileimage(url+"profile/"+res.data.data.profileimage);
      }
    }
    
  } 

  const handleChange = (event) => {
    setCountry(event.value);
  };

  async function namechange(name){
    setname(name);
  }

  async function lastnamechange(name){
    setlastname(name);
  }

  async function emailchange(email){
    setemail(email);
  }


  async function mobilechange(mobile){
    const re = /^[0-9\b]+$/;
    if (mobile === '' || re.test(mobile)) {
      if(mobile.length<=15){
      setmobile(mobile);
      }
       //this.setState({value: e.target.value})
    }
    
  }

  async function addresschange(address){
    setaddress(address);
  }

  async function dobchange(dob){
    setdob(dob);
  }

  

  async function citychange(city){
    setcity(city);
  }

  async function pincodechange(pincode){
   
    
    setpincode(pincode);
  
  }

  async function aboutmechange(aboutme){
    setaboutme(aboutme);
  }

  async function formValidate(){

    var isValid = true;
    var error ="";
    
    if(email=="" || typeof email=="undefined"){
      isValid = false;
      error ="Must Enter Email";
    }

    if(!isValid){
      toastAlert('error',error,'validate')
    }else{
      return isValid;
    }

  }

  async function profileimagechange(img){
    setprofileimage( img)      
    setviewprofileimage(URL.createObjectURL(img));
  }

  async function save(){
    formValidate();
    if(formValidate()){
        var formData = new FormData();
        formData.append('id',_id);
        formData.append('email',email);
        formData.append('name',name);
        formData.append('lastname',lastname);
        formData.append('mobile',mobile);
        formData.append('address',address);
        formData.append('dob',dob);
        formData.append('city',city);
        formData.append('pincode',pincode);
        formData.append('country',country);
        formData.append('aboutme',aboutme);
        formData.append('profileimage',profileimage);
        

        var res = await postMethod(url+"api/updateprofile",formData)
        if(res.data && res.data.email){
          toastAlert('error',res.data.email,'validate')
        }
        if(res.data && res.data.dob){
          toastAlert('error',res.data.dob,'validate')
        }
        if(res.data && res.data.mobile){
          toastAlert('error',res.data.mobile,'validate')
        }
        if(res.data && res.data.message){
          toastAlert('success',res.data.message,'success')
        }
        if(res.data && res.data.error){
          toastAlert('error',res.data.error,'alert')
        }

    }
  }

  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/profile" className="active">Profile</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">Profile Details</h1>
              </div>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <h4>Personal Data</h4>
                  <hr/>                  
                  <form className="auth_form mt-5">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="First Name" value={name} onChange={(e) => {namechange(e.target.value)}} type="text" variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="Last Name" value={lastname} onChange={(e) => {lastnamechange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_user_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="Email Address" value={email} onChange={(e) => {emailchange(e.target.value)}} variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/email_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-4">
                        <TextField id="filled-name" label="Mobile Number" value={mobile} onChange={(e) => {mobilechange(e.target.value)}}  variant="filled" type="text"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_phone_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="City" type="text" value={city} onChange={(e) => {citychange(e.target.value)}} variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_map_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="Date of Birth" value={Moment(dob).format('YYYY-MM-DD')} onChange={(e) => {dobchange(e.target.value)}} variant="filled" type="date"  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_date_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <TextField id="filled-name" label="Address" type="text" value={address} onChange={(e) => {addresschange(e.target.value)}} variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_address_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                    </div>
                    <div className="form-row">
                     <div className="form-group col-md-4">
                        <TextField id="filled-name" label="Zip Code"  variant="filled" type="text" value={pincode} onChange={(e) => {pincodechange(e.target.value)}} InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_loc_icon.png")} alt="Input"/>
                        </InputAdornment>,}} />
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" disabled label="Referral Id" type="text" value={referralid}  variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_address_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-4">
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel id="demo-simple-select-filled-label"></InputLabel>
                          <Select labelId="demo-simple-select-filled-label" id="demo-simple-select-filled"  options={cntlist}  onChange={handleChange} 
                            startAdornment = { <InputAdornment position="start"><img src={require("../assets/images/form_country_icon.png")} alt="Input"/></InputAdornment>}>
                            {/* <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={1}>India</MenuItem>
                            <MenuItem value={2}>Australia</MenuItem>
                            <MenuItem value={3}>Canada</MenuItem> */}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <TextField className="about_text" id="filled-name" value={aboutme} label="About Me" rows={3} multiline type="text" onChange={(e) => {aboutmechange(e.target.value)}} variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/form_about_icon.png")} alt="Input"/>
                        </InputAdornment>,}}/>
                      </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6">
                      <TextField id="filled-name" label="Upload Profile Image"  value={profileimage.name}   type="text" disabled variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                        <img src={require("../assets/images/form_image_icon.png")} alt="Input"/>
                          </InputAdornment>,}}/>
                           <div className="upload_btn 1">
                              <input accept="image/*" onChange={(e) => {profileimagechange(e.target.files[0])}} className={classes.input} id="icon-button-file1" type="file" />
                                <label htmlFor="icon-button-file1">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <img src={require("../assets/images/form_upload_icon.png")} alt="Icon" />
                                  </IconButton>
                                </label>
                              </div>
                          <p className="upload_note">(*Note:  .jpg / .png   max 5mb)</p>
                     </div>
                     <div className="form-group col-md-6">
                     <div className="form-group col-md-6 text-center">  
                     {viewprofileimage==''?(
                        <img src={url+"image/Plus.png"} alt="Icon" className="upload_image sssss img-fluid" />  
                          ) : (
                          <img src={viewprofileimage} alt="Icon" className="upload_image sssss img-fluid" />
                          )}  
                                              {/* <img src={url+"Plus.png"} alt="Icon" className="upload_image sssss img-fluid" />   */}

                      </div>
                     </div>
                     </div>
                    <div className="text-center">
                      <Button className="join_btn" onClick={() => {save()}}><span className="join_btn_span">Submit</span></Button>
                    </div>
                  </form>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
