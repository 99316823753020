import React, {  useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import CryptoJS from "crypto-js";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { Modal} from "react-bootstrap/";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import { TextField, InputAdornment, FormControlLabel, Checkbox, Button, Tooltip, Typography } from '@material-ui/core';
import axios from "axios";

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";
import { func } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const url=config.baseUrl;
const dashboardRoutes = [];
        
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#b5884a',
    color: '#191919',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


export default function TFA(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");
  const [google, setgoogle] = React.useState('');
  const [_id, set_id] = React.useState('');
  const [currentpassword, setcurrentpassword] = React.useState('');
  const [resetpassword, setresetpassword] = React.useState('');
  const [password2, setpassword2] = React.useState('');
  const [secretcode, setsecretcode] = React.useState('');
  const [qrimage,setqrimage]= React.useState('');
  const [show,setshow]= React.useState(false);
  const [tfacode,settfacode]= React.useState('');
  const [TFAstatus,setTFAstatus]= React.useState('');
  const [backcode,setbackcode]=React.useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordreShown, setPasswordreShown] = useState(false);
  const [passwordoldShown, setPasswordoldShown] = useState(false);

  useEffect(()  => {
    getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      getsecretkey(verified.id);
      set_id(verified.id);
      
    }
    }else{
      history.push('/login')
    }
   });
  }catch(err){

  }
  }
  
  async function getsecretkey(idd){
    console.log("inside-------------------------------")
    if (idd !== undefined ) {
      var res = await postMethod(url+"api/getsecretkey/"+idd);
      if(res.data && res.data.data){
        console.log(res.data.data,"---------------------res.data.data");
        setsecretcode(res.data.data.newSecret.secret);
        setqrimage(res.data.data.newSecret.qr);
        console.log(res.data.data.newSecret.status,"===========")
        if(res.data.data.newSecret.status && res.data.data.newSecret.status!=undefined && res.data.data.newSecret.status!=""){
          setTFAstatus(res.data.data.newSecret.status);
        }

      }
    }
  }

  async function handleModal (showrest){
    if(backcode==true){
      setshow(showrest);
    }else{
      toastAlert('error',"Need to Backup 16 Digit Code",'validate')
    }
  };

  async function checkchange(chk){
    console.log(chk);
    setbackcode(chk);
  }

  async function currentpasswordchange(pass){
    console.log(pass);
    setcurrentpassword(pass);
  }

  async function newpasswordchange(pass){
    console.log(pass);
    setresetpassword(pass);
  }
  
  async function confirmchange(pass){
    console.log(pass);
    setpassword2(pass);
  }

  async function copyToClipboard(e) {
    var textField = document.createElement('textarea')
    textField.innerText = secretcode;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toastAlert('success',"Copied",'success')
  };
  
  async function TFAchange(code){
    settfacode(code);
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglerePasswordVisiblity = () => {
    setPasswordreShown(passwordreShown ? false : true);
  };

  const toggleoldPasswordVisiblity = () => {
    setPasswordoldShown(passwordoldShown ? false : true);
  };

  async function TFAenable(){
      
      const TFAdata = {
        id: _id,
        secretcode:secretcode,
        onecode: tfacode,
        secretqr: qrimage
      };
      var res = await postMethod(url+"api/2FA-enable",TFAdata);
      
      if(res.data && res.data.secretcode){
        toastAlert('error',res.data.secretcode,'validate')
      }
      if(res.data && res.data.onecode){
        toastAlert('error',res.data.onecode,'validate')
      }
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
        setTFAstatus(res.data.TFAstatus);
        setshow(false);
        window.location.reload();
      }
      if(res.data && res.data.error){
        toastAlert('error',res.data.error,'validate')
      }

  }

  async function changepassword(){
   
      if (currentpassword!= "undefined" &&
         currentpassword != ""
      ) {

      if (resetpassword!= "undefined" &&
      resetpassword != ""
      ) {
        if (resetpassword === password2) {
          const newpass = {
            id:_id,
            currentpassword: currentpassword,
            resetpassword: resetpassword,
            password2: password2,
          };
          var res = await postMethod(url+"api/passwordupdate",newpass);
          if(res.data && res.data.resetpassword){
            toastAlert('error',res.data.resetpassword,'validate')
          }
          if(res.data && res.data.password2){
            toastAlert('error',res.data.password2,'validate')
          }
          if(res.data && res.data.message){
            toastAlert('success',res.data.message,'success')
            setTFAstatus(res.data.TFAstatus);
            setshow(false);
          }
          if(res.data && res.data.error){
            toastAlert('error',res.data.error,'validate')
          }
    
          
              
        } else {
          toastAlert('error',"Password Mismatch",'validate')
        }
      } else {
        toastAlert('error',"Please Enter New Password",'validate')
      }
      } else {
        toastAlert('error',"Please Enter Current Password",'validate')
      }
   
  }

  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/2FA" className="active">Two - Factor Authentication</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">Two - Factor Authentication</h1>
                {TFAstatus=='Disable'?(
                  <p className="mb-0">Your Security Status is <span className="text-success"><b>ON</b></span></p>
                 ):(
                  <p className="mb-0">Your Security Status is <span className="text-danger"><b>OFF</b></span></p>
                 )}
              </div>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <GridContainer>
                    <GridItem sm={12} md={9} lg={9}>
                      <h4>Backup your secret key</h4>                
                      <form className="auth_form mt-3">
                        <div className="form-row align-items-center">
                          <div className="form-group col-md-6">
                            <TextField id="outlined-basic"  variant="outlined" value={secretcode} disabled/>
                          </div>
                          <div className="form-group col-md-4">
                            <Button className="join_btn" onClick={copyToClipboard}><span className="join_btn_span">Copy Code</span></Button>
                          </div>
                        </div>
                        <p>Please remember your secret key({secretcode}). If your secret key gets lost, stolen or erased, you will need this key to get back into your google authentication! Otherwise resetting your google two factor authentication requires opening a support ticket and take atleast 7 days to process.</p>
                        <FormControlLabel control={<Checkbox name="checkedC" id="myck" />} onChange={(e) => {checkchange(e.target.checked)}} label="I have backed up the 16 digit code" className="mb-3 font_13"/>
                        <div className="text-left">
                          <Button className="join_btn" onClick={() => {
                            handleModal(true);
                          }}><span className="join_btn_span">Continue</span></Button>
                        </div>
                      </form>
                    </GridItem>
                    <GridItem sm={12} md={3} lg={3}>
                      <h4>Scan QR Code</h4>
                      <img src={qrimage} alt="Input"/>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>

              <div className="inner_page_panel border_radius">
                <div className="kyc_sec">
                  <h4>Change Password 
                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Typography>For better security your new password should be 8 characters long, atleast one UPPER case, atleast one special character like @[]%#~^</Typography>
                        </React.Fragment>}
                    >
                    <span className="change_pass_note"><i class="fas fa-info-circle"></i></span>
                    </HtmlTooltip>
                  </h4>           
                  <form className="auth_form mt-3">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" onChange={(e) => {currentpasswordchange(e.target.value)}} value={currentpassword} label="Current Password" type={passwordoldShown ? "text" : "password"} variant="filled" InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/lock_icon.png")} alt="Input"/><i className="eye_icon" onClick={toggleoldPasswordVisiblity}>{eye}</i>
                        </InputAdornment>,}}/>
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="New Password" onChange={(e) => {newpasswordchange(e.target.value)}} value={resetpassword} variant="filled" type={passwordShown ? "text" : "password"}  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/lock_icon.png")} alt="Input"/><i className="eye_icon" onClick={togglePasswordVisiblity}>{eye}</i>
                        </InputAdornment>,}} />
                      </div>
                      <div className="form-group col-md-4">
                        <TextField id="filled-name" label="Confirm New Password"  onChange={(e) => {confirmchange(e.target.value)}} value={password2} variant="filled" type={passwordreShown ? "text" : "password"}  InputProps={{startAdornment: <InputAdornment position="start">
                          <img src={require("../assets/images/lock_icon.png")} alt="Input"/><i className="eye_icon" onClick={togglerePasswordVisiblity}>{eye}</i>
                        </InputAdornment>,}} />
                      </div>
                    </div>
                    <div className="text-left">
                      <Button className="join_btn" onClick={() => {
                            changepassword();
                          }}><span className="join_btn_span">Update</span></Button>
                    </div>
                  </form>
                  <Modal
                      show={show}
                      onHide={() => {
                        handleModal(false);
                      }}
                      centered
                    >
                      <Modal.Header closeButton>
                        <h4 >2FA Code</h4>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12 mx-auto">
                            <div className="buyAmt">
                              <div className="col-md-12">
                              
                                <input
                                  value={tfacode}
                                  id="tfacode"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter the 2FA Code"
                                  onChange={(e) => {TFAchange(e.target.value)}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btnType1 smallFont py-2 px-3 mr-2"
                          onClick={() => {
                            handleModal(false);
                          }}
                        >
                          Cancel
                        </button>
                        {TFAstatus=='Disable'?(
                           <button
                           className="btn btnType1 smallFont py-2 px-3 mr-2"
                           onClick={() => TFAenable()}
                           >
                             Disable
                           </button>
                          ) : ( <button
                            className="btn btnType1 smallFont py-2 px-3 mr-2"
                            onClick={() => TFAenable()}
                            >
                              Enable
                            </button>)}
                        
                          
                        
                      </Modal.Footer>
                    </Modal>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
