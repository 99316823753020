import React , { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import Select from 'react-select';
import Moment from 'moment';
import QRCode from 'qrcode.react';
import CryptoJS from "crypto-js";
import { Link,useHistory ,useParams} from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, Button, TableHead, TextField,  FormControl } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import Web3 from 'web3';
import '@metamask/legacy-web3'
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;
const dashboardRoutes = [];
const key = CryptoJS.enc.Base64.parse("#base64Key#");
const iv = CryptoJS.enc.Base64.parse("#base64IV#");
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(date, amount, id, eth, usd, status) {
  return { date, amount, id, eth, usd, status };
}

const rows = [
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Failed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Failed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Completed</span>),
].sort((a, b) => (a.id > b.id ? -1 : 1));
																		
export default function BuyToken(props) {

  var   {  keys } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [_id, set_id] = React.useState('');
  const [buyamount, setbuyamount] = React.useState(0);
  const [loading, setloading] = React.useState(false);
  const [amount, setamount] = React.useState(0);
  const [currencydata, setcurrencydata] = React.useState('');
  const [historyList, sethistoryList] = React.useState([]);
  const [token_price_btc, settoken_price_btc] = React.useState(0);
  const [min_btc, setmin_btc] = React.useState(0);
  const [min_eth, setmin_eth] = React.useState(0);
  const [ethfee, setethfee] = React.useState(0);
  const [bnbfee, setbnbfee] = React.useState(0);
  const [btcfee, setbtcfee] = React.useState(0);
  const [ethdoller, setethdoller] = React.useState(0);
  const [btcdoller, setbtcdoller] = React.useState(0);
  const [bnbdoller, setbnbdoller] = React.useState(0);
  const [transactionfee, settransactionfee] = React.useState(0);
  const [token_price_eth, settoken_price_eth] = React.useState(0);
  const [token_price_bnb, settoken_price_bnb] = React.useState(0); 
  const [walletaddress, setwalletaddress] = React.useState('0x000000000000000000');
  const [ethaddress, setethaddress] = React.useState('0x000000000000000000');
  const [btcaddress, setbtcaddress] = React.useState('0x000000000000000000');
  const [fcpbal,setfcpbal] = React.useState('');
  const [mainnet,setmainnet] = React.useState(false);
  const [kycstatus,setkycstatus] =  React.useState('Not Verified');
  const history = useHistory();
  let assign = keys == "bnb" ? "BNB" : "ETH";
  console.log(assign,keys,"====assisgn")
  const [currency, setcurrency] = React.useState(assign);

  useEffect(()  => {
    getuserid();
  },[])

  async function getbnbdoller(){
   var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data && res.data.data[0]){
      var interval = 1/parseFloat(res.data.data[0].token_price_BNB)
      var bnbval = (parseFloat(interval)*parseFloat(res.data.data[0].token_price)).toFixed(8)
      setbnbdoller(bnbval);
    }
  }

  async function getuserid(){
    try{
      var name = "Bearer ";
      var authToken = localStorage.getItem('jwtToken')
      var token = authToken.replace(name, "");
      var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
      var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
      jwt.verify(token, decrypt_val, (err, verified) => {
        console.log(err,verified,decrypt_val,token,decipher,"======veriririr")
      if(!err && verified && verified.id){
      if( verified.id!=""  &&  verified.id!=undefined && _id==""){
        getcurrency();
        getbuytokenlist(verified.id);
        set_id(verified.id);
        getethdoller();
        getbtcdoller();
        getbnbdoller();
        getvalue();

      }
      }else{
        console.log("========")
        history.push('/login')
      }
    });
  }catch(err){
    console.log(err,"======rre")
  }
  }

  var historylist=[];

  async function getethdoller(){
    var res = await getMethod(url+"api/feedata");
    if(res.data && res.data.data && res.data.data[0] ){
       var interval = 1/parseFloat(res.data.data[0].token_price_eth)
     var ethval = (parseFloat(interval)*parseFloat(res.data.data[0].token_price)).toFixed(8)
     setethdoller(ethval);
    }
  }

  async function getbtcdoller(){
   
  }

  async function getcurrency(){
    var curlist =[{value: "ETH", label: "ETH"},{value: "BNB", label: "BNB"}]
    setcurrencydata(curlist);
    getvalue();
   {/* var res = await getMethod(url+"api/currencydata");
    if(res.data && res.data.data){
      getvalue();
      setcurrencydata(res.data.data);
    } */}
  }

  async function getvalue(){
    var res = await getMethod(url+"api/feedata");
    console.log(res,"===========getget")
    if(res.data && res.data.data){
      settoken_price_btc(res.data.data[0].token_price_btc);
      settoken_price_eth(res.data.data[0].token_price_eth);
      settoken_price_bnb(res.data.data[0].token_price_BNB)
      setmin_eth(res.data.data[0].min_investment_eth);
      setmin_btc(res.data.data[0].min_investment_btc)
      setethfee(res.data.data[0].transaction_fee);
      setbnbfee(res.data.data[0].transaction_bnbfee);
      setbtcfee(res.data.data[0].transaction_fee1);
    }
  }

  async function getbuytokenlist(iid){
    var res = await getMethod(url+"api/getbuytokenhistory/"+iid);
    
    if(res.data && res.data.data){
      var log=res.data.data;
      getuserdetails(iid);
      getwallet(iid);
      getaddress(iid);
      getbtcaddress(iid);
      sethistoryList(log);
    }
  }

  async function getaddress(iid){
    var res = await getMethod(url+"api/getaddresss/"+iid);
    if(res.data && res.data.data){
      setwalletaddress(res.data.data.address);
      setethaddress(res.data.data.address);
    }else{
      setwalletaddress("0*0000000000000");
    }
  }
  async function getbtcaddress(iid){
    var res = await getMethod(url+"api/getbtcaddress/"+iid);
    if(res.data && res.data.data){
      setbtcaddress(res.data.data.address);
    }else{
      setbtcaddress("0*0000000000000");
    }
  }

  async function getwallet(iid){
    var res = await getMethod(url+"api/getuserwallet/"+iid);
    
     if(res.data && res.data.fcp){
      var babal=(res.data.fcp).substring(0, 8);
      setfcpbal(babal);
    }
  }

  async function getuserdetails(iid){
   
    var newuser={
      id:iid,
    }
    var res = await postMethod(url+"api/getuserdetails",newuser);
    if(res.data && res.data.error){
      toastAlert('error',res.data.error,'validate')
    }
    if(res.data && res.data.errmessage){
      toastAlert('error',res.data.errmessage,'validate')
    }

    if(res.data && res.data.data){
      if(res.data.data.verifiedstatus=='Verified'){
      setkycstatus(res.data.data.verifiedstatus);
      }
    }
    
  } 
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  window.addEventListener('load', function () {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
           window.ethereum.on('accountsChanged', function (accounts) {
             getconnect();
            })
           window.ethereum.on('networkChanged', function (networkId) {
            if(networkId==config.network){
               setmainnet(true)
               setcurrency("BNB")
            }else{
               setmainnet(false)
               setcurrency("BNB")
            }
          })
        }else{
        toastAlert('error',"Please Add Metamask External",'error')
        setmainnet(false)
      }
      }catch(err){
        setmainnet(false)
      }
    }else{
        toastAlert('error',"Please Add Metamask External",'error')
        setmainnet(false)
      }
  })

  async function getconnect(){
    setcurrency("")
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setwalletaddress(result[0]);
           if(window.web3.currentProvider.networkVersion == config.network){
              setmainnet(true);
               var currAddr =result[0];
              console.log("inside===========================")
              setcurrency("BNB")
              var enteredamount = parseFloat(buyamount);
              var price = parseFloat(bnbdoller);
              if(enteredamount!="" && enteredamount!=undefined && enteredamount!=null){
              var fullamount = parseFloat(enteredamount) * parseFloat(price);
              setamount(fullamount);
              }else{
                setamount(0)
                setbuyamount(0)
              }
              settransactionfee(bnbfee);
            }else{
              setmainnet(false)
            }
          })
        }else{
          setmainnet(false)
        }
      }).catch((error) => {
        toastAlert('error',"Please Login Metamask External",'error')
      });
      }else{
        toastAlert('error',"Please Add Metamask External",'error')
        setmainnet(false)
      }
    }catch(err){
      setmainnet(false)
    }
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
    setmainnet(false)
     }
  }

  async function handleChange (event){
    setcurrency(event);
    if (event == "ETH") {
      if(buyamount!=""){
      var enteredamount = parseFloat(buyamount);
      var price = parseFloat(ethdoller);
      var fullamount =parseFloat(enteredamount) * parseFloat(price);
      settransactionfee(ethfee);
      setamount(fullamount);
    }
      setwalletaddress(ethaddress);
    }
    if(event=="BNB" || event ==""){
      setwalletaddress("")
      console.log("=================uu====",buyamount,buyamount)
      if(buyamount!=""){
      var enteredamount = parseFloat(buyamount);
      var price = parseFloat(bnbdoller);
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
      settransactionfee(bnbfee)
    }
      if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setwalletaddress(result[0]);
           if(window.web3.currentProvider.networkVersion == config.network){
              setmainnet(true);
               var currAddr =result[0];
            }else{
              setmainnet(false)
            }
          })
        }else{
          setmainnet(false)
        }
      }).catch((error) => {
        toastAlert('error',"Please Login Metamask External",'error')
      });
      }else{
        toastAlert('error',"Please Add Metamask External",'error')
        setmainnet(false)
      }
    }catch(err){
      setmainnet(false)
    }
   }else{
    toastAlert('error',"Please Add Metamask External",'error')
    setmainnet(false)
     }
    }

    if(buyamount!=""){
       
    if (event == '') {
      var enteredamount =parseFloat( buyamount);
      var price = 0;
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
    }
  }else{
    setamount(0);
  }
  };

  async function buyamountchange(ee){
    
    if(ee!=""){
    if (currency == "ETH") {
      var enteredamount = parseFloat(ee);
      var price = parseFloat(ethdoller);
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
      settransactionfee(ethfee)
    }

   {/* if (currency == "BTC") {
      var enteredamount = parseFloat(ee);
      var price =parseFloat( token_price_btc);
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
      setwalletaddress(btcaddress);
      settransactionfee(btcfee)
    } */}

    if(currency == "BNB" || currency==""){
      var enteredamount = parseFloat(ee);
      var price = parseFloat(bnbdoller);
      var fullamount = parseFloat(enteredamount) * parseFloat(price);
      setamount(fullamount);
      settransactionfee(bnbfee)
    }

    
    // if (currency == '') {
    //   var enteredamount =parseFloat( ee);
    //   var price = 0;
    //   var fullamount = parseFloat(enteredamount) * parseFloat(price);
    //   setamount(fullamount);

    // }
  }else{
    setamount(0);
  }
  setbuyamount(ee);
  } 

  async function amountchange(ee){
    setamount(ee);
  }

  async function formValidate(){
    var isValid = true;
    var error ="";
    console.log(amount,buyamount,"================checkingform")
    if(amount=="" || amount=="undefined" || amount==NaN || amount==0 || amount=='0'){
      isValid = false;
      error ="Invalid Amount";
    }else if(buyamount=="" || buyamount=="undefined" || buyamount==NaN || buyamount==0 || buyamount=='0'){
      isValid = false;
      error ="Enter Valid Token";
    }
    console.log()
    if(!isValid){
      toastAlert('error',error,'validate')
    }else{
      return isValid;
    }
  }

  async function ethradiochange() {
   setcurrency("ETH")
   var enteredamount = parseFloat(buyamount);
   var price = parseFloat(ethdoller);
   var fullamount =parseFloat(enteredamount) * parseFloat(price);
   settransactionfee(ethfee);
   setamount(fullamount);
  }

  async function bnbradiochange(){
    console.log("=============================inised")
    await getconnect()
    console.log("================ebd")
  }

  async function buynow(){
    if(amount!="" && amount!="undefined" && amount!=NaN && amount!=0 && amount!='0' && parseFloat(amount)>0){
     if(buyamount!="" && buyamount!="undefined" && buyamount!=NaN && buyamount!=0 && buyamount!='0' && parseFloat(buyamount)>0){
      if (currency == "ETH" ) {
        setloading(true);
        const Buydata = {
          id: _id,
          tokenvalue: buyamount,
          currency: currency,
          amount: amount,
          transfee:transactionfee
        };
        var res = await postMethod(url+"api/tokenbuynew",Buydata);
        if(res.data && res.data.message){
          setloading(false);
          toastAlert('success',res.data.message,'success')
          window.location.reload();
        }
        if(res.data && res.data.errmsg){
          toastAlert('error',res.data.errmsg,'error')
          setloading(false);
        }
      }else if(currency == "BNB" || currency==""){
        setloading(true)
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        try {
          if (typeof web3 !== 'undefined') {
          window.ethereum.enable().then(async function() {
          const web3  = new Web3(window.web3.currentProvider)
          if (window.web3.currentProvider.isMetaMask === true) {
            await web3.eth.getAccounts(async function(error, result) {
              setwalletaddress(result[0]);
             if(window.web3.currentProvider.networkVersion == config.network){
                setmainnet(true);
                 var currAddr =result[0];
                 await web3.eth.getBalance(currAddr,async(error, balance) =>{
                   if(typeof balance == 'undefined' || balance == null) {
                    toastAlert('error',"Insufficient balance",'error')
                    setloading(false)
                     return false;
                   }
                   var currbal = balance/1000000000000000000;
                   console.log(currbal,amount,"============================balance")
                   if (currbal == 0) {
                     toastAlert('error',"Insufficient balance",'error')
                     setloading(false)
                     return false;
                   }
                   if(parseFloat(currbal)<parseFloat(amount)){
                    toastAlert('error',"Insufficient balance",'error')
                    setloading(false)
                     return false;
                   }
                   if(parseFloat(currbal)==parseFloat(amount)){
                    toastAlert('error',"Insufficient balance for Gas Fee",'error')
                    setloading(false)
                     return false;
                   }

                   await web3.eth.sendTransaction({
                    from:result[0],
                    to:config.adminethaddress,
                    value: window.web3.toWei(amount)},async function(err, results) {
                      console.log(results,"============bnb=result")
                      if(results && results!=''){
                      console.log(results,"=========bnb========")
                      const Buydata = {
                      id: _id,
                      tokenvalue: buyamount,
                      currency: "BNB",
                      amount: amount,
                      transfee:transactionfee,
                      trxid: results
                    };
                    var res = await postMethod(url+"api/tokenbuynew",Buydata);
                    if(res.data && res.data.message){
                      setloading(false);
                      toastAlert('success',res.data.message,'success')
                      window.location.reload();
                    }
                    if(res.data && res.data.errmsg){
                      toastAlert('error',res.data.errmsg,'error')
                      setloading(false);
                    }
                  }
                }).catch(err => {
                  toastAlert('error',"Error occured Try Again Later",'error')
                  setloading(false)
                })
               })
              }else{
                setmainnet(false)
                setloading(false)
              }
            })
          }else{
            setmainnet(false)
            setloading(false)
          }
        }).catch((error) => {
          toastAlert('error',"Please Login Metamask External",'error')
          setloading(false)
        });
        }else{
          toastAlert('error',"Please Add Metamask External",'error')
          setmainnet(false)
          setloading(false)
        }
      }catch(err){
        setmainnet(false)
        setloading(false)
      }
     }else{
      toastAlert('error',"Please Add Metamask External",'error')
      setmainnet(false)
      setloading(false)
       }
      }else{
        toastAlert('error',"Choose Currency ",'validate');
        setloading(false);
      }
      }else{
      toastAlert('error',"Invalid Token Amount",'validate');
        setloading(false);
     }
     }else{
      toastAlert('error',"Invalid Amount.!",'validate');
        setloading(false);
     }

  }

  const row=historylist.sort((a, b) => (a.id > b.id ? -1 : 1));

  const { ...rest } = props;
  const classes = useStyles();
  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/buy-token" className="active">Buy Token</Link></li>
              </ul>
              <div className="dash_heading flex-start">
                <h1 className="inner_title">Buy Token</h1>
                
                <div className="radio_burton">
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio1" name="customRadio" checked={currency=="ETH"? true : false} 
                                   onClick={()=>ethradiochange()} class="custom-control-input"/>
                    <label class="custom-control-label" for="customRadio1">ETH</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="customRadio2" name="customRadio" checked={currency=="ETH"? false : true} 
                                   onClick={()=>bnbradiochange()} class="custom-control-input"/>
                    <label class="custom-control-label" for="customRadio2">BSC</label>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={6} lg={6}>
              <div className="inner_page_panel border_radius">
                <div className="dash_wallet_sec_panel d-block">
                  <div className="dash_wallet_sec_panel_left">
                    <h4 className="m-0"><span>Step 1:</span> Calculate Token Amount</h4>
                    <div class="dashboard_details mt-3">
                      <p className="text-dark mb-0">Total Available balance</p>
                      <h2 className="text-dark mb-0">{fcpbal} FCP</h2>
                    </div>
                    <form className="auth_form support_form mt-3">
                      <div className="form-row">
                        <div className="form-group col-12 mb-3">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b>Tokens You Receive</b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={buyamount} onChange={(e) => {buyamountchange(e.target.value)}} variant="outlined"/>
                              <TextField id="outlined-basic" variant="outlined" value="FCP" disabled className="w-25"/>
                            </div>
                          </FormControl>
                        </div>
                        <div className="form-group col-12">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <label htmlFor="outlined-age-native-simple"><b>Amount You Spend</b></label>
                            <div className="input_group">
                              <TextField id="outlined-basic" value={amount} onChange={(e) => {amountchange(e.target.value)}} variant="outlined" disabled/>
                              <Select native className="w-25 custom_cur_select" defaultValue={[{ value: currency, label: currency }]} onChange={(e) => {handleChange(e.value)}}  options={currencydata} value={currency==""? [{ value: "BNB", label: "BNB" }]:[{ value: currency, label: currency }]} >
                                {/* <option>{currencydata}</option> */}
                                {/* <option value={1}>USD</option>
                                <option value={2}>INR</option> */}
                              </Select>
                            </div>
                          </FormControl>
                       </div>
                      </div>
                    </form>
                    <p><b>Notes</b></p>
                    <p className="mb-1">1. Choose token amount for investment</p>
                    <p className="mb-1">2. Choose payment system: Ethereum</p>
                    <p className="mb-1">3. Price per token: {ethdoller} ETH</p>
                    <p className="mb-1">3. Price per token: {bnbdoller} BNB</p>
                    <p className="mb-1">4. Min investment: {min_eth} ETH</p>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem sm={12} md={6} lg={6}>
              <div className="inner_page_panel border_radius">
                <div className="dash_wallet_sec_panel d-block">
                  <div className="dash_wallet_sec_panel_left">
                    <h4 className="m-0"><span>Step 2:</span> Buy Filipcoin (FCP) Tokens</h4>
                    <div className="text-center">
                      <h4 className="mt-3">Verification Status: <span className="text-success">{kycstatus}</span></h4>
                      <form className="auth_form support_form mt-3">
                        <div className="form-row">
                          <div className="form-group col-8 offset-2 mb-2">
                            <FormControl variant="outlined" className={classes.formControl}>
                            <TextField id="outlined-basic" value={currency +" Address"}  variant="outlined" disabled/>
                            </FormControl>
                          </div>
                        </div>
                      </form>
                      <p><b>Scan QR Code</b></p>
                      <QRCode
                        value={walletaddress}
                        size={128}
                        level={'H'}
                      />
                      {/* <img src={require("../assets/images/qr_code.png")} alt="Icon" className="img-fluid" /> */}
                      <p>Token Send Address</p>
                      <p className="token_address">{walletaddress}</p>
                      {loading == true ? (
                          <div>
                            <Loader
                              type="Watch"
                              color="#FFBB2A"
                              height={50}
                              width={50}
                            />
                          </div>
                        ) : (
                        <Button className="join_btn mb-4" onClick={currency!="ETH" && mainnet==false?"":()=>{buynow()}}><span className="join_btn_span">{currency!="ETH" && mainnet==false? "Choose BSC MainNet" : "Buy"}</span></Button>
                        )}
                      </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <h2 className="dash_subtitle">Recent Transaction History</h2>
              <TableContainer className="dash_table">
                <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Token Amount</TableCell>
                      <TableCell>Transaction Hash</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Transaction Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? historyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : historyList
                    ).map((row) => (
                      <TableRow key={row._id}>
                        <TableCell scope="row">
                          {Moment(row.created_date).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left">
                          {row.token_receive}
                        </TableCell>
                        {row.transactionHash==""?(
                          <TableCell align="left">{row._id} </TableCell> 
                          ):(
                          <TableCell align="left">{row.transactionHash} </TableCell> 
                          )                         
                        }                      
                        <TableCell align="left">
                          {row.amount+" "+row.currency}
                        </TableCell>
                       
                        <TableCell align="left">
                          {"Completed"}
                        </TableCell>
                        <TableCell align="left">
                          Buy
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>  
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
