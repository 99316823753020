import React ,{ useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import Moment from 'moment';
import CryptoJS from "crypto-js";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import config from "../actions/config";
import { getMethod }  from "../actions/actions";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksAfterLogin from "components/Header/HeaderLinksAfterLogin.js";
import { Link ,useHistory,useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableFooter, TableRow, TableHead } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "../actions/types";

const url=config.baseUrl;

const dashboardRoutes = [];
const key = CryptoJS.enc.Base64.parse("#base64Key#");
const iv = CryptoJS.enc.Base64.parse("#base64IV#");
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(date, amount, id, eth, usd, type, status) {
  return { date, amount, id, eth, usd, type, status };
}

const rows = [
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Buy</span>, <span className="text-success">Completed</span> ),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Send</span>, <span className="text-danger">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Transfer</span>, <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Buy</span>, <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-danger">Send</span>, <span className="text-danger">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Transfer</span>, <span className="text-success">Completed</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-info">Transfer</span>, <span className="text-danger">Pending</span>),
  createData('13/10/2020 01:36 PM', "2000", "217523489", 0.25800000, "$1250.00", <span className="text-success">Buy</span>, <span className="text-success">Completed</span>),
].sort((a, b) => (a.id > b.id ? -1 : 1));
																		
export default function History(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const history = useHistory();
  const { ...rest } = props;
  const classes = useStyles();

  const [_id, set_id] = React.useState('');
  const [historyList, sethistoryList] = React.useState([]);

  useEffect(()  => {
    getuserid();   
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
       if(!err && verified && verified.id){
        gettransactionlist(verified.id);
        set_id(verified.id);
     }else{
      history.push('/login')
    }
  
   });
  }catch(err){

  }
  }

  var historylist=[];
  async function gettransactionlist(iid){
    var res = await getMethod(url+"api/getalltransaction/"+iid);
    console.log(res,"====rarara")
    if(res.data && res.data.data && res.data.data[0] && res.data.data[0].list){
      var log=res.data.data[0].list;
      sethistoryList(log);
    }    
  }

  const row=historyList.sort((a, b) => (a.id > b.id ? -1 : 1));

  return (
    <div className="inner_pages">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksAfterLogin />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/history" className="active">History</Link></li>
              </ul>
              <div className="dash_heading">
                <h1 className="inner_title">History</h1>
              </div>
            </GridItem>
          </GridContainer>
          
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <TableContainer className="dash_table">
                <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                    <TableCell>Date</TableCell>
                      <TableCell>Token Amount</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? historyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : historyList
                    ).map((row) => (
                      <TableRow key={row._id}>
                        <TableCell scope="row">
                          {Moment(row.created_date).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left">
                          {row.amount}
                        </TableCell>
                          {row.txid==""?(
                          <TableCell align="left">{row._id} </TableCell> 
                          ):(
                          <TableCell align="left">{row.txid} </TableCell> 
                          )                         
                        }
                        <TableCell align="left">
                          {row.currency}
                        </TableCell>
                        
                        <TableCell align="left">
                          {row.transferType}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>  
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
