import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinksafter_inner from "components/Header/HeaderLinksAfterLogin.js";
import { Link } from "react-router-dom";
import config from "../actions/config";

const dashboardRoutes = [];

export default function TermsConditions(props) {

  const url=config.baseUrl;
  const { ...rest } = props;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  useEffect(()  => {
     getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }


  return (
    <div className="inner_pages">
       {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksafter_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">Terms and Conditions</Link></li>
              </ul>
              <h1 className="inner_title">Terms and Conditions</h1>
            </GridItem>
          </GridContainer>
          <GridContainer className="align-items-center">
            <GridItem sm={12} md={12} lg={12} className="mt-3">
              <div className="inner_page_panel">
                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. This Privacy Policy for softNio</p>
                <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from http://filipcoin.com/ico</p>
                <p>These Terms and Conditions (hereafter, the “T&C”) are entered into between the association of persons currently in the process of forming a stock corporation under the corporate name “Service Economy Applications Inc.” under the laws of the Republic of the Philippines, having its principal office at 3/F Unit 305-306, Civic Prime Building, Civic Drive, Centennial Lane St., Filinvest Corporate City, Alabang, Muntinlupa City, 1781, Philippines (hereafter referred to as the “Company”, “SEA”, “we”, “us”, or “our”) and the natural or legal person purchasing the Company’s products or services (hereafter referred to as the “Buyer”, “you”, or “your”). The Company and the Buyer are referred to individually as a “Party” and together as the “Parties”.</p>
                <p>The team behind this Token Sale is currently in the process of incorporation with the Securities and Exchange Commission (hereafter, “SEC”) of the Philippines. Our signed and notarized Articles of Incorporation and Treasurer’s Affidavit, which attests to the authorized capital stock, subscribed capital stock, and the paid-up capital currently on deposit in trust for SEA, are uploaded at the following link: www.sea-inc.tech.</p>
                <p>Pursuant to Section 21 of the Corporation Code of the Philippines (hereafter, the “Corporation Code”), your rights under these T&C are enforceable against the incorporators as general partners, insofar as we have assumed to act as a corporation at the time when we are still in the process of incorporation. Given this disclosure, you will be precluded from resisting performance of your obligations and compliance with your warranties and representations under these T&C, on the ground that you have agreed to these T&C at the time when we have not in fact been registered as a corporation yet.</p>
                <p>Once the Company has been issued a Certificate of Incorporation from the SEC, the incorporators fully intend to adopt and ratify these T&C as a valid and binding corporate act. Thereafter, your rights under these T&C will be fully enforceable against the Company, as a juridical person, and not against the incorporators themselves, except under the extraordinary circumstances recognized under Philippine law. Conversely, the Company, as a juridical person, will be entitled to enforce its rights under these T&C.</p>
                <p>We suggest that you visit this page regularly to keep up to date with any changes, including updates in respect of our incorporation process. Your continued use of https://www.filipcoin.com (the “website”), or any information, links or content contained on the website, including the FILIPCOINs, will confirm your acceptance of these T&C as modified, changed, supplemented or updated by us. If you do not agree to such revised terms you must stop using this Site and any information, links or content contained on this website, as well as the FILIPCOINs.</p>
                <p>These T&C apply fully and automatically to all the products and services offered for sale by the Company as part of the sale entitled “FILIPCOIN Token Sale” (the “Token Sale”) towards its Buyers. Unless otherwise stated herein, these T&C only govern the Token Sale. </p>
                <p>Any use of products bought through the Token Sale relating to providing or receiving services in our ecosystem may be governed by other applicable terms and conditions and policies of the Company or third parties.</p>
                <p> These T&C form the basis for the sales agreement. These prevail over all other documents entered into by you or the Company and, as from the date of entry into force, shall apply to all purchases, including outstanding orders (the “Orders”), and are deemed to be unconditionally accepted by the Buyer as of the conclusion of an Order. Any exemption from these T&C will require the express agreement of both Parties. Any other document (e.g. quotation, presentation, etc.) is provided to the Buyer for information purposes only and shall not constitute an agreement with or a warranty or representation of the Company, all of which are disclaimed herein. The provisions stated in this paragraph constitute a key factor for the Company’s consent.</p> 
                <p> The T&C form a contract between the Buyer and the Company for the purposes of the Token Sale. By clicking on “I hereby certify that I have read and expressly accept the present Terms & Conditions”, you accept and acknowledges that we are entering into a binding contract with the Company and agree to be bound by it.</p>
                <p>If you have any questions regarding these T&C, please contact us at: <a href="mailto:helpdesksupport@filipcoin.com">helpdesksupport@filipcoin.com</a></p> 
            <h2>NATURE OF FILIPCOIN AND TOKEN SALE</h2>
                <p>The Token Sale covered by these T&C involves the sale of virtual tokens, referred to as the “FILIPCOIN”.</p>
            <h5><b>Functional Utility Virtual Tokens akin to Digital Assets</b></h5> 
                <p>FILIPCOINs are intended to be functional utility tokens designed to operate in our ecosystem. Tokens are sold as digital assets, similar to downloadable software, digital music and the like. FILIPCOINs allow owners access to our ecosystem and pay for the services we offer. At the time of sale and distribution, FILIPCOIN holders gain mandatory and automatic access to our first fully developed and functional project: the FILIPWORKS. While we do intend to add functionalities to the FILIPWORKS and progressively integrate it into the Ethereum blockchain, and while we intend to develop additional projects such as the FILIPAY and AZERV, among other projects pipelined in the Whitepaper, our intention is primarily driven by our mission, which is to revolutionize the service economy through the development of blockchain-based systems in this sector. We are not undertaking to do so for the purpose of increasing the value of the FILIPCOIN for holders, traders, or users, or to create an electronic currency, a payment system, or a commodity for futures contracts. As functional utility tokens, FILIPCOINs are designed for use in our ecosystem.</p>
                <p>The Buyers’ attention is called to the fact that purchasing FILIPCOINs does not confer automatic access to all of the projects lined up in the Whitepaper. FILIPCOINs may not be used before the effective launch of the FILIPAY and AZERV projects, and the rest of the projects lined up in the Whitepaper. FILIPCOINs will not be reimbursed if the service platform for these projects, including the added functionalities of the FILIPWORKS and its full integration into the Ethereum blockchain, are not successfully launched, or do not operate on a permanent basis. You acknowledge that this is a significant risk that you accept. </p>
            <h5><b>Not Intended to be Securities</b></h5>
                <p>FILIPCOINs are not intended to constitute or to function as securities in any jurisdiction. FILIPCOINs do not entitle the owner to any equity, governance, voting or similar rights or entitlement in the Company. There are no redemption or repayment obligations similar to debt securities. The purchase of FILIPCOINS through this Token Sale is not intended to constitute an investment contract.</p>
                <p>You are strongly advised not to purchase FILIPCOIN if you are driven by a speculation of profit from the possible increase in market value, based on data and forward-looking statements in the Whitepaper regarding our projects. You are advised to purchase FILIPCOIN primarily because you intend to use the FILIPWORKS, which is already functional, and to contribute to the blockchain community that we are building in the service economy sector. Please note that if we do not succeed in adding additional functionalities to the FILIPWORKS and integrating it into the Ethereum blockchain, or in operationalizing FILIPAY, AZERV, or any of the projects contemplated in the Whitepaper, you will not receive a refund for the purchase of the FILIPCOINs or a payment of lost earnings or profit which you may have speculated at the time when you purchased a FILIPCOIN.</p>
                <p>The Buyer accepts and acknowledges that FILIPCOINs do not entitle their owners: </p>
                    <ul>
                      <li>to participate in any decision or vote within the Company;</li>
                      <li>to benefit from the Company’s profits or results of our efforts; or</li>
                       <li>to derive any economic or other gain from the Company.</li>
                    </ul>
                <p>The Buyer therefore acknowledges that they are fully aware that FILIPCOINs are in no way associated with ownership rights, voting rights or dividend rights, and that they do not constitute shares in the Company or in any other comparable asset. Consequently, this document does not constitute an offer or an invitation to sell shares, securities or rights belonging to the Company.</p>
            <h5><b>Not Electronic Currency nor a Replacement for Fiat Currency</b></h5>
                <p>While FILIPCOINs may be exchanged with other cryptocurrencies and eventually into fiat currency, we do not intend FILIPCOINs to serve as electronic currency or a replacement for fiat currency in any country or jurisdiction. FILIPCOINs are not accepted as legal tender or currency outside our ecosystem and do not have a fixed exchange value equal to the amount delivered at the time of its issue.</p>
            <h5><b>Not a Payment Service or Commodity Futures Contract</b></h5>
                 <p>The Token Sale does not involve the purchase/sale of FILIPCOINs for future delivery and our business does not consist in receiving currencies against the delivery of FILIPCOINs. As such, a FILIPCOIN is not a means of payment.</p>
                 <p>The FILIPCOIN is not intended for making futures contracts, or for the purpose of making contracts for the purchase and sale of FILIPCOINs for future delivery, but instead of making an actual delivery, only the margins based on the changes of its market value are paid. The trading of the FILIPCOINS is merely an incidental or a supplementary feature of owning FILIPCOINS. The creation of FILIPCOINS is driven primarily by our mission to revolutionize the service economy sector by developing applications that serve that sector using the Ethereum blockchain.</p>
            <h2>RISK DISCLOSURE</h2>
                <p>The Company does not recommend purchasing FILIPCOINs unless you have considerable knowledge and understanding and prior experience with cryptographic tokens, blockchain-based software and distributed ledger technology and unless you have taken independent professional advice from a legal or financial adviser.</p>
                <p>All the risks and uncertainties associated with FILIPCOIN should be evaluated before the purchase of any FILIPCOINs. If such risks and uncertainties unfold into actual events, our business, financial condition, results of operations and prospects could be materially and adversely affected. In such instances, you might lose all or some part of the value of the FILIPCOINs.</p>
            <h5><b>Risk Factors</b></h5>
                <p>You should carefully consider and evaluate each of the following risk factors before deciding to participate in the Token Sale. To the best of our knowledge and belief, all risk factors which are material to you in making an informed judgment to participate in the Token Sale have been set out below. If any of the following considerations develops into actual events, our business, financial position and/or results of operations and the maintenance and level of usage of FILIPCOINs could be materially and adversely affected.</p>
                
                  <table className="terms_table">
                    <thead>
                    <tr>
                    <th>Risk</th>
                    <th>Description</th> 
                    </tr>
                    </thead>
                    
                    <tbody>
                    <tr>
                    <th>Securities Regulation</th>
                    <td><p>It is difficult to predict how or whether regulatory authorities may implement any changes to laws and regulations affecting distributed ledger technology and its applications, including the Company’s platform, the anticipated future FILIPCOIN functionality, and/or the FILIPCOINs. There is a considerable risk that, despite our efforts to avoid creating a security, regulators may still characterize the purchase of FILIPCOINS as such.</p>
                        <p>Operations of our ecosystem and of FILIPCOINs may be impacted by the passing of restrictive laws, the publication of restrictive or negative opinions, the issuing of injunctions by national regulators, the initiation of regulatory actions or investigations, including but not limited to restrictions on the use or ownership of digital tokens such as FILIPCOINs. These legal and regulatory risks may prevent or limit development of our ecosystem and, therefore, impact the utility, liquidity, and/or value of FILIPCOINs.</p>
                        <p>Moreover, the legal and regulatory diligences necessary for the development of the projects outlined in the Whitepaper, such as the FILIPWORKS, FILIPAY, and AZERV in different jurisdictions have not yet been conducted, including the legal and regulatory diligences relating to specific areas of regulation. There is a risk that adverse laws or regulation in those jurisdictions might prevent or limit the development of our ecosystem and, therefore, impact the utility, liquidity, and/or value of the FILIPCOINs.</p> 
                        <p>The Company may also have to cease operations in a jurisdiction that makes it illegal to operate in such jurisdiction, or make it commercially unviable or undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction. In scenarios such as the foregoing, the utility, liquidity, and/or value of FILIPCOINs will be adversely affected.</p>         
                        <p>You are strongly advised to carry out a legal analysis concerning the purchase and ownership of FILIPCOINs according to your nationality and place of residence. </p>
                    </td>
                    </tr>
                    
                    <tr>
                    <th>Taxation</th>
                    <td><p>We expect all of the investments on offer through our platform to be subject to taxation (for example sales tax, income tax or capital gains tax). Any significant change in the taxation status of the investments on offer may reduce their attractiveness to certain investor types.</p>
                    <p>You are strongly advised to carry out a tax analysis concerning the purchase and ownership of FILIPCOINs according to your nationality and place of residence</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Money Laundering </th>
                    <td><p>As with any blockchain-based platform, FILIPCOIN has the potential to be used for money laundering. We intend to conduct and comply with anti-money laundering requirements</p>
                    </td>
                    </tr>
                     
                    <tr>
                    <th>Data Privacy Legislation</th>
                    <td><p>We will be required to hold personal and sensitive data belonging to our investors and business owners. There is a risk that a deliberate or accidental breach of any jurisdictions data privacy legislation could lead to damage to our reputation, loss of customer and potential regulatory action. We intend to comply with the data privacy act and its implementing rules and regulations.</p> 
                    </td>
                    </tr>

                    <tr>
                    <th>Reputation and Negative Publicity</th>
                    <td><p>Negative publicity involving the Company, the Company’s ecosystem, the FILIPCOINs or any of the key personnel of the Company and/or regulation of distributed ledger technologies, cryptocurrencies and/or crowdsales of tokens in any jurisdiction, may materially and adversely affect the market perception or value of the FILIPCOINs, whether or not it is justified.</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Technical Issues</th>
                    <td><p>The FILIPWORKS is already developed and functional. However, we intend to add functionalities and progressively integrate it into the Ethereum blockchain. The FILIPAY is already functional as well, but must first be registered with the BSP before being fully operational. </p>
                    <p>The development program involves solving a number of complex technical problems. There is a risk that unforeseen technical complications add to the estimated effort, leading to a delay in the go live date and/or additional costs</p>
                    <p>The Buyer accepts that the development or operationalization of these Projects is not guaranteed to succeed, that the Projects are subject to software and technical risks and that said items and features may never be deployed in our ecosystem,should unexpected technical obstacles be identified in the course of the product development. </p>
                    </td>
                    </tr>

                    <tr>
                    <th>Risk of a lack of interest in our ecosystem or distributed applications</th>
                    <td><p>The utility, liquidity, and/or value of, and demand for, the FILIPCOINs hinge on the performance of our ecosystem and the continuous active engagement of its users and success of its business. There is no assurance that the Company’s ecosystem will gain or continue to gain traction. While the Company has made every effort to provide a realistic estimate, there is also no assurance that the funds raised in the Token Sale will be sufficient for the development of the Company’s ecosystem and/or for the proper development, structuring and licensing of the anticipated additional functionalities and pipelined projects. For the foregoing or any other reason, the development of the Company’s ecosystem and operationalization of the anticipated additional functionalities and pipelined projects may not be fully realized and there is no assurance that these will be realized at all.</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Competitive action </th>
                    <td><p>There is a risk that one or more blockchain start-ups with similar missions or projects in the service economy sector could attempt to launch a rival offering leading to a loss in market share. </p>
                    </td>
                    </tr>

                    <tr>
                    <th>No assurance of an active or liquid market for FILIPCOINS</th>
                    <td><p>While FILIPCOINs should not be considered an investment, their value is bound to change over time since there is no fixed exchange value equal to the amount of FILIPCOINS delivered at the time of its issue. Although we will use reasonable endeavors to seek the registration and approval for availability of the FILIPCOINs for trading on a cryptocurrency exchange or market, there is no assurance that such approval will be obtained. Furthermore, even if such approval is granted by a cryptocurrency exchange, there is no assurance that an active or liquid trading market for the FILIPCOINs will develop.</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Fluctuation in value of FILIPCOINS</th>
                    <td><p>FILIPCOINs should not be considered an investment. However, to the extent that their value is bound to change over time, such value may be negatively affected if our ecosystem is not sufficiently developed, used or adopted. There is a possibility that the Company projects may not gain or continue to gain traction among users. Such a lack of interest could impact on the development of our ecosystem and, therefore, on the utility, liquidity, and/or value of FILIPCOINs</p>
                    <p>There is thus no assurance that the value of FILIPCOINs will not decline below the original purchase price. The purchase price is not at all indicative of the value of FILIPCOINs after they have been sold. The demand for, and the corresponding value of the FILIPCOINs may fluctuate significantly in response to the following factors: (a) new technological innovations; (b) personal recommendations, perceptions or estimates of the FILIPCOIN’s value or the Company’s financial and business performance; (c) changes in market valuations and FILIPCOIN prices of entities with businesses similar to that of the Company that may be listed on the same cryptocurrency exchanges or markets as the FILIPCOINs; (d) announcements by the Company of significant events, for example partnerships, sponsorships or new product developments; (e) fluctuations in values and trading volume of cryptocurrencies on cryptocurrency exchanges or markets; (f) additions or departures of key personnel of the Company; (g) success or failure of the Company’s management in implementing business and growth strategies; and/or (h) changes in conditions affecting the blockchain or financial technology industry, the general economic conditions or market sentiments, or other events or factors.</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Risk of theft and piracy</th>
                    <td><p>The Company will make every effort to ensure that the funds received from the Token Sale will be secure. However, there is no assurance that there will be no theft of the cryptocurrencies as a result of hacks, cyberattacks, distributed denials of service or errors, vulnerabilities or defects on the Token Sale website, or the Ethereum blockchain. In such cases, the launch of the Company’s business platform and the utility of the FILIPCOIN might be temporarily or permanently curtailed.</p>
                    </td>
                    </tr>
                    
                    <tr>
                    <th>Risk of malfunction or security weaknesses in our ecosystem</th>
                    <td><p>Our ecosystem may be impacted by an adverse malfunction including, but not limited to, a malfunction that results in the loss of FILIPCOINs or market information. There is a risk that the Company team, or other third parties, may intentionally or unintentionally introduce weaknesses or bugs into the core infrastructure elements of our ecosystem, by interfering with the use of or causing loss of FILIPCOINs. The Company may experience system failures, hardware or software defects, security breaches. In such cases, the launch of the Company’s business platform and the utility of the FILIPCOIN might be temporarily or permanently curtailed.</p>
                    </td>
                    </tr>
                    
                    <tr>
                    <th>Risk of an Ethereum mining attack</th>
                    <td><p>As with other decentralized cryptographic tokens and crypto-currencies, the Ethereum blockchain used for our ecosystem is vulnerable to mining attacks, including but not limited to, dual-expense attacks, powerful mining attacks, selfish mining attacks, and critical competition attacks. Any successful attack poses a risk to the Company software and the expected performance and sequencing of Ethereum contract calculations. Despite the best e orts of the Company team, the risk of known or new mining attacks exists.</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Risks associated with the Ethereum protocol</th>
                    <td><p>Both FILIPCOINs and our ecosystem are based on the Ethereum protocol. Therefore, any malfunction, unplanned function or unexpected operation of the Ethereum protocol may cause our ecosystem or FILIPCOINs to malfunction or operate in a way that is not expected. Ether, the native Ethereum Protocol account unit may itself lose value in a similar way to FILIPCOINs, and also in other ways. For more information on the Ethereum protocol, see http://www.ethereum.org</p>
                    </td>
                    </tr>

                    <tr>
                    <th>Risks associated with your credentials</th>
                    <td><p>In the context of your access to the Company’s ecosystem, you may be linked to the Company’s accounts. You can only access that account using your credentials. The loss of these credentials could result in the loss of the FILIPCOINs. You are therefore advised to store your credentials securely in one or more backup locations. </p>
                    <p>Any third party that obtains access to your credentials or private keys may be able to use your FILIPCOINs. To minimize this risk, you must protect itself against people gaining unauthorized access to their electronic devices.</p>
                    </td>
                    </tr>
                  
                    <tr>
                    <th>Key staff retention</th>
                    <td><p>As a technology start-up, we have a number of key staff that are considered critical to the ongoing success of the business. There is a risk that one or more of these staff could leave for a competitor or to start a rival project. This could lead to a delay in data going live, and/or additional costs. </p>
                    </td>
                    </tr>
                  
                    <tr>
                    <th>Unforeseen risks</th>
                    <td><p>Crypto-currencies and cryptographic tokens are a cutting-edge, untested technology. In addition to the risks stipulated above, there are other risks that the Company team cannot predict. Risks may also occur as unanticipated combinations or as changes in the risks stipulated herein.</p>
                    <p>In addition to the risks included in the above discussion of risk factors, there are other risks associated with the purchase, holding, and use of the FILIPCOINs, including those that the Company cannot anticipate. Such risks may further appear as unanticipated variations or combinations of the risks discussed above</p>
                    </td>
                    </tr>
                    
                    <tr>
                    <th>Risk of winding-up of the Company project</th>
                    <td><p>For a number of reasons including those identified here, the Company project may no longer be a viable activity and may be dissolved or simply not launched.</p>
                    </td>
                    </tr>
                </tbody>
                 </table>

         <h5><b>Acknowledgment and Express Assumption of Risks</b></h5>
             <p>BY PURCHASING FILIPCOINs, THE BUYER EXPRESSLY ACKNOWLEDGES AND ASSUMES THE RISK FACTORS OUTLINED ABOVE. </p>
             <p>In participating to the Token Sale, the Buyer is made aware of the above risks. Those risks are explicitly accepted by the Buyer when participating to the Token Sale. The Company expressly disclaims any liability for any direct or indirect loss or damage of any kind arising directly or indirectly from the realization of any of those risks. </p>
             <p>You, the Buyer, expressly acknowledge the risk factors associated with our ecosystem development project as presented in above and that this project may therefore not come to fruition as described or may have to be partially abandoned due to technical or financial constraints, without the FILIPCOINs being actively used in our ecosystem or used in a different context. In such a case, the Buyer expressly acknowledges and accepts as an essential condition of the T&C that it will not be entitled to sue or bring any direct or indirect legal action before the courts, either in the Republic of the Philippines or abroad, against the Company, or any current or former employees, officers, directors, partners, trustees, representative, agents, advisers, contractors, or volunteers of the Company in the event of the non-performance, nondeployment or non- implementation of our ecosystem, even in cases where your FILIPCOINs have lost some or all of their value. </p>
             <p>In addition, the Company may not be held liable for any of the following: </p>
             <ol style={{ listStyleType: "lower-alpha" }}>
               <li >use of services that are not compliant with these T&C;</li>
               <li>non-performance, failure, malfunction or unavailability of the services due to a third party, the Buyer, a third-party product, or your breach of its obligations;</li>
               <li>indirect damages such as business loss or disturbance, loss of orders, operating loss, infringement of trademark, loss of profits, disclosure or unlawful or fraudulent use of user credentials by you or third parties;</li>
               <li>suspension of access or temporary or permanent suspension of services (in particular,arising from a request issued by an appropriate administrative or judicial authority, or notification received from a third-party;</li>
               <li>loss, alteration or destruction of all or part of the content (information, data, applications, les or other items) hosted on the Site, insofar as the Company is not responsible for managing the continuity of client activities, and data back-ups in particular;</li>
               <li>mismatch between the services and your needs (in particular, with regard to the sensitivity of the relevant data), security incidents relating to use of the Internet, concerning in particular the loss, alteration, destruction, disclosure or unauthorized access to your data or details on or via the Internet;</li>
               <li>damage to systems, applications and other items installed by the Buyer on the infrastructure</li>
             </ol>
        <h2>SPECIFIC TERMS AND CONDITIONS </h2>
             <p>By adhering to these T&C, you expressly acknowledge having been thoroughly and comprehensively informed about the Token Sale. </p>
               <ul>
                  <li>You are deemed to be fully aware of all the legal norms and technical constraints relating to the purchase, possession and use of cryptocurrencies and tokens based on blockchain technologies, and to the services offered by the Company. </li>
                  <li>You agree to notify the Company in full concerning any information likely to impact on your order directly or indirectly. Should you fail to do this, the Company may not be held liable in any way in this regard. </li>
                   <p>An order relating to the Token Sale and accepted by The Company (the “Order”) is deemed to be irrevocably accepted by you as from the time the Company issues the Order’s acknowledgment of receipt. As of this date, an Order may not be cancelled or amended without the Company’s express agreement, the latter being entitled to decide not to proceed without the Buyer being entitled to any compensation. </p>
                   <p>The Company also reserves the right to rectify any clerical error or omission in the contractual documents binding it to the Buyer without entitling the Buyer to any compensation. </p>
                </ul>  
        <h5><b>Obligations Binding upon the Company</b></h5>
             <p>The Company agrees to take all the necessary care and diligence to provide a quality service in accordance with current business practice and state of the art. The Company undertakes to use all reasonable means to achieve the desired result, but without guaranteeing such result, and always subject to your express acknowledgement and assumption of risks as outlined in the previous section.</p>
        <h5><b>Buyer Eligibility </b></h5>
              <p>The Company does not recommend purchasing FILIPCOINs unless you have prior experience with cryptographic tokens, blockchain-based software and distributed ledger technology and unless you have taken independent professional advice.</p>
             <p>In order to be eligible to participate in the Token Sale, the Buyer must:</p>
             <ol style={{ listStyleType: "lower-alpha" }}>
               <li>be of legal age, with full contracting capacity, and not a Restricted Person as defined below;</li>
               <li>comply with all the terms and conditions set forth in these T&C;</li>
               <li>comply in good faith with the KYC procedure made available on the Token Sale’s website prior to the Token Sale;</li>
               <li>understand the restrictions and risks associated with the creation of FILIPCOINs as set forth herein, and acknowledge and assume all such risks;</li>
               <li>have obtained sufficient information about the FILIPCOINs and our ecosystem and projects to make an informed decision to purchase the FILIPCOIN.</li>
               <li>Participation in the Token Sale is reserved for natural or legal persons that are experienced in ICOs and, notably, that have already participated to at least one token generation event as a subscriber of tokens.</li>
             </ol>
        <h5><b>Regional Restrictions </b></h5>
             <p>Citizens, nationals, residents (for taxation purposes or otherwise) and/or green card holders of: (a) the USA; (b) South Korea; (c) the People’s Republic of China; (d) any other jurisdiction which prohibits participation in the Token Sale or the purchase of Tokens or any such similar activity or any other Restricted Persons are not permitted to participate in the Token Sale.</p>
             <p>The term “Restricted Persons” refers to any firm, company, partnership, trust, corporation, entity, government, state or agency of a state or any other incorporated or unincorporated body or association, association or partnership (whether or not having separate legal personality) that is established and/or lawfully existing under the laws of jurisdictions outlined in this section</p>
             <p>Documents linked to the issue of FILIPCOINs may not be transmitted or distributed, including by mail or e-mail to Restricted Persons. It is prohibited to transmit, distribute or reproduce documents linked to the issue of FILIPCOINs to or for Restricted Persons, in whole or in part. </p>
             <p>You are solely responsible for determining which legal or regulatory requirements of any nature is required to comply with in order to participate in the Token Sale, in accordance with the laws and regulations applicable to them. </p>
             <p>The Company may not be held liable for your legal or regulatory obligations. The same applies to any tax or charge that would be payable by you, in relation to the purchase, ownership, use or passing of its FILIPCOINs.</p>
        <h5><b>KYC/AML Requirements</b></h5>
             <p>Know your customer (KYC) is the process of identifying and verifying the identity of the Buyers. Although no applicable regulation provides for an obligation to implement KYC process for the Token Sale, we wish to ensure that the participants respect all the criteria defined in sections below for participating to the sale. </p>
             <p>As part of this KYC process, the Buyers shall provide us the information required under Republic Act No. 9160, otherwise known as the “Anti-Money Laundering Act of 2001”, as amended, and its Imprementing Rules and Regulations, prior to purchasing FILIPCOINs</p>
        <h5><b>No Advice</b></h5>
             <p>No part of our Whitepaper (including these T&C) and information provided on our website should be considered to be business, legal, financial or tax advice regarding the Company, FILIPCOINs, the Token Sale or any of the matters to which the statement relates. You should consult your legal, financial, tax or other professional advisor before taking part in the Token Sale.</p>
        <h5><b>Disclaimer of Liability</b></h5>
             <p>To the maximum extent permitted by the applicable laws, regulations and rules, we shall not be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection to your acceptance of or reliance on the Whitepaper,any part thereof, the information on our website, or any disclosure or marketing information conveyed by any of the incorporators or its marketing team. In no event shall the Company or any current or former employees, officers, directors, partners, trustees, representative, agents, advisers, contractors, or volunteers of the Company be liable for: </p>
              <ul>
                 <li>damages, arising out of your use or inability to use the services or the FILIPCOINS or the breach of any of these T&C by you or by any third party</li>
                 <li>any security risk such as hacker attacks, loss of password, loss of private key, or similar; </li>
                 <li>mistakes or errors in code, text, or images involved in the Token Sale;</li>
                 <li>any losses resulting from the volatility in the value of the FILIPCOINS in any country and on any exchange or market (regulated, unregulated, primary, secondary or otherwise); or</li>
                 <li>arising out of or in any way connected to your failure to properly secure any private key to a wallet containing Tokens.</li>
              </ul>
              <p>If applicable law does not allow all or any part of the above limitation of liability to apply to you, the limitations will apply to you only to the maximum extent permitted by applicable law.</p>       
        <h5><b>Lack of Representation and Warranties; Not Legal Advice</b></h5>
              <p>We do not claim any representation, warranty or undertaking in any form whatsoever to any entity or individual, including any representation, warranty or undertaking in relation to the truth, accuracy and completeness of any of the information presented in the Whitepaper, provided in our website, or any of the disclosures, representations, warranties, or advertisements of the incorporators, its marketing team, or third persons</p> 
              <p>We do not make and hereby disclaims, any representation, warranty or undertaking in any form to any entity or person, including any representation, warranty or undertaking in relation to the truth, accuracy and completeness of any of the information set out in our Whitepaper, provided on our website, or any of the disclosures, representations, warranties, or statements made in press releases or in any place accessible by the public and oral statements that may be made by incorporators, its marketing team, or third persons.</p>
              <p>Despite the fact that we have taken significant actions to be certain that the data in regard to the facts and statistics in the Whitepaper has been presented in its proper context, the Company has not carried out an independent examination of the data extracted from third party sources, verified the accuracy or completeness of such information. As a consequence, neither FILIPCOIN, nor any part of their team acting on their behalf submit any warranty as to the accuracy or completeness of such information and shall not be required to provide any updates on it. </p>
              <p>Some statements in our Whitepaper, provided on our website, statements made in press releases or in any place accessible by the public and oral statements that may be made by the incorporators, its marketing team, or third persons may include forward-looking statements which reflect the Company’s and its management’s current views with respect to financial performance, market situation and future plans. There may be important factors that could cause the actual results of the business to differ materially from those indicated in these statements. These forward-looking statements include statements as to FILIPCOIN’s and/or our revenue, prospects, future plans, expected industry changes and other matters discussed in this Whitepaper regarding us – they are to be perceived merely as predictions. </p>
              <p>Given that risks and uncertainties that may cause the actual future results, performance or accomplishments of FILIPCOIN may differ from that expected, expressed or implied by the future statements in this Whitepaper, no expectations should be placed on these statements. Nothing in this Whitepaper, any part thereof, the information on our website, or any disclosure or marketing information conveyed by any of the incorporators or its marketing team is or may be relied upon as an assurance, representation or undertaking as to the future performance or our policies. </p>
              <p>No information in this Whitepaper should be viewed as business, legal, financial or tax advice regarding FILIPCOIN, the Distributor, the FILIPCOINs or the FILIPCOIN Initial Token Sale. You should bear in mind that you may face the financial risk of any purchase of FILIPCOINs for an indefinable period of time. </p>     
              <p>Should you proceed with purchasing FILIPCOINs and the product fails to be suitable for the particular purpose as intended by you, we do not accept any liability for such unsuitability (among other things, we do not accept the return of, or refund to you the purchase price of the respective FILIPCOINs).</p>
        <h5><b>Your Representations & Undertakings </b></h5>
              <p>By acquiring FILIPCOINs you represent and warrant to the Company that each of the following statements is true and correct:</p>
                <ul>
                  <li>you have read and understand our Whitepaper;</li>
                  <li> you are over 18 (eighteen) years of age, and fully able and legally competent to acquire and use FILIPCOINs;</li>
                  <li>you are not a Restricted Person, that is, you are not (i) a citizen or resident of a geographic area in which access to or use of the Company’s services or the acceptance of delivery of the FILIPCOINs is prohibited by applicable law, decree, regulation, treaty, or administrative act, or (ii) a citizen or resident of, or located in, a geographic area that is subject to any sovereign country sanctions or embargoes;</li>
                  <li>you agree and acknowledge that FILIPCOINs do not constitute securities in any form, information provided in the Whitepaper and on our Website) does not constitute a prospectus or offer document of any sort and is not intended to constitute an offer of securities in any jurisdiction or a solicitation for investment in securities, and you are not obliged to enter into any contract or binding legal commitment whatsoever;</li>
                  <li>you agree and affirm that no regulatory authority has investigated or approved the information presented in this Whitepaper, no action has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction and the publication, distribution or propagation of this Whitepaper to you does not mean that the applicable laws, regulatory requirements or rules have been complied with;</li>
                  <li> you agree and affirm that in case you desire to buy any FILIPCOINs, the FILIPCOINs are not to be interpreted, labeled or considered to be: (i) any other sort of currency besides a cryptocurrency; (ii) debentures, stocks or shares emitted by any individual or entity; (iii) options, rights or derivatives; (iv) rights under any contract the aim of which is to secure a profit or evade a loss; (v) units in a collective investment enterprise; and/or (vi) any other security or class of securities;</li>
                  <li>you have at least a basic degree of understanding of the operation, functionality, usage, storage, transmission mechanisms and other material characteristics of cryptocurrencies, blockchain-based software systems, cryptocurrency wallets or other related token storage mechanisms, blockchain technology and smart contract technology;</li>
                  <li>you are purchasing FILIPCOIN/s for supporting the development of our project, being aware of the commercial and other kinds of associated risks, and you are not purchasing FILIPCOINs for any other purposes, including any investment or speculative purpose;</li>
                  <li>you agree and affirm that this Whitepaper, the undertaking and/or the completion of the FILIPCOIN Initial Token Sale, or future trading of the FILIPCOINs on any cryptocurrency exchange, shall not be interpreted or viewed by you as an indication of the merits of the FILIPCOIN Team, the FILIPCOINs or the FILIPCOIN Initial Token Sale;</li>
                  <li>the funds used for purchasing FILIPCOINs do not have criminal, otherwise illegal origin or source, and no transaction is made to facilitate any criminal, otherwise illegal activity;</li>
                  <li>the funds used for purchasing FILIPCOINs do not have criminal, otherwise illegal origin or source, and no transaction is made to facilitate any criminal, otherwise illegal activity;</li>
                  <li>you understand that you may not be able to sell or otherwise transfer FILIPCOINs due to potential lack of liquidity, including that FILIPCOINs may never become available for trade on secondary market (exchange) and/or that there may not be buyers who would be interested in acquiring FILIPCOINs from you;</li>
                  <li>you understand and agree that it is your obligation to ensure compliance with any legislation relevant to your country of domicile concerning purchase and use of FILIPCOINs, and the Company does not accept any liability for any illegal or unauthorized purchase or use of FILIPCOINs;</li>
                  <li>you agree and affirm that where any restrictions regarding the possession of this Whitepaper have been applied, you have examined and complied with all such restrictions at your own expense and without any liability to us; </li>   
                  <li>you understand and agree that the Company is not liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, or loss of use or data, or loss of reputation, or loss of any economic or other opportunity of whatsoever nature orsecurities in any jurisdiction or a solicitation for investment in securities, and you are not obliged to enter into any contract or binding legal commitment whatsoeve</li>
                  <li>you agree and affirm that no regulatory authority has investigated or approved the information presented in this Whitepaper, no action has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction and the publication, distribution or propagation of this Whitepaper to you does not mean that the applicable laws, regulatory requirements or rules have been complied with;</li>
                  <li>you agree and affirm that in case you desire to buy any FILIPCOINs, the FILIPCOINs are not to be interpreted, labeled or considered to be: (i) any other sort of currency besides a cryptocurrency; (ii) debentures, stocks or shares emitted by any individual or entity; (iii) options, rights or derivatives; (iv) rights under any contract the aim of which is to secure a profit or evade a loss; (v) units in a collective investment enterprise; and/or (vi) any other security or class of securities;</li>
                  <li>you have at least a basic degree of understanding of the operation, functionality, usage, storage, transmission mechanisms and other material characteristics of cryptocurrencies, blockchain-based software systems, cryptocurrency wallets or other related token storage mechanisms, blockchain technology and smart contract technology;</li>
                  <li>you are purchasing FILIPCOIN/s for supporting the development of our project, being aware of the commercial and other kinds of associated risks, and you are not purchasing FILIPCOINs for any other purposes, including any investment or speculative purpose;</li>
                  <li>you agree and affirm that this Whitepaper, the undertaking and/or the completion of the FILIPCOIN Initial Token Sale, or future trading of the FILIPCOINs on any cryptocurrency exchange, shall not be interpreted or viewed by you as an indication of the merits of the FILIPCOIN Team, the FILIPCOINs or the FILIPCOIN Initial Token Sale;</li>
                  <li>the funds used for purchasing FILIPCOINs do not have criminal, otherwise illegal origin or source, and no transaction is made to facilitate any criminal, otherwise illegal activity;</li>
                  <li>you understand that you may not be able to sell or otherwise transfer FILIPCOINs due to potential lack of liquidity, including that FILIPCOINs may never become available for trade on secondary market (exchange) and/or that there may not be buyers who would be interested in acquiring FILIPCOINs from you;</li>
                  <li>you understand and agree that it is your obligation to ensure compliance with any legislation relevant to your country of domicile concerning purchase and use of FILIPCOINs, and the Company does not accept any liability for any illegal or unauthorized purchase or use of FILIPCOINs;</li>
                  <li>you agree and affirm that where any restrictions regarding the possession of this Whitepaper have been applied, you have examined and complied with all such restrictions at your own expense and without any liability to us; </li>
                  <li>you understand and agree that the Company is not liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise including but not limited to loss of revenue, income or profits, or loss of use or data, or loss of reputation, or loss of any economic or other opportunity of whatsoever nature or howsoever arising), arising out of or otherwise in connection with causes beyond its control, including any hacker attack, technical failures, defects, bugs or viruses affecting our website or Ethereum platform (including any delay in receiving or failure to receive FILIPCOINs), circumstances related to your wallet service provider, your failure to follow this statement, any actions taken by regulatory authorities, etc.; </li>
                  <li>If you are registering to participate to the Operation on behalf of a legal entity, the Buyer further represent and warrant that (i) such legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization, and (ii) they Buyer is duly authorized by such legal entity to act on its behalf; and</li>
                  <li>all of the above representations and warranties are true, complete, accurate and not misleading from the time of your access to and/or acceptance of possession this Whitepaper or any part of it.</li>
                  </ul>  
              <p>You agree that if your country of residence or other circumstances change such that the above representations are no longer accurate, you will immediately cease using the Company’s services. </p>
          <h5><b>Restrictions on Distribution and Propagation </b></h5>
              <p>The propagation or distribution of this Whitepaper or any part of it may be forbidden or restricted by some jurisdictions. In the case where any restrictions of the sort have been applied, you are to comply with them, without any liability to FILIPCOIN Team.</p>
          <h5><b>Limitations of Use </b></h5>
              <p>You acknowledge and accept that FILIPCOINs do not, under any circumstance, represent any form of investment or financial investment and agree not to attempt to divert their function for speculative purposes. </p>
              <p>You also agree not to use the Token Sale website, the issued FILIPCOINs or, more generally, any content or service provided to you by the Company in a way that does not comply with the terms, objectives and methods set out in these T&C. </p>
              <p>In particular, you agree not to modify, interfere with, deactivate or saturate, nor to breach the security of or impair data integrity and confidentiality in relation to any service offered by the Company</p>
              <p>You agree to respond to any specific request for information issued by the Company under the application of these T&C</p>
          <h5><b>Cancellation and Refund </b></h5>
              <p>All orders are deemed firm and final. As the Token Sale is reserved to persons that are knowledgeable or experienced in ICOs, the Buyer may not claim any right of return against the Company. </p>
          <h5><b>No Guarantee </b></h5>
              <p>As the FILIPCOINs offered for sale are deemed intangible property, having no value or functionality other than for use in our ecosystem, no specific guarantee is attached to them following delivery.</p>
              <p>Although ownership of the FILIPCOINs depends on smooth operation of the Ethereum network, the Company, which has no control over said operation, may not, under any circumstances, be held liable for any failure of said network that could result in the Buyer losing or being unable to use the FILIPCOINs. </p>
          <h5><b>Validity of Tokens </b></h5>
              <p>The FILIPCOINs’ period of validity, during which they can be used, is not time-dependent. FILIPCOINs cannot be deleted due both to their strictly decentralized nature and to the fact that, once issued, they are no longer under the Company’s control. They would, in any case, remain the property of their owner. </p>
          <h5><b>Taxes </b></h5>  
              <p>Any amounts that you pay for FILIPCOINs are exclusive of all applicable taxes. You are responsible for determining what, if any, taxes that apply to your purchase of FILIPCOINs, including, for example, sales, use, value added, and similar taxes. It is your responsibility to withhold, collect, report and remit the correct taxes to the appropriate tax authorities. We are not responsible for withholding, collecting, reporting, or remitting any sales, use, value added, or similar tax arising from your purchase of FILIPCOINs. </p> 
          <h5><b>Buyer Liability </b></h5>
              <p>You acknowledge and accept that you are solely liable for storage and use of the purchased FILIPCOINs under normal conditions of use, and in accordance both with current laws at the time and place of use, which you hereby declare you are fully aware of. </p>
              <p>Accordingly, you shall be deemed personally liable for any damaging consequences arising from the abnormal, noncompliant or unforeseeable storage, transfer or use of said FILIPCOINS. You also acknowledge that you have received all the necessary details from the Company on the information and data we have communicated and is therefore solely liable for the ordered FILIPCOINs’ suitability in terms of their specific planned use. </p>
              <p>You agree to send the Company your correct and updated payment and personal details at the time of creating your client account and each time you may modify the said account. The Company reserves the right to request supporting documents from you to ensure the accuracy of the details they have supplied, and in compliance with the KYC requirements of the anti-money laundering laws and regulations.</p>
              <p>You will be solely and exclusively liable for the creation and conservation of the credentials required to sign into our ecosystem. We shall not be held liable for any illegal or fraudulent use of your credentials. The provision of credentials is deemed confidential. Any suspicion of intentional or unintentional disclosure of the credentials shall engage yoursole liability, excluding that of the Company. </p>
              <p>You will assume full responsibility for the consequences of any theft or misuse of FILIPCOINs acquired as a result of any use by any person to whom you have provided your credential(s). Similarly, you will assume full responsibility for the consequences of the loss of the abovementioned credentials(s).</p>
          <h5><b>Limitation of Liability </b></h5>
              <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW (I) IN NO EVENT WILL THE COMPANY, OR ANY CURRENT OR FORMER EMPLOYEES, OFFICERS, DIRECTORS, PARTNERS, TRUSTEES, REPRESENTATIVE, AGENTS, ADVISERS, CONTRACTORS, OR VOLUNTEERS OF THE COMPANY BE LIABLE FOR LOSS OF PROFITS OR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, WHERE RELATED TO LOSS OF REVENUE, INCOME OR PROFITS, LOSS OF USE OR DATA, OR DAMAGES FOR BUSINESS INTERRUPTION) ARISING OUT OF OR IN ANY WAY RELATED TO THE SALE OR USE OF THE FILIPCOINS OR OTHERWISE RELATED TO THESE T&C, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE), AND (II) IN NO EVENT WILL THE AGGREGATE LIABILITY OF COMPANY, OR ANY CURRENT OR FORMER EMPLOYEES, OFFICERS, DIRECTORS, PARTNERS, TRUSTEES, REPRESENTATIVE, AGENTS, ADVISERS, CONTRACTORS, OR VOLUNTEERS OF THE COMPANY (JOINTLY), WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER THEORY, ARISING OUT OF OR RELATING TO THESE T&C OR THE USE OF OR INABILITY TO USE THE FILIPCOINS, EXCEED THE PHILIPPINE DOLLAR EQUIVALENT OF THE AMOUNT PAID BY YOU TO COMPANY FOR THE FILIPCOINS AT THE TIME OF PURCHASE. THE LIMITATIONS SET FORTH IN THIS SECTION WILL NEITHER LIMIT NOR EXCLUDE LIABILITY FOR THE GROSS NEGLIGENCE, FRAUD OR INTENTIONAL, WILLFUL OR RECKLESS MISCONDUCT OF THE COMPANY, TO THE EXTENT THAT THEY ARE NOT ALLOWED UNDER PHILIPPINE LAWS. </p>
              <p>None of the information or analyses described in these T&C are intended to provide a basis for a decision to participate in the Token Sale, and no specific recommendation was or is being made. Accordingly, these T&C does not constitute an advice or an invitation to contribute in any security or financial instrument of any nature whatsoever. </p>
              <p>This document does not constitute or form part of, and should not be construed as, an offer for a sale or subscription, or an invitation to buy or subscribe securities or financial instruments. This document, or any of its component parts, does not constitute the basis for, or should not be used as a basis for, or in connection with, a contract for the sale of securities or financial instruments or a commitment to sell securities or financial instruments of any kind.  </p>
              <p>The Company expressly disclaims any liability for any direct or indirect loss or damage of any kind arising directly or indirectly from: (i) any reliance on the information contained in this document, (ii) any error, omission or inaccuracy in said information, or (iii) any resulting action that may be brought.</p>
          <h5><b>Retention of Ownership Clause </b></h5>
              <p>The Company retains full ownership of the ordered FILIPCOINs up to full and effective payment of the price agreed with you, including the principal and other charges, any contrary clause being deemed unwritten. As such, the Company will be entitled to claim the ordered FILIPCOINs as compensation for any unpaid invoices with no prejudice to its right to rescind any sales in progress, with you also agreeing to immediately notify the Company of any third-party development that would infringe the Company’s right of ownership. Should the ordered FILIPCOINs be resold before payment is complete, the sale between the Company and you will be automatically rescinded and the assets transferred deemed to have been sold on behalf of the Company. The enforcement of the Company’s right to claim is carried out without prejudice to any other legal and/or contractual damages or rights and is made by notice sent by registered mail or email to you. </p>
          <h5><b>Intellectual Property </b></h5> 
              <p>You acknowledge that the Company retains sole and exclusive ownership of all intellectual, industrial and expertise rights (documents, data, etc.) relating to FILIPCOINs and our ecosystem The technical and technological resources and expertise used to design both FILIPCOINs and documents of any nature shall remain the exclusive property of the Company. </p>
          <h5><b>Contract Completeness, Waiver, Interpretation </b></h5>
              <p>These T&C constitute the entire agreement between you and the Company with respect to the Token Sale and supersedes all other oral and written representations, understandings or agreements relating thereto.</p>
              <p>Should any provision of these T&C be declared null and void or deemed unwritten, all other provisions shall remain in full force and effect. The Company’s decision not to avail itself of any one of these provisions shall not be construed as a waiver of its right to apply the same provision at a later date. The interpretation and assessment of the validity of any contract is understood in accordance with the following documents, in descending order in the hierarchy of norms: T&C, order acknowledgment of receipt, invoice, and delivery notification. </p>
          <h5><b>Force Majeure </b></h5>
              <p>Force majeure is deemed any event beyond the parties’ control, which they cannot reasonably foresee or reasonably avoid or overcome, provided that its occurrence makes it impossible to fulfill the obligations and adversely affects Order execution (e.g. natural cataclysm, variation in blockchain fees, armed conflicts, labor disputes, changes in regulations, subcontractor failure, blockchain breakdown, etc.). The most diligent Party shall promptly notify the other Party by any means, and the Parties will then agree to negotiate in good faith any changes required to ensure the continuity of contract obligations. If, however, such impossibility exceeds three months, the most diligent Party may terminate the contract in writing without incurring its liability and  without entitling the other party to claim any right of recourse or compensation, with the Company retaining previously collected amounts, which are irrevocably acquired. The Parties shall not be held liable for any consequences of a force majeure event as defined above. </p>
          <h5><b>Change of Law </b></h5>
              <p>You acknowledge and accept that the Token Sale is taking place within a legal environment that is still under development. New laws or rules may subsequently frame, modify or clarify the practice of such operations. Where necessary, should legislative changes conflict with all or part of these T&C, the Company reserves the right to amend the terms of the operation as appropriate, retroactively if necessary, in order to ensure that the operation remains legal and compliant with the various regulatory bodies. </p>
              <p>The Company will answer to any request issued via regular legal process aimed at obtaining specific information about the Token Sale or its clients, specifically regarding anti-money laundering legislations.  </p>
          <h5><b>Law Applicable; Dispute Resolution</b></h5>
              <p>These T&C and any contractual relationship relating to the products and services sold by the Company are governed exclusively by the laws of the Republic of the Philippines. </p>
              <p>Future Translations of the T&Cs which may be made available to you in the future are purely informative and are not legally binding. The English version of these terms and conditions has sole legal force.</p>
              <p>You and the Company agree to seek an amicable settlement prior to bringing any legal action. Failing this, any dispute, of any nature whatsoever, will be brought exclusively before the courts of Muntinlupa City, Philippines.</p>

               </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
