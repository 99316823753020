/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { toastAlert } from "../../actions/toastAlert";
import { postMethod } from "../../actions/actions";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import config from "../../actions/config";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const url = config.baseUrl;
  const [email, setemail] = React.useState('');
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  async function emailchange(email) {
    setemail(email);
  }

  async function subscribe(){
    if(email!="" && typeof email!="undefined"){
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if ((email).match(validRegex)) {
        let data = {
          "email":email
        }
        var res = await postMethod(url + "api/subscribe", data);
        if (res.data && res.data.errormsg) {
          toastAlert('success', res.data.errormsg, 'success')
        }
        if (res.data && res.data.message) {
          toastAlert('success', res.data.message, 'success')
        }
      }else{
        toastAlert('error',"Invalid Email", 'error')
      }
    }else{
      toastAlert('error',"Enter Email to Subscribe", 'error')
    }
  }

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className="footer_top">
          <GridContainer>
            <GridItem sm={6} md={3} lg={2}>   
              <h3 className="text-left">About</h3>
              <ul className="footer_menu">
                <li><a href="/about">About FILIPCOIN</a></li>
                <li><a href="/tokenomics#bep20">BEP-20 Contract Address</a></li>
                <li><a href="/tokenomics#erc20">ERC-20 Contract Address</a></li>
                <li><a href="/about#leadership_team">Leadership Team</a></li>
                <li><a href="/about#our_awards">Awards</a></li>
                <li><a href="/about#features">Features</a></li>
                <li><a href="/about#our_partners">Our Partners</a></li>
              </ul>
            </GridItem>
            <GridItem sm={6} md={3} lg={2}>
              <h3 className="text-left">Token</h3>
              <ul className="footer_menu">
              {localStorage.getItem('jwtToken') ? 
                <li><a href="/buy-token/eth">Buy FCP with ETH</a></li>
                :
                <li><a href="/token">Buy FCP with ETH</a></li>
              }
               {localStorage.getItem('jwtToken') ? 
                <li><a href="/buy-token/bnb">Buy FCP with BNB</a></li>
                :
                <li><a href="/token">Buy FCP with BNB</a></li>
              }
                <li><a href="/">Decentralized Exchange</a></li>
                <li><a href="/">Centralized Exchange</a></li>
                <li><a href="/token#price_tracking">Price Tracking</a></li>
              </ul>
            </GridItem>
            <GridItem sm={6} md={3} lg={2}>
              <h3 className="text-left">Products</h3>
              <ul className="footer_menu">
                <li><a href="/products">FILIPCOIN Wallet</a></li>
                <li><a href="/products#filipay_prod">FILIPAY App</a></li>
                <li><a href="/products#filipswap_prod">FILIPSWAP</a></li>
                <li><a href="/products#cols_prod">COLS</a></li>
                <li><a href="/">Referral Program</a></li>
              </ul>
            </GridItem>
            <GridItem sm={6} md={3} lg={2}>            
              <ul className="footer_menu footer_menu_large">
                <li><a href="/home">Home</a></li>
                <li><a href="/faq">FAQ</a></li>
                <li><a href="/">ANNOUNCEMENT</a></li>
                <li><a href="/login">LOGIN</a></li>
              </ul>
            </GridItem>
            <GridItem sm={6} md={6} lg={4} className="text-left">             
              <div className="inp_grp">
                <input type="email" className="subscribe_inp" value={email} onChange={(e) => { emailchange(e.target.value) }}/>
                <button className="subscribe_btn" type="button" onClick={()=>subscribe()}>SUBSCRIBE</button>
              </div>
              <ul className="social_links">
                <li><a href="https://www.facebook.com/groups/filipcoin" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.facebook.com/filipcoinfcp" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/filipcoinfcp/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://twitter.com/filipcoinfcp" target="_blank"><i className="fab fa-twitter"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UC4_t-U8vWCZgnhr7e4FYL3A" target="_blank"><i class="fab fa-youtube"></i></a></li>
                <li><a href="https://t.me/filipcoinfilipchannel" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>                
                <li><a href="https://t.me/filipcoinfilip" target="_blank"><i className="far fa-comments"></i></a></li>                
                {/* <li><a href="https://filipcoin.medium.com/" target="_blank"><i className="fab fa-medium-m"></i></a></li> */}
                
              </ul>
            </GridItem>
          </GridContainer>
        </div>
        <div className="footer_bottom">
          <div className="copyright_txt mt-2">
            Copyright &copy; {1900 + new Date().getYear()}{" "}
            <Link to="/" className={aClasses}>Filipcoin.</Link> All Rights Reserved
          </div>
          {/* <div className="terms_link">
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/terms-conditions" className={classes.block}>Terms & Conditions</Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/privacy-policy" className={classes.block}>Privacy Policy</Link>
              </ListItem>
            </List>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
