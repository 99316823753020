import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinksafter_inner from "components/Header/HeaderLinksAfterLogin.js";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import config from "../actions/config";

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #b78c50;
    font-size: 32px;
  }
  & > .minus {
    display: none;
    color: #b78c50;
    font-size: 32px;
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;


const dashboardRoutes = [];

export default function Faq(props) {

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const url=config.baseUrl;
  const { ...rest } = props;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  useEffect(()  => {
     getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }


  return (
    <div className="inner_pages">
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksafter_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}

      <div className="inner_pageheader">
        {/* Faq Section */}
        <div className="faq_section">
          <div className="container"> 
          <GridContainer>
                <GridItem sm={12} md={12} lg={12}>
                  <h2 className="inner_subhead text-center">Explain more about our platform</h2>
                  <h1 className="inner_title text-center">Frequently Asked Questions</h1>
                  <p className="padding_txt text-center">Below we’ve provided a bit of information of Our Token and the its Project. If you have any other questions, please get in touch with us.</p>
                </GridItem>
              </GridContainer>
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={8} className="m-auto">
                  <h1 className="faq_inner_title text-center">About Filipcoin</h1>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                      <h2 className="accordian_head">What is the FILIPCOIN Token?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The FILIPCOIN Token is a crypto-currency built using the Ethereum protocol (ERC20) and Decentralized Ledger Technology. Our goal is to develop and innovate our own blockchain technology which focuses on the decentralized finance, a system of service economy application that will provide practical solutions for our users, investors, and developer partners.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                      <h2 className="accordian_head">How do I find local FILIPCOIN Token enthusiasts and traders?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                     <p className="accordian_para">Please refer to our Twitter, Facebook, and Instagram pages. For more in-depth discussions, check out our Telegram. Links provided below:</p>
                      <p className="accordian_para">Twitter: <a href="https://twitter.com/filipcoinfcp" target="_blank">https://twitter.com/filipcoinfcp</a></p>
                      <p className="accordian_para">Facebook:</p>
                      <p className="accordian_para">FB Group Page: <a href="https://www.facebook.com/filipcoinfcp" target="_blank">https://www.facebook.com/filipcoinfcp</a></p>
                      <p className="accordian_para">FB Like Page: <a href="https://www.facebook.com/filipcoinfcp" target="_blank">https://www.facebook.com/filipcoinfcp</a></p>
                      <p className="accordian_para">Instagram: <a href="https://www.instagram.com/filipcoinfcp/" target="_blank">https://www.instagram.com/filipcoinfcp/</a></p>
                      <p className="accordian_para">Telegram:</p>
                      <p className="accordian_para">Telegram Channel: <a href="https://t.me/filipcoinfilipchannel" target="_blank">https://t.me/filipcoinfilipchannel</a></p>
                      <p className="accordian_para">FILIP Telegram Group Chat: <a href="https://t.me/filipcoinfilip" target="_blank">https://t.me/filipcoinfilip</a></p>
                     </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                      <h2 className="accordian_head">When does FILIPCOIN Token launch?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Please check our website and social media for the latest news and updates on our roadmap and timeline. We are organizing public sale later this year.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel4bh-content" id="panel4bh-header">
                      <h2 className="accordian_head">What can I do with the FILIPCOIN Token?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The FILIPCOIN Token will immediately be available as a medium of exchange within the FILIPCOIN Network for various FILIPCOIN goods and services, including those of FILIPCOIN’s partners. The token will also be usable within FILIPCOIN Transportation Industry. Our long-term goal is to provide as many uses as possible, so stay tuned for updates!</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel5bh-content" id="panel5bh-header">
                      <h2 className="accordian_head">How is FILIPCOIN Token funded?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">FILIPCOIN Token is funded by the shareholders of Service Economy Applications Inc. (SEAI) and other Angel Investors. We also allocate a portion of the sales of the tokens to the operations of FILIPCOIN Token. Please see FILIPCOIN Whitepaper for detailed information on allocation of token sales.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel6'} onChange={handleChangeFaq('panel6')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel6bh-content" id="panel6bh-header">
                      <h2 className="accordian_head">Who is the FILIPCOIN Token team?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The conception of FILIPCOIN and the possibilities it will offer, have been strongly supported by the founding members, the development team, industry experts, early backers and investors, lawyers, and professional consultants of Service Economy Applications Inc (SEAI).</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel7'} onChange={handleChangeFaq('panel7')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel7bh-content" id="panel7bh-header">
                      <h2 className="accordian_head">Can you divide the FILIPCOIN Token in smaller units (such as Satoshis for Bitcoin)?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">One unit of the FILIPCOIN token is logically equivalent to the 1 satoshi. In other words you can't divide the FILIPCOIN token in smaller units. We believe that this is more convenient for the users than to set the token's value to X number, and then have users possess some small fraction of it.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel8'} onChange={handleChangeFaq('panel8')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel8bh-content" id="panel8bh-header">
                      <h2 className="accordian_head">How will FILIPCOIN Token be created?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The FILIPCOIN Token will be created by Service Economy Applications Inc professional developers and senior software engineers, limited and allocated to wallets of buyers.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel9'} onChange={handleChangeFaq('panel9')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel9bh-content" id="panel9bh-header">
                      <h2 className="accordian_head">How do I buy the FILIPCOIN Token?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Tokens can be bought in pre-sale and public sale using standard cryptocurrencies: Bitcoin and Ethereum. By purchasing the tokens in pre-sale, you’re getting a voucher for the amount of tokens you’ve bought. You can later claim them when the FILIPCOIN Token goes live.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel10'} onChange={handleChangeFaq('panel10')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel10bh-content" id="panel10bh-header">
                      <h2 className="accordian_head">Will there be a FILIPCOIN Token Wallet?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Yes! We already have a wallet for Android and IOS that is in the final stages of testing, we will be announcing it once it has been audited and we know it is fully secure.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel11'} onChange={handleChangeFaq('panel11')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel11bh-content" id="panel11bh-header">
                      <h2 className="accordian_head">What is the overall trend in crypto?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Cryptocurrencies and the technology that drives it is still in the early days. Every day we continue to see more individuals, companies, and countries pivot towards the industry to empower themselves in ways not possible without crypto. The overall trend continues to grow.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel12'} onChange={handleChangeFaq('panel12')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel12bh-content" id="panel12bh-header">
                      <h2 className="accordian_head">Why is the FILIPCOIN Token launching so much later than announced?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">FILIPCOIN had an implementation and launching of FILIPAY early 2018 to 2019. It took FILIPCOIN until November 2020 to get the development back and be able to launch the FILIPCOIN Token with new partner developers.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel13'} onChange={handleChangeFaq('panel13')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel13bh-content" id="panel13bh-header">
                      <h2 className="accordian_head">Is there a version of the wallet for Android, or iOS?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">We are currently in the final test phase for our Android and IOS App, new platforms will be announced via our channels.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel14'} onChange={handleChangeFaq('panel14')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel14bh-content" id="panel14bh-header">
                      <h2 className="accordian_head">How can we determine the number FILIPCOIN Token in circulation?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">That number cannot be determined until after the public sale.</p>
                    </AccordionDetails>
                  </Accordion>

                  {/* About Cryptocurrency */}
                  <h1 className="faq_inner_title mt-5 text-center">About Cryptocurrency</h1>
                  <Accordion expanded={expanded === 'panel1_crypto'} onChange={handleChangeFaq('panel1_crypto')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel1_crypto-bh-content" id="panel1_crypto-bh-header">
                      <h2 className="accordian_head">What is a crypto-currency?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Cryptocurrency is a type of digital currency that uses cryptography for its security.</p>
                      <ul className="text-left">
                        <li>Their value cannot be influenced by producing more coins, because the production is secured by cryptography.</li>
                        <li>They are usually decentralized and do not require a central governance, making them safer and more reliable.</li>
                        <li>They allow the user to be in full control of his or her funds, without the involvement of third parties.</li>
                        <li>They can be used for more specific purposes, such as for smart contracts or stores of value for items.</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel2_crypto'} onChange={handleChangeFaq('panel2_crypto')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel2_crypto-bh-content" id="panel2_crypto-bh-header">
                      <h2 className="accordian_head">What are the main crypto-currencies in the world?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The first and the most popular cryptocurrency in the world is bitcoin. It made a breakthrough by its revolutionary concept of decentralized protocol in which transactions are processed by computers all around the world, without the involvement of any third party. Other cryptocurrencies are developed using the similar design, each having its own twist or a specific feature that drives it. Some of the most popular ones are: Ethereum, Litecoin and Ripple.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel3_crypto'} onChange={handleChangeFaq('panel3_crypto')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel3_crypto-bh-content" id="panel3_crypto-bh-header">
                      <h2 className="accordian_head">Where can I find more information about crypto-currencies?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">There are many websites, the most popular ones being CoinMarketCap.com, CoinBase.com, Markets.BusinessInsider.com</p>
                    </AccordionDetails>
                  </Accordion>

                  {/* About Company */}
                  <h1 className="faq_inner_title mt-5 text-center">About The Company</h1>
                  <Accordion expanded={expanded === 'panel1_company'} onChange={handleChangeFaq('panel1_company')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel1_company-bh-content" id="panel1_company-bh-header">
                      <h2 className="accordian_head">What is the name of the company?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">The company name is SERVICE ECONOMY APPLICATIONS INC. (SEAI)</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel2_company'} onChange={handleChangeFaq('panel2_company')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel2_company-bh-content" id="panel2_company-bh-header">
                      <h2 className="accordian_head">Where is the legal registration of the company?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">SERVICE ECONOMY APPLICATIONS INC. registered under the Securities and Exchange Commission (SEC) with Company Reg. No.: CS201804315 on March 27, 2018.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel3_company'} onChange={handleChangeFaq('panel3_company')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel3_company-bh-content" id="panel3_company-bh-header">
                      <h2 className="accordian_head">What is the company's mission?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">FILIP’s mission is to create social capital platform for service economy applications integrating blockchain technology to utilize and strengthen decentralized transaction protocol system to serve and address the interests of all of its participants locally and globally.</p>
                      <p className="accordian_para">This approach is the first ever system that offers a balance between autonomy, cost efficiency, and risk. This will allow Partner Developers to focus on the development of relevant applications addressing the customers’ goals. A customer-centric approach driven by customer needs and preferences, but will however, maintain the decentralized approach with respect to the payment protocol system to maintain reliability, speed, lower transaction fee, mobility and enhanced security.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel4_company'} onChange={handleChangeFaq('panel4_company')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel4_company-bh-content" id="panel4_company-bh-header">
                      <h2 className="accordian_head">What is the company’s vision?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">FILIP envisions to be one of the pioneers of cryptocurrency in the Philippines to implement DeFi, as well as in Asia, and drive the country to the global financial digitization. We envision to be the catalyst of financial technology facilitating the growth of our partners, investors, and the rest of our stakeholders.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5_company'} onChange={handleChangeFaq('panel5_company')}>
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel5_company-bh-content" id="panel5_company-bh-header">
                      <h2 className="accordian_head">What are the company’s objectives?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">FILIP is an organization that will employ a decentralized digital currency on the ETH code.  The objective is to supplement the development of the technological projects provided by our partner developer companies.</p>
                      <p className="accordian_para">It will be responsible for the crypto currency assets, development of coin, and the continuous updates of future products and services under the FILIPCOIN blockchain. Funds received by the FILIP organization will be utilized to further improve the FILIPCOIN in order to increase its value with proper integration on service economic application projects.</p>
                      <p className="accordian_para">Here are our objectives:</p>
                      <ul className="text-left">
                        <li>Provide smooth and secure transactions between apps, merchants and users</li>
                        <li>Provide the possibility of transferring value between users & merchants</li>
                        <li>Make the process of exchanging/splitting of virtual currencies</li>
                        <li>Payment gateway collaboration with different industries</li>
                        <li>Create & provide mass payment facility to fulfill community needs</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel6_company'} onChange={handleChangeFaq('panel6_company')} className="mb-5">
                    <AccordionSummary expandIcon={<Icon />} aria-controls="panel6_company-bh-content" id="panel6_company-bh-header">
                      <h2 className="accordian_head">Is there a Bangko Sentral ng Pilipinas (BSP) license to the company?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">While some providers aim to avoid regulation, we believe it is important to offer a fully regulated platform, and therefore we are committed to working with regulators. We understand that FILIPAY/FILIPCOIN may come within the regulatory requirements of the central banks such as the Bangko Sentral ng Pilipinas (“BSP”), since the apps will include functions relating to the delivery of financial services, particularly, for payments and remittances, which have material impact on anti-money laundering (“AML”) and combating the financing of terrorism (“CFT”), consumer protection and financial stability. We are currently preparing our application for registration with the BSP. We intend to comply with the requirements of the relevant regulatory agencies in other jurisdictions where we will operate in the future.</p>
                    </AccordionDetails>
                  </Accordion>

                </GridItem>
              </GridContainer>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
