import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Products(props) {

  const { ...rest } = props;
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  var   {  withdrawid } = useParams();
  
  useEffect(()  => {
     getuserid();
  },[])

async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

async function  isconfirm(){
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
      }
    }else{
      localStorage.clear();
    }
  }
  return (
    <div className="inner_pages">
      <ScrollToTopOnMount />
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}
      

      <div className="inner_pageheader term_inner">

        <section name="fcp_wallet" className="fcp_wallet_section dark_bg dark_bg_gradient section text-center" id="fcp_wallet">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">Products</h2>
                <h1 className="inner_title">FILIPCOIN WALLET</h1>
                <p className="mb-5 mt-3">FILIPCOIN (FCP) facilitates adoption to digital currency trend. Send and receive FCP anywhere in the world, exchange tokens under ETH and BNB, and PAY FARE IN MOST MODE OF TRANSPORTATION ANYWHERE.</p>
                <div className="text-center"><img src={require("../assets/images/mobile_img_01.png")} alt="Icon"  className="img-fluid"/></div>
              </GridItem>
            </GridContainer> 
          </div>
        </section>

        <section className="mobileapp_section section text-center" id="filipay_prod">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">FILIPAY</h2>
                <h1 className="inner_title">Commuting Made Easy</h1>
                <h4 className="padding_txt"><b>Download the FILIPAY App for fare payment via QR Code.</b></h4>
              </GridItem>
            </GridContainer>
            <GridContainer className="align-items-center">
              <GridItem sm={8} md={8} lg={7} data-aos="fade-up" data-aos-duration="2000" className="m-auto">
                <img src={require("../assets/images/mobile_img_02.png")} alt="Mobile App" className="img-fluid" />
              </GridItem>
              <GridItem sm={12} md={12} lg={5}>
                <ul className="mobileapp_list">
                  <li data-aos="fade-up" data-aos-duration="2000">Automatic Fare Collection</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">Pay Ahead that Maximizes Payment Mobility</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">Accessible, Reliable and Convenient</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">Digital Currency as Reward Points</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">Highly Secured E-Wallet</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">Send and Receive Peso Digital Currency</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200">Collaborative Rebates</li>
                  <li data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1400">Loading Gateways</li>
                </ul>                  
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1700">
                <p className="padding_txt">FILIPAY facilitates automatic fare collection to all Public Utility Vehicles, adoption to digital currency trend, send and receive money/credits anywhere in the world, get rebates from your favorite shopping sites or restaurants, pay ahead with your bills, gasoline, foods, travels and many more.</p>
                <p>Visit <a href="https://filipay.com.ph/" target="_blank">FILIPAY Website</a> for more information.</p>
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <section className="filipswap_prod_section dark_bg dark_bg_gradient section text-center" id="filipswap_prod">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">Products</h2>
                <h1 className="inner_title">FILIPSWAP</h1>
                <h3 className="padding_txt"><b>A decentralized exchange utilizing the FCP Blockchain. Anyone with a smartphone and an internet connection can trade via FILIPSWAP. </b></h3>
                <img src={require("../assets/images/coming_txt.png")} alt="Filipcoin" className="img-fluid coming_txt" />
              </GridItem>
            </GridContainer> 
          </div>
        </section>

        <section className="cols_prod_section section text-center" id="cols_prod">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                <h2 className="inner_subhead">Products</h2>
                <h1 className="inner_title">COLS</h1>
                <h3 className="padding_txt">Learn to trade with Crypto Online Learning System.</h3>
                <img src={require("../assets/images/cols_img.png")} alt="Filipcoin" className="img-fluid" />
                <img src={require("../assets/images/coming_txt.png")} alt="Filipcoin" className="img-fluid coming_txt" />
              </GridItem>
            </GridContainer> 
          </div>
        </section>


        <Footer/>
      </div>
    </div>
  );
}
