import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinksafter_inner from "components/Header/HeaderLinksAfterLogin.js";
import { Link } from "react-router-dom";
import config from "../actions/config";

const dashboardRoutes = [];

export default function Team(props) {
  
  const url=config.baseUrl;
  const { ...rest } = props;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  useEffect(()  => {
     getuserid();
  },[])

  async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

  return (
    <div className="inner_pages">
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinksafter_inner />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">Our Team</Link></li>
              </ul>
              <h1 className="inner_title">The Leadership Team</h1>
            </GridItem>
          </GridContainer>
          <GridContainer className="align-items-center">
            <GridItem sm={12} md={12} lg={12} className="mt-3">
              <div className="inner_page_panel">
                <div className="team_panel">
                  <img src={require("../assets/images/team_01.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Janice Arino</h2>
                    <p>CEO/President</p>
                    <p className="mt-3">Service Economy Applications Inc. with its flagship product, FILIPAY, an Automated Fare Collection System (AFCS) authorized by the Department of Transportation for the government's Public Utility Vehicle Modernization (PUVM) drive, is headed by its CEO/Chairman, Janice Ariño.</p>
                    <p>Janice, who hailed from Bicol Province, is a teacher by profession. She then moved to Los Baños where she started her small entrepreneurial journey while also pursuing a Master of Management in Business Management Major in Finance at the University of the Philippines Los Baños.</p>
                    <p>However, as cliché, as it may seem, faith had a different plan. Her path isn't without struggles and challenges but her passion, determination, and patience to see through all of life's hardships gave her the much needed resilience and spirit in gathering trusted individuals that helped her establish her own company which ultimately was registered under the Securities and Exchange Commission and is fast becoming a preferred partner by different transport cooperatives through FILIPAY, nationwide.</p>
                    <p>She is a testament to what qualities a real leader should have despite the limitations. Working and learning while continuously building relationships had helped her gain the trust of our corporate partners, clients, and stakeholders making FILIPAY one of the first few AFCS who was able to install and deploy modern jeepneys in the roads of Metro Manila, Philippines.</p>
                    <p>To support the project with the government's drive for future cashless payment system, the FILIPCOIN will pursue its vision to push forward the integration of Blockchain Technology with the company's existing project in FILIPAY - as the mobile platform for the Transportation Industry.</p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/janice-ari%C3%B1o/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      <li><a href="https://www.facebook.com/jhazsaying" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                      <li><a href="https://twitter.com/janicearino" target="_blank"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.instagram.com/janicearino/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                  </div>
                </div>

                <div className="team_panel">
                  <img src={require("../assets/images/team_05.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Jonas Arino</h2>
                    <p>Chief Operation Officer</p>
                    <p className="mt-3">With 20 years’ experience in service at Social Security System in the Philippine government as the Corporate Executive Officer I / II who ensures strict compliance with the system policies and internal control procedures while maintaining the highest level of quality service. </p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/jonas-arino-8442481aa/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div>

                 <div className="team_panel">
                  <img src={require("../assets/images/2img.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Norlito Jacob</h2>
                    <p>InfoSec & IT Engineer</p>
                    <p className="mt-3">A consistent team leader who always tends to "aim high" in terms of goals and vision. With this characteristics,he is well-known in IT development, SEO Principle and project management.He is also a security enthusiast with deep understanding when it comes to Cybersecurity and Information Security topics and ideas. Serving as the main point of contact for investigating and resolving security-related issues. </p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/jacob-norlito-70669b165/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div>

               {/* <div className="team_panel">
                  <img src={require("../assets/images/team_02.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Sanjay Shrestha</h2>
                    <p>Senior Software Engineer</p>
                    <p className="mt-3">Experienced and proficient software engineer with a focus on web and mobile development, technical project management. Detail and goal-oriented, enthusiastic team player.</p>
                    <p>A 'go-to' person with a concentration on staying ahead of the curve and leveraging emerging technologies to build brand market share. The best part of my career; it's a lifelong learning commitment.</p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/sanjay-shrestha/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div> */}

                <div className="team_panel">
                  <img src={require("../assets/images/team_03.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Punith Kumar Baskaran</h2>
                    <p>Lead Blockchain Consultant</p>
                    <p className="mt-3">Extensive experience in various domains of Blockchain development and an outstanding professional with proven skills, good technical understanding, and continuous adaptation to the technological advancements in this technology.</p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/punith-kumar-baskaran-66019037/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div>

                <div className="team_panel">
                  <img src={require("../assets/images/team_04.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Atty. Salma F. Angkaya-Kuhutan</h2>
                    <p>Legal Adviser</p>
                    <p className="mt-3">Data Privacy, AML/CFT, and FinTech Lawyer with a unique insight in Legal Preparedness/DRRM</p>
                    <p>Currently runs a general law practice while acting as legal counsel, corporate secretary, or consultant in the company. She is currently involved in regulatory compliance (data privacy and AML/CFT) for a fintech client developing solutions to address the “new normal” in public transportation, aligned with the government’s PUV modernization and contact-tracing program.</p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/sakuhutan/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div>

                <div className="team_panel">
                  <img src={require("../assets/images/team_06.png")} alt="Team" className="img-fluid"/>
                  <div className="member_details member_details_inner">
                    <h2 className="text-dark">Medel Afalla</h2>
                    <p>Philippine Public Transportation Consultant</p>
                    <p className="mt-3">With 37 years of expertise and experience in good governance in Public Transport under the Office of Transportation Cooperative Philippines.</p>
                  </div>
                  <div className="member_social_inner">
                    <ul>
                      <li><a href="https://www.linkedin.com/in/medel-afalla-870b31a6/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </div>
                </div>
                
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
