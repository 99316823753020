/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { NavLink, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list + " main_navbar"}>
      <ListItem className={classes.listItem}>
        <NavLink to="/home" color="transparent" className="nav-link">Home</NavLink>
      </ListItem>      

      <ListItem className={classes.listItem + " dropdown"}>
        <NavLink className="nav-link" data-toggle="dropdown" to="/about" role="button" aria-haspopup="true" aria-expanded="false">About</NavLink>
        <div className="dropdown-menu mega_menu">
          <div className="mega_menu_inner_wrap">
            <div className="mega_menu_first_column">
              <h3>What’s New with FILIPCOIN</h3>
              <ul>
                <li><Link to="#">News</Link></li>
                <li><Link to="#">Promos</Link></li>
                <li><Link to="#">Events</Link></li>
              </ul>
            </div>
            <div className="mega_menu_second_column">
              <h3><img src={require("../../assets/images/menu_icon_01.png")} alt="Icon" className="img-fluid"/>About FILIPCOIN</h3>
              <ul>
                <li><NavLink to="/about">Intro</NavLink></li>
                <li><Link to="/roadmap">Roadmap</Link></li>
                <li><Link to="/tokenomics">Tokenomics</Link></li>
              </ul>
            </div>
            <div className="mega_menu_third_column">     
              <ul className="list_large">
                <li><a href="tokenomics#bep20"><img src={require("../../assets/images/menu_icon_02.png")} alt="Icon" className="img-fluid"/>BEP-20 Cossntract Address</a></li>
                <li><a href="tokenomics#erc20"><img src={require("../../assets/images/menu_icon_02.png")} alt="Icon" className="img-fluid"/>ERC-20 Contract Address</a></li>
                <li><a href="about#leadership_team"><img src={require("../../assets/images/menu_icon_03.png")} alt="Icon" className="img-fluid"/>Leadership Team</a></li>
              </ul>
            </div>
            <div className="mega_menu_fourth_column">
              <ul className="list_large">
                <li><a href="about#our_awards"><img src={require("../../assets/images/menu_icon_04.png")} alt="Icon" className="img-fluid"/>Awards</a></li>
                <li><a href="about#features"><img src={require("../../assets/images/menu_icon_05.png")} alt="Icon" className="img-fluid"/>Features</a></li>
                <li><a href="home#community"><img src={require("../../assets/images/menu_icon_06.png")} alt="Icon" className="img-fluid"/>Community</a></li>
              </ul>              
            </div>
          </div>
        </div>
      </ListItem>      

      <ListItem className={classes.listItem + " dropdown"}>
        <NavLink className="nav-link" data-toggle="dropdown" to="/token" role="button" aria-haspopup="true" aria-expanded="false">Token</NavLink>
        <div className="dropdown-menu mega_menu mega_menu_token">
          <div className="mega_menu_inner_wrap">
            <div className="mega_menu_first_column">
              <h3>What’s New with FILIPCOIN</h3>
              <ul>
                <li><Link to="#">News</Link></li>
                <li><Link to="#">Promos</Link></li>
                <li><Link to="#">Events</Link></li>
              </ul>
            </div>
            <div className="mega_menu_second_column">
              <p className="px-2">Token Sale</p>
              <ul className="list_large">
                <li><a href="token"><img src={require("../../assets/images/token_menu_icon_01.png")} alt="Icon" className="img-fluid"/>Buy FCP with ETH</a></li>
                <li><a href="token"><img src={require("../../assets/images/token_menu_icon_02.png")} alt="Icon" className="img-fluid"/>Buy FCP with BNB</a></li>                
              </ul>
            </div>
            <div className="mega_menu_third_column">
              <p>Exchange</p>
              <ul className="list_large">
                <li><a href="token#uniswap_token"><img src={require("../../assets/images/token_menu_icon_03.png")} alt="Icon" className="img-fluid"/>UNISWAP</a></li>
                <li><a href="token#pancake"><img src={require("../../assets/images/token_menu_icon_04.png")} alt="Icon" className="img-fluid"/>Pancakeswap</a></li>
                <li><a href="token#tokpie_section"><img src={require("../../assets/images/token_menu_icon_05.png")} alt="Icon" className="img-fluid"/>Tokpie</a></li>
              </ul>
            </div>
            <div className="mega_menu_fourth_column">
              <p>Price Listing</p>
              <ul className="list_large">
                <li><a href="token#price_tracking"><img src={require("../../assets/images/token_menu_icon_06.png")} alt="Icon" className="img-fluid"/>CoinMarketCap</a></li>
                <li><a href="token#price_tracking"><img src={require("../../assets/images/token_menu_icon_07.png")} alt="Icon" className="img-fluid"/>FTX</a></li>
                <li><a href="token#price_tracking"><img src={require("../../assets/images/token_menu_icon_08.png")} alt="Icon" className="img-fluid"/>CoinGecko</a></li>
              </ul>              
            </div>
          </div>
        </div>
      </ListItem>       
      
      {/* <ListItem className={classes.listItem}>
        <Link to="token_sale" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Token</Link>
      </ListItem> */}      

      <ListItem className={classes.listItem + " dropdown"}>
        <NavLink className="nav-link" data-toggle="dropdown" to="/products" role="button" aria-haspopup="true" aria-expanded="false">Products</NavLink>
        <div className="dropdown-menu mega_menu mega_menu_product">
          <div className="mega_menu_inner_wrap">
            <div className="mega_menu_first_column">
              <h3>What’s New with FILIPCOIN</h3>
              <ul>
                <li><Link to="#">News</Link></li>
                <li><Link to="#">Promos</Link></li>
                <li><Link to="#">Events</Link></li>
              </ul>
            </div>
            <div className="mega_menu_second_column">
              <p className="px-2">Products</p>
              <ul className="list_large">
                <li><a href="products">FILIPCOIN Wallet</a></li>
                <li><a href="products#filipay_prod">FILIPAY App</a></li>                
              </ul>
            </div>
            <div className="mega_menu_third_column">
              <p>&nbsp;</p>
              <ul className="list_large">
                <li><a href="products#filipswap_prod">FILIPSWAP</a></li>
                <li><a href="products#cols_prod">COLS</a></li>
              </ul>
            </div>
            <div className="mega_menu_fourth_column">
              <p>&nbsp;</p>
              <ul className="list_large">
                <li><a href="javascript:void">Referral Program</a></li>
              </ul>              
            </div>
          </div>
        </div>
      </ListItem>

      <ListItem className={classes.listItem}>
        <NavLink to="/faq" color="transparent" className="nav-link">Faq</NavLink>
      </ListItem>
{/* 
      <ListItem className={classes.listItem}>
        <a href="https://filipcoin.gitbook.io/whitepaper/" target="_blank" color="transparent" download className="nav-link">Whitepaper</a>
      </ListItem>  */}
      
      {/* <ListItem className={classes.listItem}>
        <Link to="#roadmap" color="transparent" className="nav-link">Roadmap</Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link to="/" color="transparent" className="nav-link">Faq</Link>
      </ListItem> */}

      <ListItem className={classes.listItem}>
        <NavLink to="/announcement" color="transparent" className="nav-link">Announcement</NavLink>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <NavLink to="/login" color="transparent" className="nav-link login_btn">Login</NavLink>
      </ListItem>
    </List>
  );
}
