import React,  { useState, useEffect }  from "react";
import CryptoJS from "crypto-js";
import jwt from 'jsonwebtoken';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks_inner from "components/Header/HeaderLinks_Inner.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import { postMethod }  from "../actions/actions";
import { getMethod }  from "../actions/actions";
import 'react-toastify/dist/ReactToastify.css';
import config from "../actions/config"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button, Hidden } from "@material-ui/core";

const roadmap_options = {
  responsive:{
    0:{
        items:1, 
        nav: false
    },
    600:{
      items:2,
      margin:10,
      nav: true
    },
    768:{
        items:2,
        margin:10,
        nav: true
    },
    992:{
        items:2,
        margin:20
    },
    1366:{
      items:2,
      margin:20
    }
  }
};

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Roadmap(props) {

  const { ...rest } = props;
  const url=config.baseUrl;
  const [_id, set_id] = React.useState('');
  const key = CryptoJS.enc.Base64.parse("#base64Key#");
  const iv = CryptoJS.enc.Base64.parse("#base64IV#");

  var   {  withdrawid } = useParams();
  
  useEffect(()  => {
     getuserid();
  },[])

async function getuserid(){
    try{
    var name = "Bearer ";
    var authToken = localStorage.getItem('jwtToken')
    var token = authToken.replace(name, "");
    var decipher = CryptoJS.AES.decrypt(config.secretOrKey, key, {iv: iv})
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    jwt.verify(token, decrypt_val, (err, verified) => {
    if(!err && verified && verified.id){
    if( verified.id!=""  &&  verified.id!=undefined && _id==""){
      set_id(verified.id);
      console.log("in+==========================")
    }
   
    }else{
    }
  
   });
  }catch(err){

  }
  }

async function  isconfirm(){
    var id=withdrawid;
    if (typeof id != "undefined" && id != "") {
      let passdata = { id: id };
      var res = await postMethod(url+"api/useraccept",passdata);
      
      if(res.data && res.data.message){
        toastAlert('success',res.data.message,'success')
      }
    }else{
      localStorage.clear();
    }
  }
  return (
    <div className="inner_pages">
      <ScrollToTopOnMount />
      {_id==''?(
         <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
       />
        ) : (
          <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
        )}
      

      <div className="inner_pageheader term_inner">
        <div className="container"> 
          <GridContainer>
            <GridItem sm={12} md={12} lg={12}>
              <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/faq" className="active">About Us</Link></li>
              </ul>
              <h1 className="inner_title">About Us</h1>
            </GridItem>
          </GridContainer>
        </div>
        <nav className="fixed_sidenav">
          <ul>
            <li><NavLink to="/about">Introduction</NavLink></li>
            <li><NavLink to="/objective">Objective</NavLink></li>
            <li><NavLink to="/roadmap">Roadmap</NavLink></li>
            <li><NavLink to="/tokenomics">Tokenomics</NavLink></li>
          </ul>
        </nav>
        <div className="new_inner_content">
          <section className="section">
            <div className="container">
              <GridContainer className="align-items-center">
                <GridItem sm={12} md={12} lg={12} className="mt-3" data-aos="fade-up" data-aos-duration="2000">
                  <div className="abt_intro_panel">
                    <h2>Roadmap</h2>
                    <p>FILIPCOIN is aimed at being a relevant mover in the Philippines’ increased ‘fintech’ competitiveness. And with an increased acceptance of cryptocurrency in the country, time as well as timing, is highly significant. As such, FILIPCOIN activities requires strict adherence to its planned roadmap and timeline which is segmented and categorized in different phases.</p>                   
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </section>

          <section className="section roadmap_carousel mb-5"> 
            <div className="container-fluid">
              <GridContainer>
                <Hidden only={['sm', 'xs', 'md']}>
                  <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                    <div className="row roadmap_list small_space align-items-end">
                      <OwlCarousel className="owl-theme roadmap" loop margin={10} nav items={4} {...roadmap_options} navText={["<i class='fas fa-arrow-left'></i>","<i class='fas fa-arrow-right'></i>"]}>
                        <div className="item">
                          <div className="single_roadmap">
                            <div className="roadmap_icon"></div>
                            <div className="roadmap_text">
                              <h6>Phase 1 - Q4 2017 to Q4 2020</h6>
                              <ul>
                                <li>FCP TEAM</li>
                                <li>Development of an Informative Website</li>
                                <li>FILIPCOIN’s comprehensive whitepaper, roadmap, ICO details and timeline overview</li>
                                <li>Creation of FILIPCOIN</li>
                                <li>INITIAL COIN OFFERING – TOKEN SALE</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="single_roadmap">
                            <div className="roadmap_icon"></div>
                            <div className="roadmap_text">
                              <h6>Phase 2 - 4 - Q4 2017 to Q4 2021</h6>
                              <ul>
                                <li>Affiliate Site</li>
                                <li>Bounty rewards, and airdrops</li>
                                <li>FILIPAY Mobile App</li>
                                <li>Complete Features of FILIPAY Transportation System</li>
                                <li>FCP Mobile Wallet</li>
                                <li>External Wallet for Filipay and other partner companies</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="single_roadmap">
                            <div className="roadmap_icon"></div>
                            <div className="roadmap_text">
                              <h6>Phase 5 - Q4 2021  to Q4 2022</h6>
                              <ul>
                                <li>Monetary Integration System</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="single_roadmap">
                            <div className="roadmap_icon"></div>
                            <div className="roadmap_text">
                              <h6>Phase 6 - 2021-2025</h6>
                              <ul>
                                <li>FILIPCOIN Blockchain Ecosystem</li>
                                <li>Exchanger Platform API Development (Open Source)</li>
                                <li>Decentralized Finance (DeFi)</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </GridItem>
                </Hidden>
                <Hidden only={['xl', 'lg']}>
                  <GridItem sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="2000">
                    <div className="roadmap_mobile_text">
                      <h2>Phase 1 - Q4 2017 to Q4 2020</h2>
                      <ul>
                        <li>FCP TEAM</li>
                        <li>Development of an Informative Website</li>
                        <li>FILIPCOIN’s comprehensive whitepaper, roadmap, ICO details and timeline overview</li>
                        <li>Creation of FILIPCOIN</li>
                        <li>INITIAL COIN OFFERING – TOKEN SALE</li>
                      </ul>
                      <h2>Phase 2 - 4 - Q4 2017 to Q4 2021</h2>
                      <ul>
                        <li>Affiliate Site</li>
                        <li>Bounty rewards, and airdrops</li>
                        <li>FILIPAY Mobile App</li>
                        <li>Complete Features of FILIPAY Transportation System</li>
                        <li>FCP Mobile Wallet</li>
                        <li>External Wallet for Filipay and other partner companies</li>
                      </ul>
                      <h2>Phase 5 - Q4 2021  to Q4 2022</h2>
                      <ul>
                        <li>Monetary Integration System</li>
                      </ul>
                      <h2>Phase 6 - 2021-2025</h2>
                      <ul>
                        <li>FILIPCOIN Blockchain Ecosystem</li>
                        <li>Exchanger Platform API Development (Open Source)</li>
                        <li>Decentralized Finance (DeFi)</li>
                      </ul>
                    </div>
                  </GridItem>
                </Hidden>
              </GridContainer>
            </div> 
          </section>

          <div className="container" data-aos="fade-up" data-aos-duration="2000">
            <div className="bottom_page_btn_grp">
              <Link to="/objective" className="nxt_page_btn"><img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon prev_arrow_icon"/>Objective</Link>
              <Link to="/tokenomics" className="nxt_page_btn">Tokenomics <img src={require("../assets/images/arrow_icon.png")} alt="Client" className="img-fluid nxt_arrow_icon"/></Link>
            </div>
          </div>
        </div>          
        <Footer/>
      </div>
    </div>
  );
}
